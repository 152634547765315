import React from 'react';

const Pencil = () => {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.333 2.50004C11.5081 2.32494 11.716 2.18605 11.9447 2.09129C12.1735 1.99653 12.4187 1.94775 12.6663 1.94775C12.914 1.94775 13.1592 1.99653 13.3879 2.09129C13.6167 2.18605 13.8246 2.32494 13.9997 2.50004C14.1748 2.67513 14.3137 2.883 14.4084 3.11178C14.5032 3.34055 14.552 3.58575 14.552 3.83337C14.552 4.08099 14.5032 4.32619 14.4084 4.55497C14.3137 4.78374 14.1748 4.99161 13.9997 5.16671L4.99967 14.1667L1.33301 15.1667L2.33301 11.5L11.333 2.50004Z"
        stroke="#373634"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Pencil;
