import React from 'react';

const SvgRight = (props: any) => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.1373 15.2745C16.0457 15.1944 16 15.1022 16 14.998C16 14.8938 16.0457 14.8015 16.1373 14.7215L16.8247 14.1203C16.9163 14.0401 17.0218 14 17.1407 14C17.2598 14 17.3653 14.0401 17.4569 14.1203L23.8627 19.7234C23.9543 19.8036 24 19.8958 24 20C24 20.1042 23.9543 20.1964 23.8627 20.2766L17.4571 25.8797C17.3654 25.96 17.26 26 17.1409 26C17.0219 26 16.9165 25.96 16.8249 25.8797L16.1375 25.2788C16.0459 25.1986 16.0002 25.1064 16.0002 25.002C16.0002 24.898 16.0459 24.8058 16.1375 24.7256L21.5397 20L16.1373 15.2745Z"
      fill="#393939"
    />
  </svg>
);

export default SvgRight;
