import React from 'react';

const XSrightArrow = (props: any) => {
  const { fill, ...rest } = props;
  return (
    <svg
      width="4"
      height="7"
      viewBox="0 0 4 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0.0686688 1.13726C0.0228615 1.0972 0 1.05108 0 0.998979C0 0.946882 0.0228615 0.900762 0.0686688 0.860767L0.41235 0.56014C0.458158 0.520071 0.510882 0.5 0.570356 0.5C0.629914 0.5 0.682639 0.520071 0.728446 0.56014L3.93133 3.36171C3.97714 3.40178 4 3.4479 4 3.5C4 3.5521 3.97714 3.59822 3.93133 3.63829L0.72853 6.43986C0.682723 6.48 0.629998 6.5 0.57044 6.5C0.510967 6.5 0.458242 6.48 0.412435 6.43986L0.0687532 6.13938C0.0229459 6.09931 8.44002e-05 6.05319 8.44002e-05 6.00102C8.44002e-05 5.949 0.0229459 5.90288 0.0687532 5.86281L2.76987 3.5L0.0686688 1.13726Z"
        fill="#373634"
      />
    </svg>
  );
};

export default XSrightArrow;
