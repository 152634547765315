import React from 'react';
import Button from '../Button';
import ThemeType from '/theme/ThemeType';
import Grid from '/Grid';
import makeStyles from '/makeStyles';

const useStyles = makeStyles((theme: ThemeType) => ({
  buttonContainer: {
    flex: 'auto',
  },
  button: {
    fontSize: '14px',
    textTransform: 'none',
    letterSpacing: '0.01em',
    height: '41px',
    color: `${theme.palette.greyDark.main}`,
    border: `1px solid ${theme.palette.greyDark.main}`,
    '&:hover': {
      border: `1px solid ${theme.palette.greyDark.main}`,
    },
  },
  buttonSelected: {
    color: theme.palette.neutral0.main,
  },
}));

interface Segment {
  text: string;
  isSelected: boolean;
  onClick: () => void;
}

interface SegmentsProps {
  segments: Segment[];
}

export default function OHWSegments(props: SegmentsProps) {
  const classes = useStyles();

  const { segments } = props;
  if (!segments || !segments.length) {
    return;
  }

  const createSegments = (
    text: string,
    isSelected: boolean,
    onClick: () => void,
  ) => {
    return (
      <Grid item className={classes.buttonContainer}>
        <Button
          fullWidth
          type={isSelected ? 'primary' : 'secondary'}
          className={
            isSelected
              ? `${classes.button} ${classes.buttonSelected}`
              : classes.button
          }
          onClick={onClick}
        >
          {text.charAt(0).toUpperCase() + text.slice(1).toLowerCase()}
        </Button>
      </Grid>
    );
  };

  return (
    <Grid container spacing={0} direction="row">
      {segments.map((segment: Segment) => {
        const { text, isSelected, onClick } = segment;
        return createSegments(text, isSelected, onClick);
      })}
    </Grid>
  );
}
