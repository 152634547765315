import React from 'react';

const Invites = (props: any) => (
  <svg
    width="98"
    height="115"
    viewBox="0 0 98 115"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M26.975 13.58C30.865 13.485 34.725 12.86 38.62 12.89C39.625 12.895 40.71 12.94 41.66 13.08C42.11 13.15 42.56 13.24 43 13.355C43.385 13.455 43.93 13.65 44.14 13.745C44.75 14.03 45.485 13.92 45.85 13.295C46.165 12.755 46.015 11.87 45.4 11.585C41.82 9.92495 37.495 10.31 33.67 10.6C31.73 10.745 29.795 10.95 27.85 11.045C25.405 11.165 22.945 11.055 20.505 10.985C17.3 10.89 14.095 10.765 10.885 10.76C9.27499 10.76 9.27499 13.26 10.885 13.26C16.245 13.265 21.615 13.71 26.975 13.58Z"
      fill="#D19400"
    />
    <path
      d="M20.895 18.7048C18.32 18.8198 15.745 18.9048 13.165 18.9098C11.555 18.9098 11.555 21.4098 13.165 21.4098C22.205 21.3998 31.235 20.3448 40.275 20.8348C42.765 20.9698 45.24 21.2298 47.695 21.6898C48.36 21.8148 49.045 21.5048 49.235 20.8148C49.4 20.2048 49.03 19.3998 48.36 19.2748C39.295 17.5848 30.04 18.3048 20.895 18.7048Z"
      fill="#D19400"
    />
    <path
      d="M14.47 29.985C12.86 29.985 12.86 32.485 14.47 32.485C23.045 32.48 31.62 32.36 40.195 32.27C48.71 32.175 57.22 32.205 65.735 32.235C67.88 32.245 70.06 32.295 72.2 32.075C73.1 31.98 74 31.855 74.91 31.805C75.595 31.77 76.52 31.74 77.045 31.985C77.655 32.27 78.39 32.16 78.755 31.535C79.07 30.995 78.92 30.11 78.305 29.825C76.815 29.135 75.175 29.245 73.585 29.415C71.74 29.615 69.915 29.705 68.055 29.72C63.805 29.76 59.55 29.645 55.3 29.65C46.6 29.66 37.9 29.79 29.205 29.88C24.295 29.94 19.38 29.98 14.47 29.985Z"
      fill="#D19400"
    />
    <path
      d="M79.3 35.8502C74.78 35.8452 70.265 35.6302 65.745 35.5102C61.175 35.3852 56.625 35.4352 52.065 35.7352C47.545 36.0302 43.03 36.4152 38.52 36.7852C34.205 37.1402 29.86 37.6102 25.53 37.6902C21.44 37.7652 17.135 37.3202 13.16 38.5052C11.62 38.9652 12.275 41.3752 13.825 40.9152C17.24 39.8952 20.96 40.2102 24.48 40.2002C28.67 40.1902 32.87 39.7452 37.045 39.4052C46.03 38.6752 55 37.7852 64.025 37.9702C69.12 38.0752 74.21 38.3502 79.305 38.3502C80.91 38.3502 80.91 35.8502 79.3 35.8502Z"
      fill="#D19400"
    />
    <path
      d="M32.055 47.6202C29.26 48.6852 26.165 48.5602 23.225 48.5252C20.095 48.4852 16.855 48.3802 13.815 49.2502C12.27 49.6902 12.93 52.1052 14.48 51.6602C20.405 49.9602 26.895 52.2502 32.725 50.0302C33.355 49.7902 33.79 49.1902 33.6 48.4902C33.425 47.8902 32.69 47.3802 32.055 47.6202Z"
      fill="#D19400"
    />
    <path
      d="M22.605 57.7201C20.87 58.0001 19.12 58.2051 17.355 58.2601C15.615 58.3151 13.86 58.2601 12.195 57.7201C10.66 57.2201 10.005 59.6351 11.53 60.1301C15.25 61.3351 19.495 60.7351 23.27 60.1301C23.935 60.0251 24.31 59.1901 24.145 58.5901C23.95 57.8851 23.275 57.6151 22.605 57.7201Z"
      fill="#D19400"
    />
    <path
      d="M19.415 69.4605C18.22 69.5305 17.025 69.6255 15.83 69.5755C15.61 69.5655 15.395 69.5505 15.18 69.5255C15.12 69.5155 14.985 69.5005 14.95 69.4905C14.83 69.4705 14.715 69.4405 14.6 69.4105C14.505 69.3855 14.415 69.3555 14.325 69.3255C14.32 69.3255 14.32 69.3255 14.32 69.3255C14.31 69.3205 14.31 69.3155 14.3 69.3105C14.275 69.2955 14.245 69.2755 14.22 69.2605C14.205 69.2455 14.095 69.1605 14.055 69.1155C12.985 67.9105 11.22 69.6805 12.285 70.8855C12.995 71.6855 14.1 71.9105 15.11 72.0205C16.295 72.1505 17.495 72.0705 18.68 72.0005C19.935 71.9255 21.195 71.8305 22.455 71.8555C22.74 71.8605 23.025 71.8755 23.305 71.9005C23.405 71.9105 23.5 71.9205 23.6 71.9305C23.635 71.9405 23.83 71.9705 23.865 71.9755C24.095 72.0205 24.315 72.0855 24.54 72.1605C24.555 72.1655 24.56 72.1655 24.57 72.1705C24.58 72.1755 24.585 72.1805 24.605 72.1905C24.715 72.2505 24.82 72.3105 24.92 72.3805C25.475 72.7655 26.305 72.4855 26.63 71.9305C26.99 71.3105 26.74 70.6055 26.18 70.2205C25.28 69.6005 24.115 69.4455 23.05 69.3805C21.84 69.3105 20.625 69.3905 19.415 69.4605Z"
      fill="#D19400"
    />
    <path
      d="M28.15 81.7852C25.2 81.8002 22.255 82.0052 19.305 82.1002C17.995 82.1402 16.725 82.0252 15.425 81.8952C14.125 81.7702 12.785 81.7252 11.53 82.1552C10.89 82.3752 10.47 83.0152 10.655 83.6952C10.825 84.3152 11.55 84.7852 12.195 84.5702C13.31 84.1902 14.485 84.3052 15.64 84.4202C16.955 84.5552 18.23 84.6402 19.55 84.5952C22.42 84.5002 25.285 84.3002 28.155 84.2902C29.76 84.2752 29.765 81.7752 28.15 81.7852Z"
      fill="#D19400"
    />
    <path
      d="M56.49 11.255C56.395 13.19 56.165 15.13 56.295 17.065C56.335 17.685 56.74 18.215 57.38 18.295C57.755 18.34 58.165 18.205 58.445 17.94C58.705 18.335 59.165 18.59 59.76 18.43C62.76 17.625 65.9 17.175 69.025 17.195C69.255 17.745 69.74 18.13 70.37 18.035C70.775 17.975 71.15 17.71 71.335 17.345C71.615 17.435 71.89 17.53 72.155 17.655C72.75 17.93 73.46 17.83 73.83 17.25C74.055 17.105 74.23 16.885 74.315 16.58C74.475 15.995 74.115 15.135 73.44 15.04C72.805 14.95 72.165 14.885 71.525 14.835C71.29 14.75 71.065 14.655 70.845 14.54C70.795 14.515 70.745 14.5 70.69 14.48C70.415 13.755 70.095 13.05 69.62 12.425C69.18 11.845 68.225 11.485 67.655 12.17C67.61 12.225 67.57 12.28 67.525 12.33C67.355 11.29 67.135 10.305 66.695 9.33999C66.27 8.41999 65.015 8.63999 64.535 9.33999C64.27 9.72999 64.045 10.135 63.845 10.545C63.755 9.54999 63.635 8.55999 63.32 7.60999C62.905 6.35999 61.37 6.49499 60.91 7.60999C60.805 7.86499 60.71 8.12499 60.62 8.38499C60.615 7.78499 60.615 7.18999 60.605 6.61499C60.59 5.53999 59.255 5.00999 58.525 5.66999C58.47 5.49999 58.415 5.32499 58.345 5.15499C58.095 4.52999 57.51 4.08999 56.805 4.27999C56.21 4.44499 55.68 5.18499 55.93 5.81999C56.625 7.55499 56.58 9.42999 56.49 11.255ZM58.97 16.065C59.01 15.765 59.035 15.465 59.07 15.165C59.16 15.43 59.265 15.69 59.375 15.95C59.285 15.975 59.19 15.995 59.1 16.02C59.05 16.03 59.01 16.05 58.97 16.065ZM65.265 13.82C65.33 14.18 65.41 14.535 65.505 14.88C65.31 14.9 65.115 14.915 64.92 14.935C65.035 14.565 65.15 14.19 65.265 13.82ZM61.92 15.38C61.925 15.245 61.925 15.115 61.935 14.98C61.97 15.105 62.005 15.23 62.045 15.355C62 15.365 61.96 15.375 61.92 15.38Z"
      fill="#D19400"
    />
    <path
      d="M81.35 99.2301C82.205 100.225 83.11 101.18 83.96 102.185C84.17 102.43 84.375 102.68 84.58 102.93C84.635 103 84.69 103.07 84.745 103.14C84.675 103.05 84.67 103.045 84.745 103.14C84.85 103.28 84.955 103.42 85.055 103.56C85.42 104.075 85.76 104.605 86.045 105.17C86.34 105.755 87.21 105.97 87.755 105.62C88.355 105.235 88.6 104.485 88.205 103.91C86.69 101.715 84.44 100.515 82.675 98.4601C82.245 97.9601 81.37 97.2301 80.935 97.7001C80.445 98.2251 80.895 98.7001 81.35 99.2301Z"
      fill="#D19400"
    />
    <path
      d="M88.975 107.775C89.3 108.21 89.625 108.645 89.95 109.08C90.03 109.225 90.135 109.345 90.27 109.43C90.39 109.54 90.53 109.615 90.695 109.655C90.86 109.705 91.025 109.715 91.195 109.675C91.36 109.665 92.195 108.99 91.76 108.365C91.435 107.93 88.99 105.35 88.845 106.81C88.83 106.92 88.815 107.03 88.8 107.14C88.805 107.37 88.86 107.58 88.975 107.775Z"
      fill="#D19400"
    />
    <path
      d="M76.28 27.7498C68.88 27.7698 61.48 27.2498 54.09 27.9848C47.39 28.6548 40.545 28.5148 33.8 28.4798C26.62 28.4448 19.85 28.5848 12.65 29.1448C13.4 30.1648 14.295 29.8548 15.075 29.8498C20.965 29.8198 26.86 29.6348 32.73 30.3198C36.415 30.7498 40.12 30.8898 43.82 30.6148C52.71 29.9548 61.6 29.3498 70.525 29.7848C72.335 29.8748 74.145 29.9198 75.955 29.8898C77.03 29.8698 78.37 29.5748 78.385 28.3998C78.4 27.5498 77.035 27.7498 76.28 27.7498Z"
      fill="#373634"
    />
    <path
      d="M49.065 98.535C34.625 98.135 20.18 97.84 5.735 97.51C4.87 96.995 3.71 97.08 3.755 95.36C4.32 74.8 2.795 54.245 3.655 33.68C3.935 26.965 4.095 20.235 3.845 13.51C4.035 10.5 4.255 7.49504 4.4 4.48504C4.47 2.99504 5.07 2.50503 6.625 2.52503C22.61 2.73503 38.61 2.13504 54.585 3.10004C54.275 7.69504 53.945 12.295 53.67 16.895C53.6 18.055 53.22 19.315 54.245 20.31C55.07 20.625 55.855 20.26 56.66 20.17C67.425 18.97 77.32 20.415 84.73 29.415C85.87 31.305 85.91 33.395 85.895 35.52C85.805 51.085 85.74 66.655 85.675 82.22C85.67 83.015 85.675 83.815 85.675 84.61C86.68 82.555 86.35 80.375 86.55 78.255C87.87 64.105 88.385 49.925 87.795 35.72C87.725 34.015 87.715 32.26 86.995 30.635C85.72 26.745 83.955 23.13 80.73 20.475C73.98 14.915 67.425 9.11004 60.505 3.75004C58.135 1.91504 55.785 1.21004 52.845 1.19004C49.685 1.17004 38.995 0.560035 38.185 0.560035C30.435 0.580035 22.685 0.570035 14.93 0.570035C12.445 0.435035 9.94 0.855034 7.47 0.345034C2.015 -0.0299655 1.695 1.58504 1.52 2.99504C1.07 4.08004 1.335 5.22004 1.325 6.32504C1.145 24.095 -0.0700033 41.865 0.794997 59.635C1.355 71.12 2.06 82.59 1.76 94.095C1.725 95.455 1.8 96.815 1.845 98.18C1.87 98.935 1.8 99.775 2.925 99.8C4.43 99.855 5.935 99.905 7.44 99.96C24.095 100.555 40.755 100.35 57.42 100.23C58.94 100.15 60.59 100.86 62.23 99.375C60.705 99.375 59.52 99.375 58.335 99.375C55.235 99.08 52.155 98.62 49.065 98.535ZM60.74 7.48003C64.69 10.81 67.955 14.48 72.02 17.815C69.725 17.24 59.655 17.165 56.475 17.475C56.37 17.52 56.46 16.92 56.36 16.97C56.74 15.61 56.555 14.17 56.475 12.745C56.36 10.65 56.47 6.30504 56.785 4.45504C58.26 5.54004 60.16 6.99003 60.74 7.48003Z"
      fill="#373634"
    />
    <path
      d="M91.55 111.32C90.19 110.245 88.76 109.205 87.605 107.925C81.575 101.265 75.61 94.5451 69.66 87.8101C69.055 87.1301 68.115 86.5002 68.45 85.3152C69.28 84.8252 70.255 84.4751 70.905 83.8101C72.285 82.4051 73.16 82.8701 74.265 84.1801C76.48 86.8051 78.655 89.4751 81.3 91.7001C83.915 94.9101 86.875 97.7702 90.09 100.41C91.025 100.255 88.215 97.2452 86.135 94.8702C85.84 94.5452 84.26 93.0501 83.895 92.6451C82.57 90.8351 81.575 89.9851 80.115 88.4201C78.54 86.4351 77.06 84.3652 75.355 82.4952C74.365 81.4102 74.43 80.7301 75.295 79.6451C77.975 76.3101 79.695 72.5252 80.165 68.2302C81.03 65.6502 80.845 63.0001 80.465 60.4001C79.52 53.9051 77.305 48.1201 71.03 44.8301C68.795 43.5951 66.66 42.1351 64.135 41.5451C55.145 39.4501 46.965 41.4001 39.345 46.4351C31.185 51.8251 27.54 65.5751 31.675 73.0751C35.77 80.5101 41.74 85.6901 50.045 87.8051C54.935 89.0501 59.89 89.2901 64.685 87.1551C65.88 86.9151 66.435 87.7852 67.075 88.5252C71.745 93.9702 76.4 99.4301 81.09 104.855C83.41 107.54 85.695 110.27 88.205 112.77C90.98 115.53 96.335 114.345 97.23 110.725C95.345 111.71 93.51 112.865 91.55 111.32ZM59.985 85.4451C49.72 86.0801 41.81 81.6851 36.335 73.0751C32.35 66.8051 32.455 56.4651 36.715 51.1851C43.08 43.2951 56.14 39.7001 66.24 44.2101C67.37 44.7151 68.955 45.5501 70.19 46.4551C70.89 47.1751 71.5 47.5502 72.05 48.1602C75.055 51.5052 76.46 55.5401 77.225 59.4951C77.27 59.9401 77.335 60.3801 77.44 60.8101C77.455 60.8651 77.46 60.9201 77.47 60.9751C77.74 63.2051 77.67 65.5251 77.275 67.9351C77.115 68.6601 76.925 69.3851 76.725 70.1101C76.185 72.5001 75.24 74.7451 73.905 76.7651C70.61 81.7701 66.03 85.0701 59.985 85.4451Z"
      fill="#373634"
    />
    <path
      d="M37.43 11.1653C40.28 11.1803 43.135 11.2153 45.985 11.2003C46.65 11.1953 47.71 11.2153 48.365 11.0703C48.665 11.0053 46.665 10.7803 46.3 10.7603C35.24 10.1803 24.23 8.99534 13.15 9.15534C12.635 9.16034 12.1 9.28534 11.615 9.46534C11.06 9.67034 10.345 9.94534 10.41 10.6453C10.49 11.4753 11.305 11.3103 11.865 11.3003C20.385 11.1903 28.91 11.6153 37.43 11.1653Z"
      fill="#373634"
    />
    <path
      d="M13.04 19.4203C16.515 19.4603 19.99 19.4603 23.47 19.6453C30.415 20.0103 37.38 20.0853 44.335 20.2503C45.39 20.2753 46.475 20.2253 47.385 19.5653C47.675 19.3553 48.09 19.0303 48.08 18.7653C48.06 18.2103 47.5 18.1653 47.05 18.1703C45.54 18.1953 44.03 18.3203 42.52 18.2853C34.655 18.1053 27.09 18.7503 19.225 18.6603C16.52 18.6303 13.415 18.6103 10.65 19.2803C12.1 19.4903 12.345 19.4153 13.04 19.4203Z"
      fill="#373634"
    />
    <path
      d="M33.53 47.6703C26.785 47.0053 20.475 47.1253 14.17 47.4203C13.525 47.4503 12.405 47.3703 12.5 48.4603C12.57 49.2803 13.54 49.1353 14.16 49.2803C14.52 49.3653 14.91 49.3603 15.285 49.3503C20.415 49.2103 25.54 49.0803 30.67 48.9003C31.535 48.8703 32.455 48.8103 33.53 47.6703Z"
      fill="#373634"
    />
    <path
      d="M12.44 81.6746C15.46 81.6346 18.495 81.7196 21.5 81.4596C24.09 81.2346 26.745 81.5146 29.3 80.3846C29.025 80.2796 28.76 80.0996 28.48 80.0796C23.05 79.6546 17.62 79.7596 12.195 80.0896C11.7 80.1196 11.055 80.2196 11.19 80.9996C11.315 81.7096 11.93 81.6796 12.44 81.6746Z"
      fill="#373634"
    />
    <path
      d="M12.435 56.4298C11.9 56.5448 11.455 56.8348 11.5 57.5048C11.555 58.3148 12.1 58.5248 12.76 58.4298C16.63 57.8548 20.59 58.3698 24.46 57.2398C21.13 56.7548 17.84 55.9998 14.45 56.1848C13.775 56.2248 13.095 56.2898 12.435 56.4298Z"
      fill="#373634"
    />
    <path
      d="M73.71 37.0905C75.285 37.2005 76.875 36.9655 78.195 35.7755C74.565 36.0855 71 35.4305 67.425 35.7255C67.315 35.6455 67.205 35.6405 67.095 35.7205C57.61 35.0105 48.115 35.2955 38.625 35.5705C38.48 35.5755 38.345 35.6705 38.2 35.7205C30.655 35.9455 23.11 36.1605 15.57 36.3955C14.67 36.4255 13.76 36.5155 12.87 36.6705C12.35 36.7605 11.765 37.0655 11.815 37.6655C11.875 38.4105 12.59 38.2055 13.065 38.2205C16.235 38.3155 19.415 38.5755 22.575 38.4155C32.68 37.9055 42.775 37.2355 52.875 36.6305C59.82 36.7555 66.77 36.6105 73.71 37.0905Z"
      fill="#373634"
    />
    <path
      d="M12.985 68.2899C12.4 68.4099 11.34 68.2399 11.405 69.1549C11.475 70.1399 12.5 70.0099 13.21 69.9649C15.975 69.7899 18.735 69.5549 21.495 69.3399C22.575 69.5449 23.635 69.6299 25.035 68.9199C23.625 68.4049 22.605 68.0699 21.495 68.0599C18.65 67.9399 15.8 67.7249 12.985 68.2899Z"
      fill="#373634"
    />
    <path
      d="M94.845 105.22C94.35 104.185 91.72 100.99 92.08 102.565C93 103.995 94.9 106.205 94.845 105.22Z"
      fill="#373634"
    />
    <path
      d="M51.21 81.5653C47.335 80.0153 43.765 77.2753 41.135 73.8153C36.435 67.6353 36.925 58.4803 41.61 51.4853C42.295 50.4603 42.98 49.4303 43.805 48.1953C38.585 49.5853 34.24 61.8303 36.285 68.5303C38.4 75.4553 49.375 83.8553 54.15 82.1853C52.68 82.1053 52.07 81.9103 51.21 81.5653Z"
      fill="#373634"
    />
    <path
      d="M68.69 57.2052C67.25 57.0852 66.38 57.9052 65.51 58.5502C61.505 61.5202 57.535 64.5402 53.595 67.5951C52.605 68.3601 51.885 69.0501 50.935 67.5001C50.06 66.0801 48.965 66.9651 48.025 67.5751C46.91 68.3001 47.46 69.0901 47.985 69.8851C49.45 72.1001 50.695 72.3651 53.03 70.5651C57.82 66.8802 62.53 63.0901 67.25 59.3102C67.865 58.8152 68.645 58.3602 68.69 57.2052Z"
      fill="#373634"
    />
    <path
      d="M76.03 68.4146C77.48 56.9996 71.635 50.4046 71.3 50.1196C67.505 46.9296 64.1199 44.9896 59.1149 44.8296C57.3549 44.7746 55.3949 44.6746 53.0199 45.8746C57.0999 45.0596 61.505 45.6246 65.315 47.3846C67.2 48.5446 69.235 50.0246 70.39 52.1696C73.685 56.9646 74.97 61.8746 74.615 67.5346C73.835 73.7296 70.665 76.7546 67.36 79.8546C71.605 77.0746 75.02 73.6696 76.03 68.4146Z"
      fill="#373634"
    />
  </svg>
);

export default Invites;
