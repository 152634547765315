import React from 'react';
import Switch, { SwitchProps } from '@mui/material/Switch';
import { styled } from '@mui/material/styles';

import theme from '../theme';

const PrimaryToggleSwitch = styled((props: SwitchProps) => (
  <Switch disableRipple {...props} />
))(() => ({
  width: 42,
  height: 22,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 1,
    '&.Mui-checked': {
      transform: 'translateX(20px)',
      color: 'white',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.blue.main,
        opacity: 1,
        border: 'none',
      },
    },
    '& + .MuiSwitch-thumb': {
      backgroundColor: theme.palette.blue.main,
      border: '6px solid #fff',
    },
  },
  '& .MuiSwitch-thumb': {
    width: 20,
    height: 20,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.greyMedium.main,
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
  },
}));

const SecondaryToggleSwitch = styled((props: SwitchProps) => (
  <Switch disableRipple {...props} />
))(() => ({
  width: 51,
  height: 31,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(20px)',
      color: 'white',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.lightGreen.main,
        opacity: 1,
        border: 'none',
      },
    },
    '& + .MuiSwitch-thumb': {
      backgroundColor: theme.palette.lightGreen.main,
      border: '6px solid #fff',
    },
  },
  '& .MuiSwitch-thumb': {
    width: 27,
    height: 27,
  },
  '& .MuiSwitch-track': {
    borderRadius: 51 / 2,
    backgroundColor: theme.palette.greyMedium.main,
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
  },
}));

const ToggleSwitch = ({ type, ...rest }: { type?: string } & SwitchProps) => {
  switch (type) {
    case 'secondary': {
      return <SecondaryToggleSwitch {...rest} />;
    }
    default: {
      return <PrimaryToggleSwitch {...rest} />;
    }
  }
};

export default ToggleSwitch;
