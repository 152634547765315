import React from 'react';

const ClockSolid = (props: any) => {
  const { fill, ...rest } = props;
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M5.99413 0.166748C2.77413 0.166748 0.166626 2.78008 0.166626 6.00008C0.166626 9.22008 2.77413 11.8334 5.99413 11.8334C9.21996 11.8334 11.8333 9.22008 11.8333 6.00008C11.8333 2.78008 9.21996 0.166748 5.99413 0.166748ZM8.33329 8.33341C8.27933 8.38749 8.21522 8.43039 8.14466 8.45967C8.07409 8.48894 7.99844 8.50401 7.92204 8.50401C7.84564 8.50401 7.77 8.48894 7.69943 8.45967C7.62886 8.43039 7.56476 8.38749 7.51079 8.33341L5.59163 6.41425C5.53651 6.36022 5.49266 6.2958 5.46262 6.2247C5.43258 6.15361 5.41695 6.07726 5.41663 6.00008V3.66675C5.41663 3.34591 5.67913 3.08341 5.99996 3.08341C6.32079 3.08341 6.58329 3.34591 6.58329 3.66675V5.76091L8.33329 7.51091C8.56079 7.73841 8.56079 8.10591 8.33329 8.33341Z"
        fill="#373634"
      />
    </svg>
  );
};

export default ClockSolid;
