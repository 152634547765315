import Accordion from '@mui/material/Accordion';
import { styled } from '@mui/material/styles';

import theme from '../theme';

const OHWAccordion = styled(Accordion)(() => ({
  borderRadius: 0,
  boxShadow: 'none',
  borderBottom: `1px solid ${theme.palette.primary.main}`,
  '&::before': {
    display: 'none',
  },
  '&:last-child': {
    borderRadius: 0,
  },
}));

export default OHWAccordion;
