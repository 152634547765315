import React from 'react';

const Calendar = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17 1.81818H18C19.1 1.81818 20 2.63636 20 3.63636V18.1818C20 19.1818 19.1 20 18 20H2C0.9 20 0 19.1818 0 18.1818V3.63636C0 2.63636 0.9 1.81818 2 1.81818H3V0H5V1.81818H15V0H17V1.81818ZM2 18.1818H18V6.36364H2V18.1818Z"
        fill="#373634"
      />
      <path
        d="M6 10C6 10.5523 5.55228 11 5 11C4.44772 11 4 10.5523 4 10C4 9.44771 4.44772 9 5 9C5.55228 9 6 9.44771 6 10Z"
        fill="#373634"
      />
      <path
        d="M11 10C11 10.5523 10.5523 11 10 11C9.44772 11 9 10.5523 9 10C9 9.44771 9.44772 9 10 9C10.5523 9 11 9.44771 11 10Z"
        fill="#373634"
      />
      <path
        d="M15 11C15.5523 11 16 10.5523 16 10C16 9.44771 15.5523 9 15 9C14.4477 9 14 9.44771 14 10C14 10.5523 14.4477 11 15 11Z"
        fill="#373634"
      />
      <path
        d="M6 15C6 15.5523 5.55228 16 5 16C4.44772 16 4 15.5523 4 15C4 14.4477 4.44772 14 5 14C5.55228 14 6 14.4477 6 15Z"
        fill="#373634"
      />
      <path
        d="M10 16C10.5523 16 11 15.5523 11 15C11 14.4477 10.5523 14 10 14C9.44772 14 9 14.4477 9 15C9 15.5523 9.44772 16 10 16Z"
        fill="#373634"
      />
      <path
        d="M16 15C16 15.5523 15.5523 16 15 16C14.4477 16 14 15.5523 14 15C14 14.4477 14.4477 14 15 14C15.5523 14 16 14.4477 16 15Z"
        fill="#373634"
      />
    </svg>
  );
};

export default Calendar;
