import React from 'react';
import clsx from 'clsx';
import ButtonBase from '/ButtonBase';
import Text from '/Text';
import { TEXT_DS } from '/Text/constants';
import ThemeType from '/theme/ThemeType';
import Icons from '/Icons';
import Grid from '/Grid';
import makeStyles from '/makeStyles';

const useStyles = makeStyles((theme: ThemeType) => ({
  root: {
    padding: 16,
    backgroundColor: theme.palette.neutral0.main,
    border: `1px solid ${theme.palette.greyMedium.main}`,
    borderRadius: 4,
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: theme.palette.lightOrangeTint.main,
      border: `1px solid ${theme.palette.mud.main}`,
      boxShadow: '0px 1px 4px rgba(26, 26, 26, 0.25)',
    },
  },
  image: {
    width: 48,
    height: 48,
  },
  img: {
    margin: 'auto',
    display: 'block',
    maxWidth: '100%',
    maxHeight: '100%',
  },
  leftContainer: {
    textAlign: 'center',
    marginRight: 16,
  },
  primaryText: {
    color: theme.palette.primary.main,
    fontFamily: theme.bodyFontFamily,
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '0.01em',
    padding: 0,
    margin: 0,
  },
  secondaryText: {
    color: theme.palette.greyDark.main,
    fontFamily: theme.bodyFontFamily,
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '21px',
    letterSpacing: '0.01em',
    margin: 0,
    paddingTop: 4,
  },
  fullWidth: {
    width: '100%',
  },
  headerIconContainer: {
    margin: '0 0 0 8px',
    maxHeight: '18px',
  },
}));

function truncate(str: string, n: number) {
  return str?.length > n ? str?.substr(0, n - 1) + '…' : str;
}

interface ChunkyButtonSelectProps {
  className?: any;
  fullWidth?: boolean;
  onClick?: () => void /* use OnClick when you want the entire button to be clickable*/;
  onChange?: () => void /* use onChange when you want on the button to act as a change/edit button*/;
  onChangeText?: string /* the text to pass into the component when you have a change event that needs to be triggers, typicall "Change" or "Edit"*/;
  primaryText: string /* main header text */;
  secondaryText?: string /* second row of text */;
  tertiaryText?: string /* third row of text */;
  hideImage?: boolean /* set hideImage to true if you want to hide the left image */;
  avatar?: React.ReactNode /* React component ( usually svg ) that will serve as an avatar/image to the far left */;
  headerIcon?: React.ReactNode /* React component ( usually svg ) that will serve an icon to the right of the primary text */;
  rightIcon?: React.ReactNode /* React component ( usually svg ) that will serve as an icon to the far right */;
}

export default function ChunkyButtonSelect(props: ChunkyButtonSelectProps) {
  const classes = useStyles();
  const {
    onClick,
    className,
    fullWidth,
    onChange,
    onChangeText,
    primaryText,
    secondaryText,
    tertiaryText,
    headerIcon,
    avatar,
    hideImage,
    rightIcon,
  } = props;
  const handleOnClick = () => {
    if (onClick) {
      onClick();
    }
  };

  const classRoot = clsx(
    classes.root,
    {
      [classes.fullWidth]: fullWidth,
    },
    className,
  );
  const primaryTextTruncated = truncate(primaryText, 60);

  return (
    <div className={classRoot} onClick={handleOnClick}>
      <Grid container direction="row" alignItems="center" wrap="nowrap">
        {!hideImage && (
          <Grid item className={classes.leftContainer}>
            <ButtonBase className={classes.image} onClick={handleOnClick}>
              {avatar ? (
                avatar
              ) : (
                <Icons.DefaultProfile width={48} height={48} />
              )}
            </ButtonBase>
          </Grid>
        )}
        <Grid
          item
          container
          alignItems="flex-end"
          justifyContent="space-between"
        >
          <Grid item xs container direction="column">
            <Grid
              item
              container
              direction="row"
              alignItems="center"
              wrap="nowrap"
            >
              <Text className={classes.primaryText}>
                {primaryTextTruncated}
              </Text>
              {headerIcon && (
                <Grid item className={classes.headerIconContainer}>
                  {headerIcon}
                </Grid>
              )}
              {rightIcon && (
                <Grid item xs container justifyContent="flex-end">
                  {rightIcon}
                </Grid>
              )}
            </Grid>
            {secondaryText && (
              <Text className={classes.secondaryText}>{secondaryText}</Text>
            )}
            {tertiaryText && (
              <Text className={classes.secondaryText}>{tertiaryText}</Text>
            )}
          </Grid>
          {onChange && (
            <ButtonBase onClick={onChange}>
              <Text variant="custom" default={TEXT_DS.BODY_LINK_14}>
                {onChangeText || 'Edit'}
              </Text>
            </ButtonBase>
          )}
        </Grid>
      </Grid>
    </div>
  );
}
