import {
  amber,
  deepPurple,
  green,
  grey,
  orange,
  red,
  teal,
  cyan,
  deepOrange,
} from '@material-ui/core/colors';

export default {
  amber,
  deepPurple,
  green,
  grey,
  orange,
  red,
  teal,
  cyan,
  deepOrange,
};
