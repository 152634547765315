import React from 'react';

function MudEditPencil() {
  return (
    <svg
      width="27"
      height="28"
      viewBox="0 0 27 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" y="0.5" width="27" height="27" rx="13.5" fill="#9F7058" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.7726 9.04722C21.0759 9.35051 21.0759 9.84044 20.7726 10.1437L19.3494 11.5669L16.4332 8.6506L17.8563 7.22747C18.1596 6.92418 18.6495 6.92418 18.9528 7.22747L20.7726 9.04722ZM7 21V18.0838L15.601 9.48273L18.5173 12.399L9.91626 21H7Z"
        fill="white"
      />
      <rect x="0.5" y="0.5" width="27" height="27" rx="13.5" stroke="white" />
    </svg>
  );
}

export default MudEditPencil;
