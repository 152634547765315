import * as React from 'react';

const SvgComponent = (props: any) => (
  <svg
    width={33}
    height={33}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M26.183 4.778H7.516A2.667 2.667 0 0 0 4.85 7.444v18.667a2.667 2.667 0 0 0 2.666 2.667h18.667a2.667 2.667 0 0 0 2.667-2.667V7.444a2.667 2.667 0 0 0-2.667-2.666Z"
      stroke="#3C5D62"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.184 14.111a2 2 0 1 0 0-4 2 2 0 0 0 0 4ZM28.849 20.778l-6.667-6.667L7.516 28.778"
      stroke="#3C5D62"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SvgComponent;
