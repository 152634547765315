import React from 'react';

const MinusWhite = (props: any) => {
  const { fill, ...rest } = props;
  return (
    <svg
      width="41"
      height="40"
      viewBox="0 0 41 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path d="M25.5 21H15.5V19H25.5V21Z" fill="#ffffff" />
    </svg>
  );
};

export default MinusWhite;
