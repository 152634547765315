import * as React from 'react';
const SvgComponent = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={62}
    height={62}
    fill="none"
    {...props}
  >
    <path
      fill="#9F7058"
      d="M62 58a4 4 0 0 1-4 4H0c11.696-6.683 21.232-16.816 27.117-28.933l2.925-6.02A51.768 51.768 0 0 1 62 0v58Z"
    />
  </svg>
);
export default SvgComponent;
