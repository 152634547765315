import * as React from 'react';

const SvgComponent = (props: any) => (
  <svg
    width={18}
    height={18}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      className="icon"
      d="M12.75 15.75v-1.5a3 3 0 0 0-3-3h-6a3 3 0 0 0-3 3v1.5M6.75 8.25a3 3 0 1 0 0-6 3 3 0 0 0 0 6ZM17.25 15.75v-1.5A3 3 0 0 0 15 11.347M12 2.347a3 3 0 0 1 0 5.813"
      stroke="#fff"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SvgComponent;
