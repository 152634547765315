import * as React from 'react';

const SvgComponent = (props: any) => (
  <svg
    width={31}
    height={30}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        d="M19.312 6.25a6.25 6.25 0 0 1 4.938 4.938M19.312 1.25a11.25 11.25 0 0 1 9.938 9.925M28 21.15v3.75a2.5 2.5 0 0 1-2.725 2.5 24.736 24.736 0 0 1-10.788-3.837 24.376 24.376 0 0 1-7.5-7.5A24.738 24.738 0 0 1 3.15 5.225 2.5 2.5 0 0 1 5.637 2.5h3.75a2.5 2.5 0 0 1 2.5 2.15c.159 1.2.452 2.378.875 3.512A2.5 2.5 0 0 1 12.2 10.8l-1.588 1.587a20 20 0 0 0 7.5 7.5L19.7 18.3a2.5 2.5 0 0 1 2.637-.563 16.05 16.05 0 0 0 3.513.876A2.5 2.5 0 0 1 28 21.15Z"
        stroke="#373634"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" transform="translate(.5)" d="M0 0h30v30H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default SvgComponent;
