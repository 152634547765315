import * as React from 'react';
import { styled } from '@mui/material/styles';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';

import { typography, primaryColor } from '../constants/CSS';

const BootstrapTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: primaryColor,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: primaryColor,
    fontFamily: typography.bodyFontFamily,
    fontSize: '12px',
    fontWeight: '800px',
    letterSpacing: '0.01em',
    lineHeight: '18px',
    width: '200px',
    minHeight: '40px',
    textAlign: 'center',
    padding: '12px 16px 10px',
    borderRadius: '4px',
    opacity: '0.9 !important',
  },
}));
export default BootstrapTooltip;
