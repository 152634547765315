import React from 'react';

const PainterPaletteSVG = (props: any) => {
  const { fill, stroke, ...rest } = props;
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <g clip-path="url(#clip0_1021_31582)">
        <path
          d="M12.1665 21C9.77956 21 7.49037 20.0518 5.80254 18.364C4.11472 16.6761 3.1665 14.3869 3.1665 12C3.1665 9.61305 4.11472 7.32387 5.80254 5.63604C7.49037 3.94821 9.77956 3 12.1665 3C17.1365 3 21.1665 6.582 21.1665 11C21.1665 12.06 20.6925 13.078 19.8485 13.828C19.0045 14.578 17.8595 15 16.6665 15H14.1665C13.7204 14.9928 13.2846 15.135 12.9286 15.404C12.5726 15.673 12.3168 16.0533 12.2018 16.4844C12.0868 16.9155 12.1193 17.3727 12.2942 17.7833C12.469 18.1938 12.776 18.5341 13.1665 18.75C13.3662 18.9342 13.5031 19.1764 13.558 19.4425C13.613 19.7085 13.5832 19.9851 13.4729 20.2334C13.3625 20.4816 13.1772 20.6891 12.9429 20.8266C12.7086 20.9641 12.4371 21.0247 12.1665 21Z"
          stroke={stroke || '#88898A'}
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M7.6665 10.5C7.6665 10.7652 7.77186 11.0196 7.9594 11.2071C8.14693 11.3946 8.40129 11.5 8.6665 11.5C8.93172 11.5 9.18607 11.3946 9.37361 11.2071C9.56115 11.0196 9.6665 10.7652 9.6665 10.5C9.6665 10.2348 9.56115 9.98043 9.37361 9.79289C9.18607 9.60536 8.93172 9.5 8.6665 9.5C8.40129 9.5 8.14693 9.60536 7.9594 9.79289C7.77186 9.98043 7.6665 10.2348 7.6665 10.5Z"
          stroke={stroke || '#88898A'}
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M11.6665 7.5C11.6665 7.76522 11.7719 8.01957 11.9594 8.20711C12.1469 8.39464 12.4013 8.5 12.6665 8.5C12.9317 8.5 13.1861 8.39464 13.3736 8.20711C13.5611 8.01957 13.6665 7.76522 13.6665 7.5C13.6665 7.23478 13.5611 6.98043 13.3736 6.79289C13.1861 6.60536 12.9317 6.5 12.6665 6.5C12.4013 6.5 12.1469 6.60536 11.9594 6.79289C11.7719 6.98043 11.6665 7.23478 11.6665 7.5Z"
          stroke={stroke || '#88898A'}
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M15.6665 10.5C15.6665 10.7652 15.7719 11.0196 15.9594 11.2071C16.1469 11.3946 16.4013 11.5 16.6665 11.5C16.9317 11.5 17.1861 11.3946 17.3736 11.2071C17.5611 11.0196 17.6665 10.7652 17.6665 10.5C17.6665 10.2348 17.5611 9.98043 17.3736 9.79289C17.1861 9.60536 16.9317 9.5 16.6665 9.5C16.4013 9.5 16.1469 9.60536 15.9594 9.79289C15.7719 9.98043 15.6665 10.2348 15.6665 10.5Z"
          stroke={stroke || '#88898A'}
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1021_31582">
          <rect
            width="24"
            height="24"
            fill={fill || 'white'}
            transform="translate(0.166504)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
export default PainterPaletteSVG;
