import withStyles from '../withStyles';
import InputBase from '@material-ui/core/InputBase';
import theme from '../theme';

const SelectInput = withStyles({
  root: {},
  input: {
    position: 'relative',
    outline: 'none',
    borderRadius: 0,
    backgroundColor: 'white',
    border: `1px solid ${theme.palette.primary.main}`,
    borderColor: theme.palette.primary.main,
    boxShadow: 'none',
    padding: '24px 16px',
    // Use the system font instead of the default Roboto font.
    fontFamily: theme.bodyFontFamily,
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '24px',
    letterSpacing: '0.01em',
    '&:focus': {
      outline: 'none',
      borderRadius: 0,
      borderWidth: 1,
      borderColor: theme.palette.primary.main,
      boxShadow: 'none',
    },
  },
})(InputBase);

export default SelectInput;
