import React from 'react';
import makeStyles from '/makeStyles';

export const useImageStyles = (url: string) =>
  makeStyles(() => ({
    // to correctly display previously uploaded images (HC)
    // that aren't square
    image: {
      background: `url(${url}) center center / cover no-repeat`,
      width: 48,
      height: 48,
      borderRadius: 100,
    },
  }));

export default function SearchImage({ url }: { url: string }) {
  const imageClasses = useImageStyles(url)();
  return <div className={imageClasses.image} />;
}
