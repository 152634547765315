import React from 'react';

const WineGlassSmall = (props: any) => {
  const { fill, ...rest } = props;
  return (
    <svg
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.6357 7.14154C12.6707 9.36582 11.2439 11.2772 9.29541 11.6162V15.4231H11.2042V16.5H6.43227V15.4231H8.34103V11.6162C6.39147 11.2793 4.96364 9.36666 5.00071 7.14154V7.07154V7.00154L5.9694 2.90385L6.06484 2.5H11.5716L11.6814 2.90385L12.6357 7.00692V7.07154V7.14154ZM10.8367 3.57692H6.79969L6.05051 6.80769H11.5907L10.8367 3.57692Z"
        fill="white"
      />
    </svg>
  );
};

export default WineGlassSmall;
