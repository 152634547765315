import React from 'react';
import Button, { ButtonProps } from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import { Palette } from '@material-ui/core/styles/createPalette';

import theme from '../theme';
import { ButtonStyle } from './constants';
interface ButtonV2Props extends ButtonProps {
  loading?: boolean;
  type?: ButtonStyle;
  color?: keyof Palette;
  disabled?: boolean;
}

const getLinkStyles = (
  size: 'sm' | 'lg',
  disabled?: boolean,
  color?: keyof Palette,
) => ({
  border: 'none',
  padding: '0px',
  paddingBottom: '6px',
  minWidth: '20px',
  fontSize: size === 'sm' ? '12px' : '16px',
  height: size === 'sm' ? '21px' : '24px',
  backgroundColor: 'transparent',
  color: disabled
    ? theme.palette.greyMedium.main
    : color
    ? theme.palette[color]
    : theme.palette.primary.main,
  borderBottom: disabled
    ? `2px solid ${theme.palette.greyMedium.main}`
    : `2px solid ${theme.palette.primary.main}`,
  '&:hover': {
    backgroundColor: 'transparent',
    color: disabled
      ? theme.palette.greyMedium.main
      : theme.palette.primary.light,
    borderBottom: disabled
      ? `2px solid ${theme.palette.greyMedium.main}`
      : `2px solid ${theme.palette.primary.light}`,
  },
});

const getButtonStyles = ({ type, disabled, color }: ButtonV2Props) => {
  switch (type) {
    case ButtonStyle.SMALL_LINK:
      return {
        ...getLinkStyles('sm', disabled, color),
      };
    case ButtonStyle.LARGE_LINK:
      return {
        ...getLinkStyles('lg', disabled, color),
      };
    default:
      return {};
  }
};

const StyledButton = styled(Button)((props: ButtonV2Props) => {
  const buttonStyles = getButtonStyles(props);

  return {
    // common styles
    padding: '16px 24px',
    height: '48px',
    borderRadius: 0,
    fontSize: '12px',
    fontWeight: '500',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: '16px',
    letterSpacing: '0.1em',
    fontFamily: theme.bodyFontFamily,
    textTransform: 'uppercase',

    ...buttonStyles,
  };
});

const ButtonMui5 = (props: ButtonV2Props) => {
  const { ...buttonProps } = props;
  return <StyledButton disableRipple {...buttonProps} />;
};

export default ButtonMui5;
