import React from 'react';

const RefreshRightArrowCircle = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <g clip-path="url(#clip0_300_9204)">
        <path
          d="M12 0C5.38114 0 0 5.38114 0 12C0 18.6189 5.38114 24 12 24C18.6189 24 24 18.6189 24 12C24 5.38114 18.6189 0 12 0ZM12 22.5C6.21086 22.5 1.5 17.7891 1.5 12C1.5 6.21086 6.21086 1.5 12 1.5C17.7891 1.5 22.5 6.21086 22.5 12C22.5 17.7891 17.7891 22.5 12 22.5ZM17.9906 8.87829L17.2968 11.2173C17.1984 11.5455 16.9031 11.7564 16.5796 11.7564C16.5093 11.7564 16.439 11.747 16.364 11.7236L14.0249 11.0298C13.6265 10.9126 13.4015 10.4954 13.5187 10.097C13.6359 9.69854 14.0531 9.47355 14.4515 9.59076L15.1265 9.79232C14.2547 8.51732 12.6422 7.86576 11.0719 8.28765C10.2047 8.51734 9.44999 9.05642 8.94844 9.79703C8.71406 10.1392 8.24999 10.2283 7.90782 9.99391C7.56565 9.75956 7.47659 9.29547 7.71094 8.9533C8.41877 7.91735 9.47344 7.16735 10.6875 6.83924C12.9094 6.24391 15.1875 7.17674 16.4016 8.9908L16.561 8.45174C16.6782 8.0533 17.0954 7.8283 17.4938 7.94551C17.8829 8.0627 18.1079 8.4799 17.9907 8.87834L17.9906 8.87829ZM16.2937 15.0519C15.5859 16.0878 14.5312 16.8378 13.3171 17.1659C12.8671 17.2878 12.4077 17.3487 11.9531 17.3487C11.0437 17.3487 10.1484 17.1097 9.34213 16.6456C8.6343 16.2378 8.04369 15.68 7.60307 15.0191L7.44369 15.5581C7.34526 15.8862 7.04994 16.0972 6.72652 16.0972C6.65621 16.0972 6.5859 16.0878 6.5109 16.0644C6.11246 15.9472 5.88746 15.53 6.00467 15.1315L6.69844 12.7925C6.81563 12.394 7.23283 12.169 7.63127 12.2862L9.97033 12.98C10.3688 13.0972 10.5938 13.5144 10.4766 13.9128C10.3593 14.3113 9.94217 14.5363 9.54373 14.4191L8.86873 14.2175C9.18279 14.6769 9.59529 15.0613 10.0875 15.3472C10.9547 15.8487 11.9578 15.98 12.9234 15.7222C13.7906 15.4925 14.5453 14.9534 15.0469 14.2128C15.2813 13.8706 15.7453 13.7816 16.0875 14.0159C16.4391 14.2409 16.5281 14.7097 16.2938 15.0519L16.2937 15.0519Z"
          fill="#373634"
        />
      </g>
      <defs>
        <clipPath id="clip0_300_9204">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RefreshRightArrowCircle;
