import React from 'react';

const Search = (props: any) => {
  const { fill, ...rest } = props;
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 12.39C6 15.9191 8.86049 18.78 12.3891 18.78C15.9177 18.78 18.7782 15.9191 18.7782 12.39C18.7782 8.86089 15.9177 6 12.3891 6C8.86049 6 6 8.86089 6 12.39ZM12.3891 17.4466C15.1814 17.4466 17.445 15.1827 17.445 12.39C17.445 9.59727 15.1814 7.33333 12.3891 7.33333C9.59677 7.33333 7.33315 9.59727 7.33315 12.39C7.33315 15.1827 9.59677 17.4466 12.3891 17.4466ZM17.4342 17.4336C17.6969 17.1709 18.1227 17.1709 18.3853 17.4336L21.803 20.8518C22.0657 21.1144 22.0657 21.5403 21.803 21.803C21.5404 22.0657 21.1146 22.0657 20.8519 21.803L17.4342 18.3848C17.1716 18.1221 17.1716 17.6963 17.4342 17.4336Z"
        fill="#393939"
      />
    </svg>
  );
};

export default Search;
