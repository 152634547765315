import React, { useState, useCallback, RefObject } from 'react';
import Decimal from 'decimal.js';

import { styled } from '@mui/material/styles';
import AvatarEditor from '../AvatarEditor';
import Grid from '../Grid';
import makeStyles from '../makeStyles';
import Icons from '../Icons';
import Slider from '../Slider';
import { SliderProps } from '@mui/material/Slider';
import Text from '../Text';
import theme from '../theme';
import ThemeType from '../theme/ThemeType';

// rotation & scale constants
const INITIAL_ROTATE = 0;

const INITIAL_SCALE = 1.2;
const SCALE_STEP = 0.001;
const MANUAL_ADJUST_SCALE_STEP = 0.1;
const SCALE_MIN = 0.1;
const SCALE_MAX = 2;

// style constants
const LIGHT_COLOR = [255, 255, 255, 0.85];
const BORDER_RADIUS = 1;

const useStyles = makeStyles((theme: ThemeType) => ({
  main: {
    '& p': { fontWeight: 400, fontSize: '16px', lineHeight: '24px' },
  },
  bottomBanner: { flexWrap: 'nowrap', bottom: 0, width: '100%' },
  text: { paddingLeft: '12px', textDecoration: 'underline' },
  icon: { cursor: 'pointer' },
  editor: { maxWidth: '100%' },
  rotate: {
    borderRight: `1px solid ${theme.palette.greyMedium.main}`,
    height: '56px',
    width: '40%',
    cursor: 'pointer',
    [theme.breakpoints.up('sm')]: { width: '22%' },
  },
  scale: {
    width: '60%',
    [theme.breakpoints.up('sm')]: { width: '78%' },
  },
}));

const StyledSlider = styled((props: SliderProps) => <Slider {...props} />)(
  () => ({
    width: '54%',
    [theme.breakpoints.up('sm')]: { width: '68%' },
    color: theme.palette.greyDark.main,
  }),
);

type ImageUploadProps = {
  image: string;
  imageRef: RefObject<any>;
  width: number;
  height: number;
  border: number;
  borderRadius?: number;
};

const ImageUpload = (props: ImageUploadProps) => {
  const { image, imageRef, width, height, border, borderRadius } = props;
  const [rotate, setRotate] = useState(INITIAL_ROTATE);
  const [scale, setScale] = useState(INITIAL_SCALE);

  const classes = useStyles();

  const handleCrop = useCallback(
    (event, newValue) => {
      event.preventDefault();
      setScale(newValue);
    },
    [setScale],
  );

  const handleRotate = useCallback(() => {
    setRotate(rotate + 90);
  }, [setRotate, rotate]);

  const handleIncrementScale = useCallback(() => {
    const value = new Decimal(scale).plus(MANUAL_ADJUST_SCALE_STEP).toNumber();
    if (!(value > SCALE_MAX)) {
      setScale(value);
    }
  }, [scale]);

  const handleDecrementScale = useCallback(() => {
    const value = new Decimal(scale).minus(MANUAL_ADJUST_SCALE_STEP).toNumber();
    if (!(value < SCALE_MIN)) {
      setScale(value);
    }
  }, [scale]);

  return (
    <Grid
      container
      className={classes.main}
      alignItems="center"
      direction="column"
    >
      <AvatarEditor
        image={image}
        width={width}
        height={height}
        border={border}
        color={LIGHT_COLOR}
        borderRadius={borderRadius || BORDER_RADIUS}
        scale={scale}
        rotate={rotate}
        ref={imageRef}
        className={classes.editor}
      />
      <Grid container className={classes.bottomBanner} alignItems="center">
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          className={classes.rotate}
          onClick={handleRotate}
        >
          <Icons.Rotate className={classes.icon} />
          <div>
            <Text variant="body1" component="p" className={classes.text}>
              Rotate
            </Text>
          </div>
        </Grid>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          className={classes.scale}
        >
          <Icons.Minus
            className={classes.icon}
            onClick={handleDecrementScale}
          />
          <StyledSlider
            aria-label="Scale"
            size="small"
            step={SCALE_STEP}
            value={scale}
            min={SCALE_MIN}
            max={SCALE_MAX}
            onChange={handleCrop}
          />
          <Icons.Plus className={classes.icon} onClick={handleIncrementScale} />
        </Grid>
      </Grid>
    </Grid>
  );
};
export default ImageUpload;
