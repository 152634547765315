import AddCircleIcon from '@mui/icons-material/AddCircle';
import AddCircleOutlinedIcon from '@mui/icons-material/AddCircleOutline';
import ArrowHeroBannerDown from './assets/arrow-hero-banner-down';
import ArrowHeroBannerDownWildthingsOrange from './assets/arrow-hero-banner-down-wildthings-orange';
import AddIcon from '@mui/icons-material/Add';
import AppsIcon from '@mui/icons-material/Apps';

import AnimatedCalendarIcon from './assets/WineClub/animated-calendar-icon';
import AnimatedHeartInHandsIcon from './assets/WineClub/animated-heart-in-hands-icon';
import AnimatedHeartIcon from './assets/CorporateGifting/animated-heart-icon';
import AnimatedPiggyBankIcon from './assets/WineClub/animated-piggy-bank-icon';
import AnimatedShippingBoxIcon from './assets/WineClub/animated-shipping-box-icon';
import AnimatedSingleWineIcon from './assets/WineClub/animated-single-wine-icon';
import AnimatedWineAndLaptopIcon from './assets/WineClub/animated-wine-and-laptop-icon';
import AnimatedWineCustomizationIcon from './assets/WineClub/animated-wine-customization-icon';
import AnimatedWineGiftcon from './assets/WineClub/animated-wine-gift-icon';
import AnimatedWineryIcon from './assets/WineClub/animated-winery-icon';

import Archive from './assets/archive';
import AlertCircleOutline from './assets/alert-circle-outline';
import ArrowBrownRight from './assets/arrow-brown-right';
import ArrowDownSmall from './assets/arrow-down-small';
import ArrowDropDownCircleIcon from '@mui/icons-material/ArrowDropDownCircle';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowLeftBlack from './assets/arrow-left-black';
import ArrowRightBlack from './assets/arrow-right-black';
import ArrowRightBlackThick from './assets/arrow-right-black-thick';
import ArrowRightWhite from './assets/arrow-right-white';
import ArrowUpSmall from './assets/arrow-up-small';
import AddCircleAutocompleteIcon from './assets/add-circle-icon';
import AVTimer from '@mui/icons-material/AvTimer';

import BackArrow from './assets/back-arrow';
import BlankAvatar from './assets/blank-avatar';
import BlueClose from './assets/blueClose';
import BlueStarOn from './assets/star-blue-on';
import BlueStarOff from './assets/star-blue-off';
import BlueSuccess from './assets/blue-success';
import Bookmark from './assets/bookmark';

import BottleBlack from './assets/BottleBlack';
import BottleBlack4 from './assets/BottleBlack4';
import BottleBlack6 from './assets/BottleBlack6';
import BottleBlack12 from './assets/BottleBlack12';
import BottleBlue from './assets/BottleBlue';
import BottleBlue4 from './assets/BottleBlue4';
import BottleBlue6 from './assets/BottleBlue6';
import BottleBlue12 from './assets/BottleBlue12';
import BottleGray from './assets/BottleGray';
import BottleGray4 from './assets/BottleGray4';
import BottleGray6 from './assets/BottleGray6';
import BottleGray12 from './assets/BottleGray12';

import Calendar from './assets/calendar';
import CalendarWine from './assets/calendar-wine';
import CancelIcon from '@mui/icons-material/Cancel';
import CardDropdown from './assets/card-dropdown';
import CardPlayIcon from './assets/card-play-icon';
import CaretDown from './assets/caret-down';
import Cash from './assets/cash';
import CauseHeart from './assets/cause-heart';
import CeBrownIcon from './assets/ce-brown-icon';
import CeDashOHLogo from './assets/ce-dash-oh-logo';
import CheckMark from './assets/checkMark';
import CheckMarkItalic from './assets/checkmark-italic';
import CheckMarkWhite from './assets/checkMarkWhite';
import CheersLarge from './assets/cheers-lg';
import CheersSmall from './assets/cheers-sm';
import Circle from '@mui/icons-material/Circle';
import CircleOutlined from '@mui/icons-material/CircleOutlined';
import Charity from './assets/charity';
import Chat from './assets/chat';
import Checkbox from '@mui/icons-material/CheckBox';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CircleQuestion from './assets/circle-question';
import Clock from './assets/clock';
import ClockSolid from './assets/clock-solid';
import Close from './assets/close';
import CloseCircleBlack from './assets/close-circle-black';
import CloseCircleWhite from './assets/close-circle-white';
import CloseIcon from '@mui/icons-material/Close';
import CloseThin from './assets/close-thin';
import CloudUpload from './assets/cloud-upload';
import CopyPad from './assets/copy-pad';
import COCDatabaseEdit from './assets/CauseOfChoice/database-edit';
import COCFundraiser from './assets/CauseOfChoice/fundraiser';
import COCHeart from './assets/CauseOfChoice/heart';
import COCNotSure from './assets/CauseOfChoice/not-sure';
import COCHeartHands from './assets/CauseOfChoice/heart-hands';
import COCWineCheers from './assets/CauseOfChoice/wine-cheers';
import COCEducation from './assets/CauseOfChoice/education';
import COCPrograms from './assets/CauseOfChoice/programs';
import COCHunger from './assets/CauseOfChoice/hunger';
import COCWater from './assets/CauseOfChoice/water';
import COCHealth from './assets/CauseOfChoice/health';
import COCIncentives from './assets/CauseOfChoice/incentives';
import COCVerified from './assets/CauseOfChoice/verified';
import CoffeeIcon from '@mui/icons-material/LocalCafe';
import ContentCopy from '@mui/icons-material/ContentCopy';
import Copy from './assets/copy';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';

/* Wine Rep Dashboard Icons */
import CEDashGrid from './assets/CeDashboard/grid';
import CEDashReachOut from './assets/CeDashboard/reachouts';
import CEDashEvents from './assets/CeDashboard/events';
import CEDashOrders from './assets/CeDashboard/orders';
import CEDashContacts from './assets/CeDashboard/contacts';
import CEDashCalendarAndEvents from './assets/CeDashboard/calendar-and-events';
import CEDashCredits from './assets/CeDashboard/credits';
import CEDashReports from './assets/CeDashboard/reports';
import CEDashResource from './assets/CeDashboard/resource';
import CEDashTrainingCenter from './assets/CeDashboard/training-center';
import CEDashVine from './assets/CeDashboard/vine';
import CEDashHome from './assets/CeDashboard/home';
import CEDashLogOut from './assets/CeDashboard/log-out';
import CEDashLineGraph from './assets/CeDashboard/line-graph';
import CEDashCircleGraph from './assets/CeDashboard/cirlce-graph';
import CEDashAccount from './assets/CeDashboard/account';
import CeDashEmptyPhoto from './assets/CeDashboard/empty-photo';
import CeDashStars from './assets/CeDashboard/stars';

import DefaultProfile from './assets/default-profile';
import Download from './assets/download';
import DownloadNoBackground from './assets/download-no-background';

import EditIcon from '@mui/icons-material/Edit';
import EmptyProfile from './assets/empty-profile';
import Email from './assets/email';
import ErrorAlert from './assets/error-alert';
import ErrorIcon from '@mui/icons-material/Error';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import Facebook from './assets/facebook';
import FacebookLogin from './assets/facebook-login';
import FacebookLogo from './assets/facebook-logo';
import FastStartActiveCheckCircle from './assets/FastStart/ActiveCheckCircle';
import FastStartBanner from './assets/FastStart/Banner';
import FastStartCheck from './assets/FastStart/Check';
import FastStartClock from './assets/FastStart/Clock';
import FastStartInactiveCheckCircle from './assets/FastStart/InactiveCheckCircle';
import FastStartWideBanner from './assets/FastStart/WideBanner';
import Filter from './assets/filter';
import FilterSort from './assets/filter-sort';
import FilterTwoLines from './assets/filter-two-lines';
import FormIcon from './assets/CorporateGifting/form-icon';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';

import GiftIcon from '@mui/icons-material/CardGiftcard';
import Globe from './assets/globe';
import GoldStar from './assets/gold-star';
import GoldStarMedium from './assets/gold-star-medium';
import GoogleLogin from './assets/google-login';
import GreenCheck from './assets/green-check';
import GreyEditPencil from './assets/grey-edit-pencil';
import GreyEditPencilWithDropShadow from './assets/grey-edit-pencile-drop-shadow';

import Hamburger from './assets/hamburger';
import HamburgerMenu from './assets/hamburger-menu';
import HeartOutline from './assets/heart-outline';

import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

import ImportExport from '@mui/icons-material/ImportExport';
import InfoAlert from './assets/info-alert';
import InfoIcon from '@mui/icons-material/Info';
import InPerson from './assets/in-person';
import InfoOutLinedIcon from './assets/info-outlined-icon';
import Instagram from './assets/instagram';
import InstagramLogo from './assets/instagram-logo';
import InviteResults from './assets/invite-results';
import Invites from './assets/invites';

import KitchenIcon from '@mui/icons-material/Restaurant';

import LaunchIcon from '@mui/icons-material/Launch';
import LinkedInLogo from './assets/linkedin-logo';
import Loader from './assets/loader';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import LockCircle from './assets/lock-circle';
import LockClosed from './assets/lock-closed';
import LockClosedV2 from './assets/lock-closed-v2';
import LockClosedAddToCart from './assets/lock-closed-add-to-cart';
import LockOpen from './assets/lock-open';

import Message from './assets/message';
import MessageSmall from './assets/message-small';
import Minus from './assets/minus';
import MinusWhite from './assets/minus-white';
import MinusThin from './assets/minus-thin';
import MinusMedium from './assets/minus-medium';
import MoreHorizontal from './assets/more-horizontal';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Monitor from './assets/monitor';
import MudEditPencil from './assets/mud-edit-pencil';

/* MockupGenerator Icons */
import MUGColor from './assets/MockupGenerator/painter-palette';
import MUGHelp from './assets/MockupGenerator/question-mark';
import MUGLogo from './assets/MockupGenerator/upload';
import MUGText from './assets/MockupGenerator/typography';

import NavOHLogo from './assets/nav-OH-logo';
import NotificationsIcon from '@mui/icons-material/Notifications';

import OHCheckCircleIcon from './assets/check-circle-icon';
import OHLogo from './assets/OH-logo';
import OHLogoWhite from './assets/OH-logo-white';
import OHDashLogo from './assets/oh-dash-logo';

import Pencil from './assets/pencil';
import PersonIcon from '@mui/icons-material/Person';
import Phone from './assets/phone';
import PhoneSmall from './assets/phone-small';
import PhotoUpload from './assets/photo-upload';
import Plus from './assets/plus';
import PlusWhite from './assets/plus-white';
import PlusDisabled from './assets/plus-disabled';
import PlusThin from './assets/plus-thin';
import PlusMedium from './assets/plus-medium';
import PlusMediumDisabled from './assets/plus-medium-disabled';

import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import RadioButtonCheckedOutlinedIcon from '@mui/icons-material/RadioButtonCheckedOutlined';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import RadioButtonUncheckedOutlinedIcon from '@mui/icons-material/RadioButtonUncheckedOutlined';
import RadioCheckOn from './assets/radio-check-on';

import ReachOutsCopyButton from './assets/reachouts-copy-button';
import ReachOutsDownloadButton from './assets/reachouts-download-button';

import RefreshLeftArrow from './assets/refresh-left-arrow';
import RefreshRightArrow from './assets/refresh-right-arrow';
import RefreshRightArrowCircle from './assets/refresh-right-arrow-circle';

import RemoveIcon from '@mui/icons-material/Remove';
import Restore from './assets/restore';
import RightArrow from './assets/right-arrow';
import RightArrowWhite from './assets/right-arrow-white';
import Rotate from './assets/rotate';

import SaveDonate from './assets/save-donate';
import Savings from './assets/savings';
import Search from './assets/search';
import SearchIcon from '@mui/icons-material/Search';
import ShippingIcon from './assets/CorporateGifting/shipping-icon';
import Shipper from './assets/shipper';
import ShoppingBag from './assets/shopping-bag';
import SliderLeftArrow from './assets/slider-left-arrow';
import SliderRightArrow from './assets/slider-right-arrow';
import SliderLeftArrowBlack from './assets/slider-left-arrow-black';
import SmallLeftArrow from './assets/small-left-arrow';
import SmallRightArrow from './assets/small-right-arrow';
import SMS from './assets/sms';
import SortArrows from './assets/sort-arrows';
import SpeechBubble from './assets/speech-bubble';
import SpeechBubbleSmall from './assets/speech-bubble-small';
import SpilledWine from './assets/spilled-wine';
import Star from './assets/star';
import StarLarge from './assets/star-large';
import StarMedium from './assets/star-medium';
import StarOutline from './assets/star-outline';
import Stars from './assets/stars';
import StarsThreeOutline from './assets/stars-three-outline';
import SvgConnectPeople from './assets/connect-people';
import SvgDelete from './assets/delete';
import SvgDiscover from './assets/discover';
import SvgDollar from './assets/dollar';
import SvgGift from './assets/gift';
import SvgRewards from './assets/rewards';
import SvgRight from './assets/right';
import SvgShipping from './assets/shipping';
import SvgTag from './assets/tag';

import ThreeStars from './assets/three-stars';
import Trash from './assets/trash';
import Twitter from './assets/twitter';
import TwoGlassCheers from './assets/two-glass-cheers';

import UpsellBottomBorder from './assets/upsell-bottom-border';
import UpsellTopBorder from './assets/upsell-top-border';
import UpsellBottomBorderDesktop from './assets/upsell-bottom-border-desktop';
import UpsellTopBorderDesktop from './assets/upsell-top-border-desktop';

import User from './assets/user';
import ValidationCheckmark from './assets/validation-checkmark';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import WarningIcon from '@mui/icons-material/Warning';
import WhiteLock from './assets/white-lock';
import WineBottleAdded from './assets/wine-bottle-added';
import WineBottleNotAdded from './assets/wine-bottle-not-added';
import WineBottles from './assets/wine-bottles';
import WineCheers from './assets/wine-cheers';
import WineClubEarlyRelease from './assets/wine-club-early-release';
import WineClubExclusive from './assets/wine-club-exclusive';
import WineGiftIcon from './assets/CorporateGifting/wine-gift-icon';
import WineGlassSmall from './assets/wine-glass-small';
import WineIcon from '@mui/icons-material/LocalBar';

import XSleftArrow from './assets/xs-left-arrow';
import XSrightArrow from './assets/xs-right-arrow';

import YouTube from './assets/youtube';

export default {
  Archive,
  ArrowRightWhite,
  ArrowRightBlack,
  ArrowRightBlackThick,
  ArrowBrownRight,
  ArrowHeroBannerDown,
  ArrowHeroBannerDownWildthingsOrange,
  ArrowLeftBlack,
  AddCircleIcon,
  AnimatedCalendarIcon,
  AnimatedHeartInHandsIcon,
  AnimatedHeartIcon,
  AnimatedPiggyBankIcon,
  AnimatedShippingBoxIcon,
  AnimatedSingleWineIcon,
  AnimatedWineAndLaptopIcon,
  AnimatedWineCustomizationIcon,
  AnimatedWineGiftcon,
  AnimatedWineryIcon,
  AlertCircleOutline,
  AddCircleAutocompleteIcon,
  ArrowDownSmall,
  ArrowDropDownCircleIcon,
  ArrowDropDownIcon,
  ArrowUpSmall,
  AddCircleOutlinedIcon,
  AddIcon,
  AppsIcon,
  AVTimer,
  BackArrow,
  BlankAvatar,
  BlueClose,
  BlueStarOn,
  BlueStarOff,
  BlueSuccess,
  Bookmark,
  BottleBlack,
  BottleBlack4,
  BottleBlack6,
  BottleBlack12,
  BottleBlue,
  BottleBlue4,
  BottleBlue6,
  BottleBlue12,
  BottleGray,
  BottleGray4,
  BottleGray6,
  BottleGray12,
  Calendar,
  CalendarWine,
  CancelIcon,
  CardDropdown,
  CardPlayIcon,
  Cash,
  CaretDown,
  CauseHeart,
  CeBrownIcon,
  CeDashOHLogo,
  CEDashReachOut,
  CEDashCalendarAndEvents,
  CEDashGrid,
  CEDashEvents,
  CEDashOrders,
  CEDashContacts,
  CEDashCredits,
  CEDashReports,
  CEDashResource,
  CEDashVine,
  CEDashHome,
  CEDashLogOut,
  CEDashLineGraph,
  CEDashCircleGraph,
  CEDashTrainingCenter,
  CEDashAccount,
  CeDashEmptyPhoto,
  CeDashStars,
  Charity,
  Chat,
  Checkbox,
  CheckCircleIcon,
  CheckMark,
  CheckMarkItalic,
  CheckMarkWhite,
  CheersLarge,
  CheersSmall,
  Circle,
  CircleOutlined,
  CircleQuestion,
  Clock,
  ClockSolid,
  Close,
  CloseCircleBlack,
  CloseCircleWhite,
  CloseIcon,
  CloseThin,
  CloudUpload,
  COCVerified,
  COCHeartHands,
  COCNotSure,
  COCWineCheers,
  COCHeart,
  COCDatabaseEdit,
  COCFundraiser,
  COCEducation,
  COCPrograms,
  COCWater,
  COCHealth,
  COCHunger,
  COCIncentives,
  CoffeeIcon,
  ContentCopy,
  Copy,
  CopyPad,
  CreateOutlinedIcon,
  DefaultProfile,
  Download,
  DownloadNoBackground,
  EditIcon,
  Email,
  EmptyProfile,
  ErrorAlert,
  ErrorIcon,
  ErrorOutlineIcon,
  ExpandMoreIcon,
  Facebook,
  FacebookLogin,
  FacebookLogo,
  FastStartActiveCheckCircle,
  FastStartBanner,
  FastStartCheck,
  FastStartClock,
  FastStartInactiveCheckCircle,
  FastStartWideBanner,
  Filter,
  FilterSort,
  FilterTwoLines,
  FormIcon,
  FormatListBulletedIcon,
  GiftIcon,
  Globe,
  GoldStar,
  GoldStarMedium,
  GoogleLogin,
  GreenCheck,
  GreyEditPencil,
  GreyEditPencilWithDropShadow,
  Hamburger,
  HamburgerMenu,
  HeartOutline,
  HelpOutlineIcon,
  ImportExport,
  InfoAlert,
  InfoIcon,
  InPerson,
  InfoOutLinedIcon,
  Instagram,
  InstagramLogo,
  InviteResults,
  Invites,
  KitchenIcon,
  LaunchIcon,
  LinkedInLogo,
  LocationOnIcon,
  Loader,
  LockCircle,
  LockClosed,
  LockClosedV2,
  LockClosedAddToCart,
  LockOpen,
  Message,
  MessageSmall,
  Minus,
  MinusWhite,
  MinusThin,
  MinusMedium,
  Monitor,
  MoreHorizontal,
  MoreVertIcon,
  MudEditPencil,
  MUGColor,
  MUGHelp,
  MUGLogo,
  MUGText,
  NavOHLogo,
  NotificationsIcon,
  OHCheckCircleIcon,
  OHLogo,
  OHLogoWhite,
  OHDashLogo,
  Pencil,
  PersonIcon,
  Phone,
  PhoneSmall,
  PhotoUpload,
  Plus,
  PlusWhite,
  PlusDisabled,
  PlusThin,
  PlusMedium,
  PlusMediumDisabled,
  RadioButtonCheckedIcon,
  RadioButtonCheckedOutlinedIcon,
  RadioButtonUncheckedIcon,
  RadioButtonUncheckedOutlinedIcon,
  RadioCheckOn,
  ReachOutsCopyButton,
  ReachOutsDownloadButton,
  RefreshLeftArrow,
  RefreshRightArrow,
  RefreshRightArrowCircle,
  RemoveIcon,
  Restore,
  RightArrow,
  RightArrowWhite,
  Rotate,
  SaveDonate,
  Savings,
  Search,
  SearchIcon,
  ShippingIcon,
  Shipper,
  ShoppingBag,
  SliderLeftArrow,
  SliderLeftArrowBlack,
  SliderRightArrow,
  SmallLeftArrow,
  SmallRightArrow,
  SMS,
  SortArrows,
  SpeechBubble,
  SpeechBubbleSmall,
  SpilledWine,
  Star,
  StarLarge,
  StarMedium,
  StarOutline,
  Stars,
  StarsThreeOutline,
  SvgTag,
  SvgRight,
  SvgGift,
  SvgRewards,
  SvgConnectPeople,
  SvgDelete,
  SvgShipping,
  SvgDiscover,
  SvgDollar,
  ThreeStars,
  Trash,
  Twitter,
  TwoGlassCheers,
  UpsellBottomBorder,
  UpsellTopBorder,
  UpsellBottomBorderDesktop,
  UpsellTopBorderDesktop,
  User,
  ValidationCheckmark,
  VisibilityIcon,
  VisibilityOffIcon,
  WarningIcon,
  WhiteLock,
  WineBottleAdded,
  WineBottleNotAdded,
  WineBottles,
  WineCheers,
  WineClubExclusive,
  WineClubEarlyRelease,
  WineGiftIcon,
  WineGlassSmall,
  WineIcon,
  XSleftArrow,
  XSrightArrow,
  YouTube,
};
