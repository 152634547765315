import React from 'react';
import theme from '../../../theme';

const FastStartWideBanner = (props: any) => {
  const { fill, ...rest } = props;
  return (
    <svg
      width="137"
      height="24"
      viewBox="0 0 137 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M0.588589 2.9486C-0.129294 1.61613 0.835759 0 2.34931 0H134.651C136.164 0 137.129 1.61613 136.411 2.94861L132.181 10.7999C131.869 11.3803 131.862 12.0777 132.164 12.6639L136.499 21.0846C137.184 22.4156 136.218 24 134.721 24H2.2791C0.78202 24 -0.18433 22.4156 0.500912 21.0846L4.83599 12.6639C5.1378 12.0777 5.13126 11.3803 4.81852 10.7999L0.588589 2.9486Z"
        fill={fill || theme.palette.bronzeTint.main}
      />
    </svg>
  );
};

export default FastStartWideBanner;
