import React from 'react';

function Email(props: any) {
  return (
    <svg
      width="60"
      height="61"
      viewBox="0 0 60 61"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        y="0.5"
        width="60"
        height="60"
        rx="12"
        fill="url(#paint0_linear_1523_54405)"
      />
      <path
        d="M16.6666 17.1667H43.3333C45.1666 17.1667 46.6666 18.6667 46.6666 20.5V40.5C46.6666 42.3333 45.1666 43.8333 43.3333 43.8333H16.6666C14.8333 43.8333 13.3333 42.3333 13.3333 40.5V20.5C13.3333 18.6667 14.8333 17.1667 16.6666 17.1667Z"
        stroke="white"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M46.6666 20.5L30 32.1667L13.3333 20.5"
        stroke="white"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1523_54405"
          x1="30"
          y1="0.5"
          x2="30"
          y2="60.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2E77E7" />
          <stop offset="1" stopColor="#34C3F3" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default Email;
