import * as React from 'react';

const SvgComponent = (props: any) => (
  <svg
    width={40}
    height={40}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M36.717 2.335a1 1 0 0 1 .883 1.358L25.944 36.997a1 1 0 0 1-1.858.076l-6.51-14.649-14.649-6.51a1 1 0 0 1 .076-1.858l33.316-11.66a1 1 0 0 1 .398-.061Zm-3.856 3.39L6.044 15.11l12.068 5.363 14.75-14.75ZM19.526 21.888 34.275 7.14l-9.386 26.817-5.363-12.068Z"
      fill="#373634"
    />
  </svg>
);

export default SvgComponent;
