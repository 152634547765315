import React from 'react';

// JC: Do we need this export here? It won't build for me if the interface is not exported.
export interface SliderProps {
  fill?: string;
}
const SliderLeftArrow = (props: SliderProps) => (
  <span>
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.794 12.1242C25.9314 11.9907 26 11.8369 26 11.6633C26 11.4896 25.9314 11.3359 25.794 11.2026L24.7629 10.2005C24.6255 10.0669 24.4674 10 24.2889 10C24.1103 10 23.9521 10.0669 23.8147 10.2005L14.206 19.539C14.0686 19.6726 14 19.8263 14 20C14 20.1737 14.0686 20.3274 14.206 20.461L23.8144 29.7995C23.9518 29.9333 24.11 30 24.2887 30C24.4671 30 24.6253 29.9333 24.7627 29.7995L25.7937 28.7979C25.9312 28.6644 25.9997 28.5106 25.9997 28.3367C25.9997 28.1633 25.9312 28.0096 25.7937 27.876L17.6904 20L25.794 12.1242Z"
        fill={props.fill || '#9F7058'}
      />
    </svg>
  </span>
);
export default SliderLeftArrow;
