import React from 'react';
import theme from '../../../theme';

const FastStartBanner = (props: any) => {
  const { fill, ...rest } = props;
  return (
    <svg
      width="130"
      height="24"
      viewBox="0 0 111 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M0.224707 2.80119C-0.352989 1.47987 0.615124 0 2.05722 0H108.743C110.185 0 111.153 1.47987 110.575 2.80119L107.015 10.9439C106.796 11.4443 106.792 12.0123 107.002 12.5161L110.642 21.229C111.193 22.5466 110.225 24 108.797 24H2.00308C0.575107 24 -0.392813 22.5466 0.157653 21.229L3.79771 12.5161C4.00821 12.0123 4.00354 11.4443 3.7848 10.9439L0.224707 2.80119Z"
        fill={fill || theme.palette.bronzeTint.main}
      />
    </svg>
  );
};

export default FastStartBanner;
