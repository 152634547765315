import React from 'react';

const RightArrowWhite = (props: any) => {
  const { fill, ...rest } = props;
  return (
    <svg
      width="40"
      height="41"
      viewBox="0 0 40 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.1373 16.2394C16.0457 16.1592 16 16.067 16 15.9628C16 15.8586 16.0457 15.7664 16.1373 15.6864L16.8247 15.0851C16.9163 15.005 17.0218 14.9648 17.1407 14.9648C17.2598 14.9648 17.3653 15.005 17.4569 15.0851L23.8627 20.6883C23.9543 20.7684 24 20.8607 24 20.9648C24 21.069 23.9543 21.1613 23.8627 21.2414L17.4571 26.8446C17.3654 26.9248 17.26 26.9648 17.1409 26.9648C17.0219 26.9648 16.9165 26.9248 16.8249 26.8446L16.1375 26.2436C16.0459 26.1635 16.0002 26.0712 16.0002 25.9669C16.0002 25.8628 16.0459 25.7706 16.1375 25.6905L21.5397 20.9648L16.1373 16.2394Z"
        fill={fill || '#ffffff'}
      />
    </svg>
  );
};

export default RightArrowWhite;
