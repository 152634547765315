import React from 'react';

const SvgFilterSort = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="19"
    height="10"
    fill="none"
    viewBox="0 0 19 10"
  >
    <path
      fill="#373634"
      fillRule="evenodd"
      d="M.967 0v1.2h18V0h-18zm7 9.6h4V8.4h-4v1.2zm8-4.2h-12V4.2h12v1.2z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgFilterSort;
