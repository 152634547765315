import React from 'react';

const SmallLeftArrow = (props: any) => (
  <svg
    width="8"
    height="12"
    viewBox="0 0 8 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.86266 1.27453C7.95428 1.19439 8 1.10215 8 0.997958C8 0.893765 7.95428 0.801525 7.86266 0.721535L7.1753 0.12028C7.08368 0.0401427 6.97824 0 6.85929 0C6.74017 0 6.63472 0.0401427 6.54311 0.12028L0.137338 5.72343C0.045723 5.80357 0 5.89581 0 6C0 6.10419 0.045723 6.19643 0.137338 6.27657L6.54294 11.8797C6.63455 11.96 6.74 12 6.85912 12C6.97807 12 7.08352 11.96 7.17513 11.8797L7.86249 11.2788C7.95411 11.1986 7.99983 11.1064 7.99983 11.002C7.99983 10.898 7.95411 10.8058 7.86249 10.7256L2.46027 6L7.86266 1.27453Z"
      fill="#373634"
    />
  </svg>
);

export default SmallLeftArrow;
