import * as React from 'react';
import MuiCheckbox, { CheckboxProps } from '@mui/material/Checkbox';
import { styled } from '@mui/material/styles';
import theme from '../theme';

const OHWCheckbox = styled(MuiCheckbox)(() => ({
  color: theme.palette.primary.main,
  '&.Mui-checked': {
    color: theme.palette.primary.main,
  },
}));

const Checkbox = (props: CheckboxProps) => <OHWCheckbox {...props} />;

export default Checkbox;
