import React from 'react';

const UploadSVG = (props: any) => {
  const { fill, stroke, ...rest } = props;
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <g clip-path="url(#clip0_271_16055)">
        <path
          d="M7.37496 18C6.12723 18 4.93061 17.5259 4.04834 16.682C3.16607 15.8381 2.67041 14.6935 2.67041 13.5C2.67041 12.3065 3.16607 11.1619 4.04834 10.318C4.93061 9.47412 6.12723 9.00001 7.37496 9.00001C7.66964 7.68719 8.53172 6.5335 9.77154 5.79273C10.3854 5.42594 11.0736 5.17156 11.7967 5.04412C12.5199 4.91669 13.2638 4.91869 13.9861 5.05001C14.7083 5.18133 15.3948 5.43941 16.0062 5.80949C16.6177 6.17958 17.1422 6.65443 17.5497 7.20694C17.9573 7.75945 18.2399 8.37879 18.3815 9.02961C18.5231 9.68043 18.5209 10.35 18.375 11H19.375C20.3032 11 21.1935 11.3688 21.8498 12.0251C22.5062 12.6815 22.875 13.5718 22.875 14.5C22.875 15.4283 22.5062 16.3185 21.8498 16.9749C21.1935 17.6313 20.3032 18 19.375 18H18.375"
          stroke={stroke || '#88898A'}
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M9.375 15L12.375 12L15.375 15"
          stroke={stroke || '#88898A'}
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M12.375 12V21"
          stroke={stroke || '#88898A'}
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_271_16055">
          <rect
            width="24"
            height="24"
            fill={fill || 'white'}
            transform="translate(0.375)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
export default UploadSVG;
