import React from 'react';

function Icon(props: any) {
  const { fill } = props;
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M17.7669 23.4676L14.2109 19.736L13 20.9978L17.7669 26L28 15.2617L26.7976 14L17.7669 23.4676Z"
        fill={fill || '#268794'}
      />
    </svg>
  );
}

export default Icon;
