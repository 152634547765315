import React from 'react';

const WhiteLock = () => {
  return (
    <svg
      width="20"
      height="24"
      viewBox="0 0 20 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 0C6.26611 0 3.24324 3.05034 3.24324 6.81818V9.81818H1.91723C0.864655 9.81818 0 10.6907 0 11.7528V22.0653C0 23.1275 0.864655 24 1.91723 24H18.0828C19.1353 24 20 23.1275 20 22.0653V11.7528C20 10.6907 19.1353 9.81818 18.0828 9.81818H16.7568V6.81818C16.7568 3.05034 13.7339 0 10 0ZM10 1.09091C13.1537 1.09091 15.6757 3.63584 15.6757 6.81818V9.81818H4.32432V6.81818C4.32432 3.63584 6.84632 1.09091 10 1.09091ZM12.7027 16.9091C12.7027 15.4093 11.4863 14.1818 10 14.1818C8.51374 14.1818 7.2973 15.4093 7.2973 16.9091C7.2973 18.4088 8.51374 19.6364 10 19.6364C11.4863 19.6364 12.7027 18.4088 12.7027 16.9091Z"
        fill="white"
      />
    </svg>
  );
};

export default WhiteLock;
