import clsx from 'clsx';
import React from 'react';
import SwipeableDrawer, {
  SwipeableDrawerProps,
} from '@material-ui/core/SwipeableDrawer';
import { DrawerProps } from '@material-ui/core/Drawer';
import withDefaults from '../withDefaults';

const styles = {
  root: {},
  paper: {},
};

const OHWSwipeableDrawer = ({
  classes,
  className: classNamesProp,
  children,
  paperClasses,
  ...rest
}: SwipeableDrawerProps & DrawerProps & { paperClasses: any }) => {
  const className = clsx(classes?.root, classNamesProp);
  const paperClassName = paperClasses
    ? clsx(paperClasses.root, paperClasses.paper)
    : '';
  return (
    // According to MUI documentation "the props of the Drawer component are also
    // available on SwipeableDrawer." But SwipeableDrawerProps type doesn't reflect
    // that yet, so ignore TS error for now
    // @ts-ignore
    <SwipeableDrawer
      // @ts-ignore
      className={className}
      PaperProps={{ className: paperClassName }}
      {...rest}
    >
      {children}
    </SwipeableDrawer>
  );
};

export default withDefaults(styles, { name: 'OH-Swipeable-Drawer' })(
  OHWSwipeableDrawer,
);
