import React from 'react';

const Twitter = (props: any) => {
  const { fill, ...rest } = props;
  return (
    <svg
      width="40"
      height="41"
      viewBox="0 0 40 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <rect y="0.464844" width="40" height="40" rx="20" fill="white" />
      <path
        d="M27.7511 15.7789C28.5602 15.2684 29.181 14.4588 29.4723 13.4944C28.7148 13.9684 27.8778 14.3111 26.9847 14.4966C26.272 13.693 25.2534 13.1919 24.1261 13.1919C21.9635 13.1919 20.2109 15.0415 20.2109 17.3224C20.2109 17.6462 20.2434 17.9618 20.3106 18.2631C17.0565 18.0906 14.1711 16.4478 12.2382 13.9471C11.9009 14.5593 11.7081 15.2684 11.7081 16.0247C11.7081 17.4571 12.3995 18.7217 13.4506 19.4639C12.8085 19.4426 12.2045 19.2547 11.6756 18.9474V18.9982C11.6756 21.0003 13.0248 22.6702 14.8188 23.0484C14.4894 23.1453 14.1442 23.1937 13.7856 23.1937C13.5335 23.1937 13.287 23.1689 13.0494 23.1205C13.547 24.7608 14.9936 25.9569 16.7081 25.9888C15.3668 27.0973 13.6781 27.7568 11.8437 27.7568C11.5277 27.7568 11.2151 27.7391 10.9092 27.7001C12.6427 28.8713 14.7011 29.5555 16.9131 29.5555C24.1183 29.5555 28.0559 23.2611 28.0559 17.8022C28.0559 17.6226 28.0537 17.4441 28.0458 17.268C28.8112 16.6854 29.4768 15.9574 30.0001 15.1289C29.2975 15.4575 28.5422 15.6796 27.7511 15.7789Z"
        fill="#373634"
      />
    </svg>
  );
};

export default Twitter;
