import React, { ReactNode } from 'react';
import clsx from 'clsx';

import AccordionDetails, {
  AccordionDetailsProps,
} from '@mui/material/AccordionDetails';
import { Theme } from '@material-ui/core/styles';

import Text from '../Text';
import withDefaults from '../withDefaults';

// import Icons from '../Icons';

const styles = (theme: Theme) => ({
  root: {
    '& p': {
      fontSize: '16px',
      lineHeight: '24px',
      letterSpacing: '0.01em',
      marginBottom: '0',
      [theme.breakpoints.up('md')]: {
        fontSize: '18px',
      },
    },
  },
});

interface OHWAccordionDetailsProps extends AccordionDetailsProps {
  text?: string;
  children?: ReactNode;
  expandIcon?: ReactNode;
}

const OHWAccordionDetails = ({
  classes,
  className: classNamesProp,
  children,
  expandIcon,
  text,
  ...rest
}: OHWAccordionDetailsProps) => {
  const className = classes ? clsx(classes.root, classNamesProp) : undefined;
  return (
    <AccordionDetails
      id="OWHAccordionDetails"
      className={className}
      /* expandIcon={expandIcon ? expandIcon : <Icons.ExpandMoreIcon />} */
      {...rest}
    >
      {text ? <Text variant="body2">{text}</Text> : children}
    </AccordionDetails>
  );
};

export default withDefaults(styles, { name: 'OH-AccordionDetails' })(
  OHWAccordionDetails,
);
