import React from 'react';
import styled from '@emotion/styled';
import Text from '../Text';
import { TEXT_DS } from '../Text/constants';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Icons from '../Icons';

//#region Styles

const StyledToggleButtonGroup = styled(ToggleButtonGroup)`
  && {
    border-radius: 100px;
    padding: 5px;
    background-color: #e9eae9;
  }
  .MuiToggleButton-root {
    border-radius: 100px;
    border: none;
    background-color: #e9eae9;
    color: #373634;
    p {
      text-transform: none;
    }
    gap: 8px;
  }
  .MuiToggleButton-root.Mui-selected {
    border-radius: 100px;
    background-color: #3c5d62;
    color: #fff;
    p {
      color: #fff;
    }
  }
`;

//#region Component
interface TabToggleProps {
  fullWidth: boolean;
  options: string[];
  value: string;
  onChange: (value: string) => void;
}

export const TabToggle = ({
  fullWidth,
  options,
  value,
  onChange,
}: TabToggleProps) => {
  //#region HTML

  return (
    <StyledToggleButtonGroup
      fullWidth={fullWidth}
      value={value}
      exclusive
      onChange={(_, newValue) => {
        onChange(newValue);
      }}
    >
      {options.map((option, index) => (
        <ToggleButton key={index} value={option} aria-label={option}>
          {option === value ? <Icons.CheckCircleIcon fill="#e9eae9" /> : null}{' '}
          <Text variant="custom" default={TEXT_DS.BODY_SEMIBOLD_16}>
            {option}
          </Text>
        </ToggleButton>
      ))}
    </StyledToggleButtonGroup>
  );
};

export default TabToggle;
