import React from 'react';

const CircleQuestion = (props: any) => {
  const { fill, ...rest } = props;
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M11 21C16.5228 21 21 16.5228 21 11C21 5.47715 16.5228 1 11 1C5.47715 1 1 5.47715 1 11C1 16.5228 5.47715 21 11 21Z"
        stroke="#373634"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8.09 8.00008C8.3251 7.33175 8.78915 6.76819 9.39995 6.40921C10.0108 6.05024 10.7289 5.91902 11.4272 6.03879C12.1255 6.15857 12.7588 6.52161 13.2151 7.06361C13.6713 7.60561 13.9211 8.2916 13.92 9.00008C13.92 11.0001 10.92 12.0001 10.92 12.0001"
        stroke="#373634"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M11 16H11.01"
        stroke="#373634"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default CircleQuestion;
