import React from 'react';

const RadioCheckOn = (props: any) => {
  const { fill, ...rest } = props;

  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 20C10 14.48 14.48 10 20 10C25.52 10 30 14.48 30 20C30 25.52 25.52 30 20 30C14.48 30 10 25.52 10 20ZM13 20.2015L18 25L27 16.3628L25.59 15L18 22.2841L14.41 18.8484L13 20.2015Z"
        fill={fill || '#AB9B84'}
      />
    </svg>
  );
};

export default RadioCheckOn;
