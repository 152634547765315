import React from 'react';

function BottleGray12() {
  return (
    <svg
      width="16"
      height="38"
      viewBox="0 0 16 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.7327 21.1004C14.4206 19.3364 13.1778 16.714 12.9123 16.12C12.6469 15.5256 10.9215 12.202 10.4909 10.2296C10.1113 8.488 9.89254 3.882 9.86841 3.3536C9.8668 3.3208 9.87753 3.2892 9.89951 3.2608L9.92203 3.2316C9.95795 3.1848 9.96332 3.128 9.93597 3.078C9.90755 3.0264 9.91399 2.968 9.95313 2.9204L10.1413 2.6912C10.1703 2.656 10.1815 2.614 10.173 2.5732L10.0974 2.2068C10.0909 2.176 10.0738 2.1468 10.047 2.1228L9.96922 2.0516C9.92793 2.0136 9.9097 1.9652 9.91881 1.9168C9.93919 1.8044 9.96546 1.584 9.90273 1.3968C9.82069 1.152 9.73865 1.134 9.51614 1.028C9.47914 1.0092 9.43517 1 9.39067 1H7.94832H6.60946C6.56496 1 6.52153 1.0092 6.484 1.0272C6.26148 1.1336 6.17944 1.1516 6.0974 1.396C6.03467 1.5832 6.06094 1.8036 6.08132 1.916C6.09043 1.9644 6.0722 2.0132 6.03092 2.0508L5.95317 2.122C5.9269 2.1464 5.9092 2.1752 5.90277 2.206L5.82716 2.5724C5.81859 2.6132 5.82985 2.6552 5.8588 2.6904L6.047 2.92C6.08614 2.9676 6.09258 3.026 6.06416 3.0776C6.03681 3.1276 6.04164 3.1844 6.0781 3.2312L6.10062 3.2604C6.1226 3.2888 6.13333 3.3208 6.13172 3.3532C6.10759 3.8816 5.88936 8.4876 5.50921 10.2292C5.07918 12.2016 3.35373 15.5256 3.08778 16.1196C2.82237 16.714 1.57949 19.3364 1.26743 21.1C0.942495 22.9344 0.973594 29.6856 1.05188 30.7112C1.10979 31.468 1.05563 34.7632 1.15322 35.6412C1.20094 36.0684 1.45455 36.3616 1.57252 36.4588C2.15535 36.938 7.93492 37 7.93492 37C7.93492 37 13.8448 36.938 14.4271 36.4592C14.545 36.3624 14.7987 36.0688 14.8464 35.6416C14.9445 34.7636 14.8898 31.4684 14.9477 30.7116C15.0265 29.686 15.0576 22.9348 14.7327 21.1004Z"
        fill="white"
        stroke="#393939"
        strokeWidth="0.75"
      />
      <path
        d="M4.88989 30.772C4.88989 30.892 4.98589 31 5.11789 31H5.84989C5.96989 31 6.06589 30.892 6.06589 30.772V23.068C6.06589 22.948 5.96989 22.84 5.84989 22.84H5.26189L3.55789 24.388C3.50989 24.436 3.48589 24.532 3.52189 24.628L3.66589 25.048C3.70189 25.156 3.82189 25.18 3.92989 25.132L4.88989 24.676V30.772ZM7.08317 30.772C7.08317 30.892 7.17917 31 7.31117 31H11.9552C12.0752 31 12.1832 30.892 12.1832 30.772V30.196C12.1832 30.076 12.0752 29.968 11.9552 29.968H8.89517C9.60317 29.164 10.4432 28.18 11.0792 27.412C11.7272 26.632 12.2312 25.996 12.2312 25.072C12.2312 23.812 11.3192 22.72 9.60317 22.72C8.17517 22.72 7.27517 23.896 7.27517 23.896C7.19117 23.992 7.21517 24.136 7.28717 24.208L7.69517 24.616C7.79117 24.712 7.92317 24.712 8.01917 24.616C8.34317 24.268 8.88317 23.812 9.54317 23.812C10.5992 23.812 11.0192 24.508 11.0192 25.12C11.0192 25.6 10.7072 26.2 10.1072 26.872C9.21917 27.928 7.97117 29.452 7.08317 30.46V30.772Z"
        fill="#393939"
      />
    </svg>
  );
}

export default BottleGray12;
