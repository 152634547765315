import React from 'react';

const OHCheckCircleIcon = (props: any) => {
  const { fill, ...rest } = props;
  return (
    <svg
      width="13"
      height="13"
      viewBox="0 0 13 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M6.5 0C2.912 0 0 2.912 0 6.5C0 10.088 2.912 13 6.5 13C10.088 13 13 10.088 13 6.5C13 2.912 10.088 0 6.5 0Z"
        fill="#373634"
      />
      <path
        d="M5.20001 9.75L1.95001 6.631L2.86651 5.75144L5.20001 7.98464L10.1335 3.25L11.05 4.1358L5.20001 9.75Z"
        fill="white"
      />
    </svg>
  );
};

export default OHCheckCircleIcon;
