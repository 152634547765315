import * as React from 'react';
const SvgComponent = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={108}
    height={93}
    fill="none"
    {...props}
  >
    <path
      fill="#9F7058"
      d="M0 4a4 4 0 0 1 4-4h104l-7.512 5.88a101.067 101.067 0 0 0-27.347 32.913 101.065 101.065 0 0 1-58.588 49.508L0 93V4Z"
    />
  </svg>
);
export default SvgComponent;
