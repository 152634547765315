import * as React from 'react';

const InactiveCheckCircle = (props: any) => {
  return (
    <svg
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="check">
        <g clip-path="url(#clip0_5426_915)">
          <rect width="19.9998" height="20" rx="9.99988" fill="white" />
          <path
            id="Vector"
            d="M15 1.33989C16.5083 2.21075 17.7629 3.46042 18.6398 4.96519C19.5167 6.46997 19.9854 8.17766 19.9994 9.91923C20.0135 11.6608 19.5725 13.3758 18.72 14.8946C17.8676 16.4133 16.6332 17.6831 15.1392 18.5782C13.6452 19.4733 11.9434 19.9627 10.2021 19.998C8.46083 20.0332 6.74055 19.6131 5.21155 18.7791C3.68256 17.9452 2.39787 16.7264 1.48467 15.2434C0.571462 13.7604 0.0614093 12.0646 0.00500011 10.3239L0 9.99989L0.00500011 9.67589C0.0610032 7.94888 0.563548 6.26585 1.46364 4.79089C2.36373 3.31592 3.63065 2.09934 5.14089 1.25977C6.65113 0.420205 8.35315 -0.0137108 10.081 0.000330246C11.8089 0.0143713 13.5036 0.47589 15 1.33989ZM13.707 7.29289C13.5348 7.12072 13.3057 7.01729 13.0627 7.002C12.8197 6.98672 12.5794 7.06064 12.387 7.20989L12.293 7.29289L9 10.5849L7.707 9.29289L7.613 9.20989C7.42058 9.06075 7.18037 8.98692 6.9374 9.00225C6.69444 9.01757 6.46541 9.12101 6.29326 9.29315C6.12112 9.4653 6.01768 9.69433 6.00235 9.9373C5.98702 10.1803 6.06086 10.4205 6.21 10.6129L6.293 10.7069L8.293 12.7069L8.387 12.7899C8.56237 12.926 8.77803 12.9998 9 12.9998C9.22197 12.9998 9.43763 12.926 9.613 12.7899L9.707 12.7069L13.707 8.70689L13.79 8.61289C13.9393 8.42049 14.0132 8.18024 13.9979 7.93721C13.9826 7.69419 13.8792 7.46509 13.707 7.29289Z"
            fill="#EDE0BF"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_5426_915">
          <rect width="19.9998" height="20" rx="9.99988" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default InactiveCheckCircle;
