import React from 'react';

const YouTube = (props: any) => {
  const { fill, ...rest } = props;
  return (
    <svg
      width="40"
      height="41"
      viewBox="0 0 40 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <rect y="0.464844" width="40" height="40" rx="20" fill="white" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.81 17.2691C29.81 17.2691 29.6242 15.9955 29.0547 15.4346C28.3322 14.6982 27.5223 14.6947 27.1512 14.652C24.4924 14.4648 20.5042 14.4648 20.5042 14.4648H20.4958C20.4958 14.4648 16.5076 14.4648 13.8488 14.652C13.4771 14.6947 12.6678 14.6982 11.9447 15.4346C11.3753 15.9955 11.19 17.2691 11.19 17.2691C11.19 17.2691 11 18.7651 11 20.2605V21.6629C11 23.1588 11.19 24.6543 11.19 24.6543C11.19 24.6543 11.3753 25.9279 11.9447 26.4887C12.6678 27.2251 13.6172 27.202 14.04 27.2789C15.56 27.4209 20.5 27.4648 20.5 27.4648C20.5 27.4648 24.4924 27.4591 27.1512 27.2719C27.5223 27.2286 28.3322 27.2251 29.0547 26.4887C29.6242 25.9279 29.81 24.6543 29.81 24.6543C29.81 24.6543 30 23.1588 30 21.6629V20.2605C30 18.7651 29.81 17.2691 29.81 17.2691ZM18.1256 23.2168L18.125 18.0103L23.2359 20.6225L18.1256 23.2168Z"
        fill="#373634"
      />
    </svg>
  );
};

export default YouTube;
