import React from 'react';

const TypographySVG = (props: any) => {
  const { fill, stroke, ...rest } = props;
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <g clip-path="url(#clip0_1021_31585)">
        <path
          d="M4.5 20H7.5"
          stroke={stroke || '#88898A'}
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M14.5 20H21.5"
          stroke={stroke || '#88898A'}
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M7.3999 15H14.2999"
          stroke={stroke || '#88898A'}
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M10.7002 6.30005L16.5002 20"
          stroke={stroke || '#88898A'}
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M5.5 20L11.5 4H13.5L20.5 20"
          stroke={stroke || '#88898A'}
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1021_31585">
          <rect
            width="24"
            height="24"
            fill={fill || 'white'}
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
export default TypographySVG;
