import React from 'react';

type Props = {
  /** Unused */
  fill?: string;

  /** Will be applied to inner `path` elements */
  pathProps?: {
    /** Color of the lock icon; defaults to #373634 */
    stroke?: string;
    [key: string]: any;
  };

  [key: string]: any;
};

const LockOpen = (props: Props) => {
  const {
    fill,
    pathProps: { stroke = '#373634', ...pathRest } = {},
    ...rest
  } = props;

  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M12.6667 7.33331H3.33333C2.59695 7.33331 2 7.93027 2 8.66665V13.3333C2 14.0697 2.59695 14.6666 3.33333 14.6666H12.6667C13.403 14.6666 14 14.0697 14 13.3333V8.66665C14 7.93027 13.403 7.33331 12.6667 7.33331Z"
        stroke={stroke}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
        {...pathRest}
      />
      <path
        d="M4.66669 7.33331V4.66665C4.66586 3.84001 4.97221 3.04256 5.52627 2.42909C6.08033 1.81563 6.84258 1.42992 7.66502 1.34684C8.48747 1.26376 9.31145 1.48925 9.977 1.97952C10.6426 2.4698 11.1022 3.18988 11.2667 3.99998"
        stroke={stroke}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
        {...pathRest}
      />
    </svg>
  );
};

export default LockOpen;
