import React from 'react';

const HeartOutline = (props: any) => {
  const { ...rest } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="21"
      fill="none"
      viewBox="0 0 20 21"
      {...rest}
    >
      <path
        stroke="#373634"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M17.367 4.342a4.583 4.583 0 00-6.484 0L10 5.225l-.883-.883a4.584 4.584 0 00-6.484 6.483l.884.883L10 18.192l6.483-6.484.884-.883a4.58 4.58 0 000-6.483z"
      ></path>
    </svg>
  );
};

export default HeartOutline;
