import * as React from 'react';

type Props = {
  /** Unused */
  fill?: string;

  /** Will be applied to inner `path` elements */
  pathProps?: {
    /** Color of the lock icon; defaults to #373634 */
    stroke?: string;
    [key: string]: any;
  };

  [key: string]: any;
};

const LockClosedAddToCart = (props: Props) => {
  const {
    fill,
    pathProps: { stroke = '#373634', ...pathRest } = {},
    ...rest
  } = props;

  return (
    <svg
      width={15}
      height={17}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.243 2.342a2.876 2.876 0 0 1 4.91 2.034v2.15H4.401v-2.15c0-.763.303-1.494.842-2.034ZM2.901 6.527v-2.15a4.376 4.376 0 0 1 8.752 0v2.15h.7a2.2 2.2 0 0 1 2.2 2.2v5.077a2.2 2.2 0 0 1-2.2 2.2H2.2a2.2 2.2 0 0 1-2.2-2.2V8.727a2.2 2.2 0 0 1 2.2-2.2h.7Zm8.002 1.5H2.201a.7.7 0 0 0-.701.7v5.077a.7.7 0 0 0 .7.7h10.153a.7.7 0 0 0 .7-.7V8.727a.7.7 0 0 0-.7-.7h-1.45Z"
        fill={stroke}
        {...pathRest}
      />
    </svg>
  );
};

export default LockClosedAddToCart;
