import React from 'react';

const Chat = (props: any) => {
  const { fill, ...rest } = props;
  return (
    <svg
      width="14"
      height="15"
      viewBox="0 0 14 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M2.99998 8.85004H8.21665V7.85004H2.99998V8.85004ZM2.99998 6.68337H11V5.68337H2.99998V6.68337ZM2.99998 4.51671H11V3.51671H2.99998V4.51671ZM0.333313 14.1667V1.83337C0.333313 1.57782 0.433313 1.34726 0.633313 1.14171C0.833313 0.936152 1.06665 0.833374 1.33331 0.833374H12.6666C12.9222 0.833374 13.1528 0.936152 13.3583 1.14171C13.5639 1.34726 13.6666 1.57782 13.6666 1.83337V10.5C13.6666 10.7556 13.5639 10.9862 13.3583 11.1917C13.1528 11.3973 12.9222 11.5 12.6666 11.5H2.99998L0.333313 14.1667ZM1.33331 11.75L2.58331 10.5H12.6666V1.83337H1.33331V11.75ZM1.33331 1.83337V10.5V11.75V1.83337Z"
        fill={fill}
      />
    </svg>
  );
};

export default Chat;
