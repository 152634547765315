import { withStyles, Theme } from '@material-ui/core/styles';
import theme from '../theme';

function withDefaults(
  stylesOrClass: any,
  options?: {
    // name the component in JSS
    name?: string;
    // replace default theme
    deafultTheme?: Theme;
    // expose theme as a prop to component
    withTheme?: boolean;
  },
) {
  return withStyles(stylesOrClass, {
    defaultTheme: theme,
    ...options,
  });
}

export default withDefaults;
