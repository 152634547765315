import React, { ReactNode, Children, FunctionComponent } from 'react';
import { useState, createContext, useContext } from 'react';

interface StepperContextTypes {
  handleNext: () => void;
  handlePrev: () => void;
  hasNext: boolean;
  hasPrev: boolean;
  activeStep: number;
}

const StepperContext = createContext<StepperContextTypes | null>(null);

interface StepperProviderProps {
  children: ReactNode;
  Controls: FunctionComponent;
}

export const StepperProvider = ({
  children,
  Controls,
}: StepperProviderProps) => {
  // cast the children to an array so we can pull selectively from it
  // based on the activeStep state mapped to the generated array index
  const childrenArray = Children.toArray(children);
  // count the total number of available Step components
  const stepsCount = Children.count(children);
  // state management for the active stepper position
  const [activeStep, setActiveStep] = useState(0);
  // state management for tracking if stepper has next or previous
  const [hasNext, setHasNext] = useState(true);
  const [hasPrev, setHasPrev] = useState(false);
  // function to increment the active stepper position if the active
  // stepper position is less than the total number of steps
  const handleNext = () => {
    if (!hasNext) return;
    if (activeStep + 1 === stepsCount - 1) {
      setHasNext(false);
    }
    setHasPrev(true);
    setActiveStep(activeStep + 1);
  };
  // function to decrement the active stepper position
  // if the active stepper position is 0, it will not decrement
  const handlePrev = () => {
    if (!hasPrev) return;
    if (activeStep - 1 === 0) {
      setHasPrev(false);
    }
    setHasNext(true);
    setActiveStep(activeStep - 1);
  };

  const initialValue = {
    handleNext,
    handlePrev,
    hasNext,
    hasPrev,
    activeStep,
    stepsCount,
  };

  return (
    <StepperContext.Provider value={initialValue}>
      {childrenArray[activeStep]}
      {<Controls />}
    </StepperContext.Provider>
  );
};

export const useStepper = () => useContext(StepperContext);
