import React from 'react';

const ReachOutsCopyButton = (props: any) => {
  const { fill, ...rest } = props;
  return (
    <svg
      width="60"
      height="61"
      viewBox="0 0 60 61"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <rect
        y="0.5"
        width="60"
        height="60"
        rx="12"
        fill="url(#paint0_linear_2118_52911)"
      />
      <path
        d="M43.3333 25.5H28.3333C26.4924 25.5 25 26.9924 25 28.8333V43.8333C25 45.6743 26.4924 47.1667 28.3333 47.1667H43.3333C45.1743 47.1667 46.6667 45.6743 46.6667 43.8333V28.8333C46.6667 26.9924 45.1743 25.5 43.3333 25.5Z"
        stroke="white"
        stroke-width="2.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M18.3333 35.5002H16.6667C15.7826 35.5002 14.9348 35.149 14.3096 34.5239C13.6845 33.8987 13.3333 33.0509 13.3333 32.1668V17.1668C13.3333 16.2828 13.6845 15.4349 14.3096 14.8098C14.9348 14.1847 15.7826 13.8335 16.6667 13.8335H31.6667C32.5507 13.8335 33.3986 14.1847 34.0237 14.8098C34.6488 15.4349 35 16.2828 35 17.1668V18.8335"
        stroke="white"
        stroke-width="2.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2118_52911"
          x1="30"
          y1="0.5"
          x2="30"
          y2="60.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#3490AD" />
          <stop offset="1" stop-color="#335E65" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default ReachOutsCopyButton;
