import React from 'react';

type Props = {
  /** Unused */
  fill?: string;

  /** Will be applied to inner `path` elements */
  pathProps?: {
    /** Color of the lock icon; defaults to #373634 */
    stroke?: string;
    [key: string]: any;
  };

  [key: string]: any;
};

const LockClosed = (props: Props) => {
  const {
    fill,
    pathProps: { stroke = '#373634', ...pathRest } = {},
    ...rest
  } = props;

  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M12.7167 7.33331H3.38338C2.647 7.33331 2.05005 7.93027 2.05005 8.66665V13.3333C2.05005 14.0697 2.647 14.6666 3.38338 14.6666H12.7167C13.4531 14.6666 14.05 14.0697 14.05 13.3333V8.66665C14.05 7.93027 13.4531 7.33331 12.7167 7.33331Z"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        {...pathRest}
      />
      <path
        d="M4.71667 7.33331V4.66665C4.71667 3.78259 5.06786 2.93475 5.69299 2.30962C6.31811 1.6845 7.16595 1.33331 8.05001 1.33331C8.93406 1.33331 9.78191 1.6845 10.407 2.30962C11.0322 2.93475 11.3833 3.78259 11.3833 4.66665V7.33331"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        {...pathRest}
      />
    </svg>
  );
};

export default LockClosed;
