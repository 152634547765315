import React from 'react';

type Props = {
  /** Unused */
  fill?: string;

  /** Will be applied to inner `path` elements */
  pathProps?: {
    /** Color of the lock icon; defaults to #373634 */
    stroke?: string;
    [key: string]: any;
  };

  [key: string]: any;
};

const LockCircle = (props: Props) => {
  const {
    fill,
    pathProps: { stroke = '#373634', ...pathRest } = {},
    ...rest
  } = props;

  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <circle cx="12.666" cy="12" r="12" fill="#C2C6C9" />
      <path
        d="M17.3327 11.3333H7.99935C7.26297 11.3333 6.66602 11.9302 6.66602 12.6666V17.3333C6.66602 18.0696 7.26297 18.6666 7.99935 18.6666H17.3327C18.0691 18.6666 18.666 18.0696 18.666 17.3333V12.6666C18.666 11.9302 18.0691 11.3333 17.3327 11.3333Z"
        fill="white"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        {...pathRest}
      />
      <path
        d="M9.33203 11.3333V8.66659C9.33203 7.78253 9.68322 6.93468 10.3083 6.30956C10.9335 5.68444 11.7813 5.33325 12.6654 5.33325C13.5494 5.33325 14.3973 5.68444 15.0224 6.30956C15.6475 6.93468 15.9987 7.78253 15.9987 8.66659V11.3333"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        {...pathRest}
      />
    </svg>
  );
};

export default LockCircle;
