import React from 'react';
import Popover, { PopoverProps } from '@mui/material/Popover';
import clsx from 'clsx';
import withDefaults from '../withDefaults';

const styles = {
  root: {},
  paper: {},
};

const OHWPopover = ({
  classes,
  className: classNamesProp,
  children,
  paperClasses,
  PaperProps: paperProps,
  ...rest
}: PopoverProps) => {
  const className = clsx(classes?.root, classNamesProp);
  const paperClassName = paperClasses
    ? clsx(paperClasses.root, paperClasses.paper)
    : {};
  const paperPropsWithClassName = { className: paperClassName, ...paperProps };
  return (
    <Popover
      className={className}
      PaperProps={paperPropsWithClassName}
      {...rest}
    >
      {children}
    </Popover>
  );
};

export default withDefaults(styles, { name: 'OH-Popover' })(OHWPopover);
