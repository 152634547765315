import React from 'react';

function BlueStarOn(props: any) {
  return (
    <svg
      width="15"
      height="16"
      viewBox="0 0 15 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.5 12.0997L11.6715 14.75L10.5645 9.755L14.25 6.39421L9.39675 5.96079L7.5 1.25L5.60325 5.96079L0.75 6.39421L4.4355 9.755L3.3285 14.75L7.5 12.0997Z"
        fill="#3C5D62"
      />
    </svg>
  );
}

export default BlueStarOn;
