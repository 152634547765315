import React from 'react';

function DownArrowWildthingsOrange() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="8"
      fill="none"
      viewBox="0 0 16 8"
    >
      <path
        fill="#C75E30"
        d="M14.932.932a.375.375 0 00-.53-.53l.53.53zM8 7.333l-.265.266a.375.375 0 00.53 0L8 7.333zM1.598.402a.375.375 0 10-.53.53l.53-.53zm12.803 0L7.735 7.068l.53.53L14.932.933l-.53-.53zM8.265 7.068L1.598.402l-.53.53 6.667 6.667.53-.53z"
      />
    </svg>
  );
}

export default DownArrowWildthingsOrange;
