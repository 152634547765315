import React from 'react';

const PlusWhite = (props: any) => {
  const { fill, ...rest } = props;
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19 21V25H21V21H25V19H21V15H19V19H15V21H19Z"
        fill={fill || '#ffffff'}
      />
    </svg>
  );
};

export default PlusWhite;
