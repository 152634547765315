import React from 'react';

const Trash = () => {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.2 3.7009H11.2V2.36499C11.1609 1.29875 10.2664 0.465136 9.2 0.501123H6.8C5.73357 0.465136 4.83908 1.29875 4.8 2.36499V3.7009H0.8C0.358172 3.7009 0 4.05905 0 4.50084C0 4.94264 0.358172 5.30079 0.8 5.30079H1.6V14.1002C1.6 15.4256 2.67452 16.5 4 16.5H12C13.3255 16.5 14.4 15.4256 14.4 14.1002V5.30079H15.2C15.6418 5.30079 16 4.94264 16 4.50084C16 4.05905 15.6418 3.7009 15.2 3.7009ZM6.4 2.36499C6.4 2.237 6.568 2.10101 6.8 2.10101H9.2C9.432 2.10101 9.6 2.237 9.6 2.36499V3.7009H6.4V2.36499ZM12 14.9001C12.4418 14.9001 12.8 14.542 12.8 14.1002V5.30079H3.2V14.1002C3.2 14.542 3.55817 14.9001 4 14.9001H12ZM5.6 12.5003C6.04183 12.5003 6.4 12.1421 6.4 11.7003V8.50056C6.4 8.05876 6.04183 7.70062 5.6 7.70062C5.15817 7.70062 4.8 8.05876 4.8 8.50056V11.7003C4.8 12.1421 5.15817 12.5003 5.6 12.5003ZM11.2 11.7003C11.2 12.1421 10.8418 12.5003 10.4 12.5003C9.95817 12.5003 9.6 12.1421 9.6 11.7003V8.50056C9.6 8.05876 9.95817 7.70062 10.4 7.70062C10.8418 7.70062 11.2 8.05876 11.2 8.50056V11.7003Z"
        fill="#373634"
      />
    </svg>
  );
};

export default Trash;
