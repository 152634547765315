import * as React from "react"

const SvgComponent = (props: any) => (
    <svg
        width={40}
        height={40}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <rect width={40} height={40} rx={20} fill="#E9EAE9" />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M26.773 15.047a.774.774 0 0 1 0 1.097l-1.424 1.423-2.916-2.916 1.423-1.424a.774.774 0 0 1 1.097 0l1.82 1.82ZM13 27v-2.916l8.601-8.601 2.916 2.916-8.6 8.601H13Z"
            fill="#373634"
        />
    </svg>
)

export default SvgComponent
