import React from 'react';
import { styled } from '@mui/material/styles';
import CircularProgress, {
  CircularProgressProps,
} from '@mui/material/CircularProgress';
import theme from '../theme';

const StyledCircularProgress = styled((props: CircularProgressProps) => (
  <CircularProgress {...props} />
))(() => ({
  color: theme.palette.primary.main,
}));

type LoaderDarkProps = {
  size?: number;
};

export default function LoaderDark(props: LoaderDarkProps) {
  return <StyledCircularProgress size={props?.size || 24} {...props} />;
}
