/**
 * Given an unformatted phone number, return a formatted phone number.
 * @param rawNumber
 */
const PhoneNumber = (rawNumber: string) => {
  if (!rawNumber || (rawNumber.length !== 10 && rawNumber.length !== 11)) {
    return null;
  }
  let i = 0;
  const number = rawNumber.replace(/[- )(+]/g, '');
  const pattern = number.length === 10 ? '(###) ###-####' : '# (###) ###-####';
  return pattern.replace(/#/g, (_) => number[i++]);
};

export default PhoneNumber;
