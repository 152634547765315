import React from 'react';

function BottleBlue12() {
  return (
    <svg
      width="14"
      height="36"
      viewBox="0 0 14 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.7327 20.1004C13.4206 18.3364 12.1778 15.714 11.9123 15.12C11.6469 14.5256 9.92148 11.202 9.49093 9.2296C9.1113 7.488 8.89254 2.882 8.86841 2.3536C8.8668 2.3208 8.87753 2.2892 8.89951 2.2608L8.92203 2.2316C8.95795 2.1848 8.96332 2.128 8.93597 2.078C8.90755 2.0264 8.91399 1.968 8.95313 1.9204L9.14133 1.6912C9.17029 1.656 9.18154 1.614 9.17297 1.5732L9.09736 1.2068C9.09093 1.176 9.07377 1.1468 9.04696 1.1228L8.96922 1.0516C8.92793 1.0136 8.9097 0.9652 8.91881 0.9168C8.93919 0.8044 8.96546 0.584 8.90273 0.3968C8.82069 0.152 8.73865 0.134 8.51614 0.0280001C8.47914 0.00920007 8.43517 0 8.39067 0H6.94832H5.60946C5.56496 0 5.52153 0.00919994 5.484 0.0271999C5.26148 0.1336 5.17944 0.1516 5.0974 0.396C5.03467 0.5832 5.06094 0.8036 5.08132 0.916C5.09043 0.9644 5.0722 1.0132 5.03092 1.0508L4.95317 1.122C4.9269 1.1464 4.9092 1.1752 4.90277 1.206L4.82716 1.5724C4.81859 1.6132 4.82985 1.6552 4.8588 1.6904L5.047 1.92C5.08614 1.9676 5.09258 2.026 5.06416 2.0776C5.03681 2.1276 5.04164 2.1844 5.0781 2.2312L5.10062 2.2604C5.1226 2.2888 5.13333 2.3208 5.13172 2.3532C5.10759 2.8816 4.88936 7.4876 4.50921 9.2292C4.07918 11.2016 2.35373 14.5256 2.08778 15.1196C1.82237 15.714 0.579486 18.3364 0.267425 20.1C-0.0575046 21.9344 -0.026406 28.6856 0.0518774 29.7112C0.109786 30.468 0.0556303 33.7632 0.153216 34.6412C0.200937 35.0684 0.454555 35.3616 0.572516 35.4588C1.15535 35.938 6.93492 36 6.93492 36C6.93492 36 12.8448 35.938 13.4271 35.4592C13.545 35.3624 13.7987 35.0688 13.8464 34.6416C13.9445 33.7636 13.8898 30.4684 13.9477 29.7116C14.0265 28.686 14.0576 21.9348 13.7327 20.1004Z"
        fill="#46789E"
      />
      <path
        d="M3.88989 29.772C3.88989 29.892 3.98589 30 4.11789 30H4.84989C4.96989 30 5.06589 29.892 5.06589 29.772V22.068C5.06589 21.948 4.96989 21.84 4.84989 21.84H4.26189L2.55789 23.388C2.50989 23.436 2.48589 23.532 2.52189 23.628L2.66589 24.048C2.70189 24.156 2.82189 24.18 2.92989 24.132L3.88989 23.676V29.772ZM6.08317 29.772C6.08317 29.892 6.17917 30 6.31117 30H10.9552C11.0752 30 11.1832 29.892 11.1832 29.772V29.196C11.1832 29.076 11.0752 28.968 10.9552 28.968H7.89517C8.60317 28.164 9.44317 27.18 10.0792 26.412C10.7272 25.632 11.2312 24.996 11.2312 24.072C11.2312 22.812 10.3192 21.72 8.60317 21.72C7.17517 21.72 6.27517 22.896 6.27517 22.896C6.19117 22.992 6.21517 23.136 6.28717 23.208L6.69517 23.616C6.79117 23.712 6.92317 23.712 7.01917 23.616C7.34317 23.268 7.88317 22.812 8.54317 22.812C9.59917 22.812 10.0192 23.508 10.0192 24.12C10.0192 24.6 9.70717 25.2 9.10717 25.872C8.21917 26.928 6.97117 28.452 6.08317 29.46V29.772Z"
        fill="white"
      />
    </svg>
  );
}

export default BottleBlue12;
