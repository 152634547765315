import React from 'react';

const SvgDollar = (props: any) => (
  <svg
    width="6"
    height="12"
    viewBox="0 0 6 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M0.552 9.184C0.768 9.388 1.536 10.012 2.832 10.108V11.356C2.832 11.56 3.06 11.572 3.204 11.572C3.336 11.572 3.444 11.488 3.444 11.356V10.108C5.052 10.012 5.94 9.052 5.94 7.744C5.94 6.172 4.512 5.452 3.468 5.02C3.468 4.3 3.468 3.58 3.468 2.86C4.056 3.04 4.68 3.472 4.764 3.508C5.04 3.7 5.388 3.04 5.628 2.68C5.712 2.548 5.676 2.356 5.556 2.284C5.34 2.128 4.536 1.588 3.468 1.504V1.108C3.468 0.976 3.36 0.879999 3.24 0.879999H3.084C2.964 0.879999 2.856 0.976 2.856 1.108V1.492C1.212 1.624 0.456 2.632 0.456 3.772C0.456 5.356 1.728 5.992 2.844 6.436C2.844 7.216 2.832 7.96 2.832 8.74C2.112 8.56 1.392 8.032 1.344 7.996C1.008 7.744 0.72 8.452 0.492 8.824C0.372 9.004 0.444 9.076 0.552 9.184ZM2.82 4.756C2.208 4.432 1.92 4.084 1.92 3.604C1.92 3.244 2.22 2.896 2.82 2.824V4.756ZM3.468 8.812V6.712C4.068 7.06 4.368 7.444 4.368 7.912C4.368 8.38 3.984 8.728 3.468 8.812Z"
      fill="#88898A"
    />
  </svg>
);

export default SvgDollar;
