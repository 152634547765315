import React from 'react';

const ArrowUpSmall = (props: any) => {
  const { fill, ...rest } = props;
  return (
    <svg
      width="12"
      height="8"
      viewBox="0 0 12 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.7255 7.86266C10.8056 7.95428 10.8978 8 11.002 8C11.1062 8 11.1985 7.95428 11.2785 7.86266L11.8797 7.1753C11.9599 7.08368 12 6.97824 12 6.85929C12 6.74017 11.9599 6.63472 11.8797 6.54311L6.27657 0.137338C6.19643 0.045723 6.10419 0 6 0C5.89581 0 5.80357 0.045723 5.72343 0.137338L0.12028 6.54294C0.0399952 6.63455 0 6.74 0 6.85912C0 6.97807 0.0399952 7.08352 0.12028 7.17513L0.72124 7.86249C0.801378 7.95411 0.893617 7.99983 0.997958 7.99983C1.102 7.99983 1.19424 7.95411 1.27438 7.86249L6 2.46027L10.7255 7.86266Z"
        fill="#373634"
      />
    </svg>
  );
};

export default ArrowUpSmall;
