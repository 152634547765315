import React, { useState } from 'react';

import { styled } from '@mui/material/styles';
import { AccordionProps } from '@mui/material/Accordion';
import { AccordionDetailsProps } from '@mui/material/AccordionDetails';
import { AccordionSummaryProps } from '@mui/material/AccordionSummary';

import Accordion from './Accordion';
import AccordionDetails from './AccordionDetails';
import AccordionSummary from './AccordionSummary';

import theme from '../theme';

const StyledAccordionSummary = styled(
  (props: AccordionSummaryProps & { isExpanded: boolean }) => (
    <AccordionSummary {...props} />
  ),
)(({}) => ({
  margin: '0',
  padding: '0',
  height: 59,
  flexDirection: 'row-reverse',
  backgroundColor: theme.palette.lightYellowBeige.main,
  color: theme.palette.mud.main,
  '& p': {
    [theme.breakpoints.up('md')]: {
      fontSize: '18px',
      lineHeight: '27px',
    },
  },

  '& .MuiAccordionSummary-expandIconWrapper': {
    color: theme.palette.mud.main,
    marginLeft: '12px',
  },
}));

const StyledAccordionDetails = styled((props: AccordionDetailsProps) => (
  <AccordionDetails {...props} />
))(() => ({
  padding: '16px 0px 0 0px',
  '& p': {
    [theme.breakpoints.up('sm')]: {
      fontSize: '18px',
      lineHeight: '27px',
    },
  },
  '& p:last-of-type': { marginBottom: '0' },
  '& ul': {
    margin: '-24px 0 0 -12px',
  },
  '& ol': {
    margin: '0 0 0 -18px',
    fontWeight: 300,
  },
  '& a': {
    fontWeight: 500,
  },
}));

export const MobileAccordion = (props: AccordionProps) => {
  const { children, ...accordionProps } = props;
  const [isExpanded, setExpanded] = useState(false);

  const handleExpandChange = () => {
    setExpanded(!isExpanded);
  };

  const label = isExpanded ? 'Hide order details' : 'Show order details';

  return (
    <Accordion
      expanded={isExpanded}
      onChange={handleExpandChange}
      sx={{
        m: '0',
        boxShadow: 'none',
        borderBottom: 'none',
        '&.Mui-expanded': {
          m: '0',
        },
      }}
      {...accordionProps}
    >
      <StyledAccordionSummary label={label} expanded={isExpanded} />
      <StyledAccordionDetails>{children}</StyledAccordionDetails>
    </Accordion>
  );
};

export default MobileAccordion;
