import React from 'react';

const ShoppingBag = (props: any) => {
  const { fill, ...rest } = props;
  return (
    <svg
      width="35"
      height="33"
      viewBox="0 0 35 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.7947 10.5436C11.8228 10.3465 11.9916 10.2001 12.1907 10.2001H16.063C16.2551 10.2001 16.42 10.3366 16.456 10.5253L17.6115 16.5916H10.9307L11.7947 10.5436ZM9.54705 17.7916L9.3329 19.2907C8.72534 19.4892 8.28638 20.0605 8.28638 20.7343C8.28638 21.5728 8.96616 22.2526 9.8047 22.2526C10.6432 22.2526 11.323 21.5728 11.323 20.7343C11.323 20.1582 11.0022 19.6571 10.5295 19.3998L10.7592 17.7916H17.84L18.1506 19.4219C17.6994 19.6851 17.3963 20.1743 17.3963 20.7343C17.3963 21.5728 18.076 22.2526 18.9146 22.2526C19.7531 22.2526 20.4329 21.5728 20.4329 20.7343C20.4329 20.0451 19.9738 19.4632 19.3447 19.2778L19.0616 17.7916H25.1195C25.3925 17.7916 25.5853 18.0591 25.499 18.3181L22.1168 28.4647C22.0624 28.628 21.9095 28.7382 21.7373 28.7382H6.98342C6.81125 28.7382 6.65839 28.628 6.60395 28.4647L3.22175 18.3181C3.13542 18.0591 3.3282 17.7916 3.60123 17.7916H9.54705ZM9.71848 16.5916L10.6067 10.3738C10.7193 9.58561 11.3944 9.00012 12.1907 9.00012H16.063C16.8312 9.00012 17.491 9.5461 17.6348 10.3007L18.833 16.5916H25.1195C26.2116 16.5916 26.9828 17.6615 26.6374 18.6976L23.2552 28.8442C23.0374 29.4975 22.426 29.9382 21.7373 29.9382H6.98342C6.29473 29.9382 5.68331 29.4975 5.46553 28.8442L2.08333 18.6976C1.73798 17.6615 2.50913 16.5916 3.60123 16.5916H9.71848Z"
        fill="#373634"
      />
      <circle cx="25" cy="10" r="10" fill="#9F7058" />
    </svg>
  );
};

export default ShoppingBag;
