import React from 'react';

function BottleBlack4() {
  return (
    <svg
      width="16"
      height="38"
      viewBox="0 0 16 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.7327 21.1004C14.4206 19.3364 13.1778 16.714 12.9123 16.12C12.6469 15.5256 10.9215 12.202 10.4909 10.2296C10.1113 8.488 9.89254 3.882 9.86841 3.3536C9.8668 3.3208 9.87753 3.2892 9.89951 3.2608L9.92203 3.2316C9.95795 3.1848 9.96332 3.128 9.93597 3.078C9.90755 3.0264 9.91399 2.968 9.95313 2.9204L10.1413 2.6912C10.1703 2.656 10.1815 2.614 10.173 2.5732L10.0974 2.2068C10.0909 2.176 10.0738 2.1468 10.047 2.1228L9.96922 2.0516C9.92793 2.0136 9.9097 1.9652 9.91881 1.9168C9.93919 1.8044 9.96546 1.584 9.90273 1.3968C9.82069 1.152 9.73865 1.134 9.51614 1.028C9.47914 1.0092 9.43517 1 9.39067 1H7.94832H6.60946C6.56496 1 6.52153 1.0092 6.484 1.0272C6.26148 1.1336 6.17944 1.1516 6.0974 1.396C6.03467 1.5832 6.06094 1.8036 6.08132 1.916C6.09043 1.9644 6.0722 2.0132 6.03092 2.0508L5.95317 2.122C5.9269 2.1464 5.9092 2.1752 5.90277 2.206L5.82716 2.5724C5.81859 2.6132 5.82985 2.6552 5.8588 2.6904L6.047 2.92C6.08614 2.9676 6.09258 3.026 6.06416 3.0776C6.03681 3.1276 6.04164 3.1844 6.0781 3.2312L6.10062 3.2604C6.1226 3.2888 6.13333 3.3208 6.13172 3.3532C6.10759 3.8816 5.88936 8.4876 5.50921 10.2292C5.07918 12.2016 3.35373 15.5256 3.08778 16.1196C2.82237 16.714 1.57949 19.3364 1.26743 21.1C0.942495 22.9344 0.973594 29.6856 1.05188 30.7112C1.10979 31.468 1.05563 34.7632 1.15322 35.6412C1.20094 36.0684 1.45455 36.3616 1.57252 36.4588C2.15535 36.938 7.93492 37 7.93492 37C7.93492 37 13.8448 36.938 14.4271 36.4592C14.545 36.3624 14.7987 36.0688 14.8464 35.6416C14.9445 34.7636 14.8898 31.4684 14.9477 30.7116C15.0265 29.686 15.0576 22.9348 14.7327 21.1004Z"
        fill="white"
        stroke="#393939"
        strokeWidth="0.75"
      />
      <path
        d="M4.86336 29.212H8.77536V30.772C8.77536 30.892 8.88336 31 9.00336 31H9.71136C9.83136 31 9.93936 30.892 9.93936 30.772L9.92736 29.188H10.8994C11.0314 29.188 11.1274 29.08 11.1274 28.96V28.456C11.1274 28.336 11.0314 28.24 10.8994 28.24H9.92736V22.936C9.92736 22.804 9.81936 22.708 9.69936 22.708H9.25536C9.20736 22.708 9.11136 22.744 9.07536 22.78L4.63536 28.264V28.984C4.63536 29.104 4.73136 29.212 4.86336 29.212ZM5.93136 28.216L8.82336 24.688V28.216H5.93136Z"
        fill="#393939"
      />
    </svg>
  );
}

export default BottleBlack4;
