import React from 'react';
import styled from '@emotion/styled';
import { formatNonProfitAddress } from '@onehope/utils';

import Icons from '/Icons';
import MenuItem from '/MenuItem';
import theme from '/theme';
import Grid from '/Grid';
import SearchImage from './getSearchImage';

const TextOverFlowContainer = styled.span`
  min-width: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

type PrimaryLabelProps = {
  isIRSVerified?: boolean;
};

type SuggestionContainerProps = {
  height: string;
};

const SuggestionContainer = styled.div<SuggestionContainerProps>`
  height: ${({ height }: SuggestionContainerProps) => `${height}px`};
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const PrimaryLabelEvents = styled.div`
  font-family: ${theme.bodyFontFamily};
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.01em;
  font-weight: 400;
  color: ${theme.palette.primary.main};
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: calc(100% - 64px);
  @media all and (min-width: 500px) {
    max-width: calc(100% - 32px);
  }
`;
// @ts-ignore: Type 'CreateStyled<any>' has no call signatures
const Verified = styled(Icons.COCVerified)`
  && {
    margin-left: 8px;
  }
`;

// @ts-ignore: Type 'CreateStyled<any>' has no call signatures
const DatabaseEdit = styled(Icons.COCDatabaseEdit)`
  && {
    margin-left: 8px;
  }
`;

const SecondaryLabelEvents = styled.div`
  font-family: ${theme.bodyFontFamily};
  font-size: 14px;
  line-height: 21px;
  font-weight: 400;
  padding-top: 2px;
  letter-spacing: 0.01em;
  color: ${theme.palette.greyDark.main};
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: calc(100% - 64px);
  @media all and (min-width: 500px) {
    max-width: calc(100% - 16px);
  }
`;

const PrimaryLabel = styled.div<PrimaryLabelProps>`
  font-family: ${theme.bodyFontFamily};
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.01em;
  font-weight: 400;
  color: ${theme.palette.primary.main};
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: ${({ isIRSVerified }: PrimaryLabelProps) =>
    isIRSVerified ? `calc(100% - 94px)` : `calc(100% - 64px)`};
  @media all and (min-width: 500px) {
    max-width: ${({ isIRSVerified }: PrimaryLabelProps) =>
      isIRSVerified ? `calc(100% - 62px)` : `calc(100% - 32px)`};
  }
`;

const SecondaryLabel = styled.div`
  font-family: ${theme.bodyFontFamily};
  font-size: 14px;
  line-height: 21px;
  font-weight: 400;
  padding-top: 2px;
  letter-spacing: 0.01em;
  color: ${theme.palette.greyDark.main};
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: calc(100% - 64px);
  @media all and (min-width: 768px) {
    max-width: calc(100% - 32px);
  }
`;

const Address = styled.div`
  width: 432px;
  font-size: 14px;
  line-height: 21px;
  font-weight: 500;
  letter-spacing: 0.01em;
  color: ${theme.palette.primary.main};
`;

const Padding14 = styled.div`
  padding-bottom: 14px;
`;

// @ts-ignore: Type 'CreateStyled<any>' has no call signatures
const HeaderAndSubMenuItem = styled(MenuItem)`
  && {
    font-family: ${theme.bodyFontFamily};
    height: 64px;
    width: 100%;
    border-radius: 0 !important;
    border-bottom: 1px solid ${theme.palette.greyLight.main};
    border-left: ${(props: any) =>
      props?.selected
        ? `4px solid ${theme.palette.primary.main}`
        : `4px solid ${theme.palette.veryLightGrey.main}`};
    &:hover,
    &.Mui-selected {
      background-color: ${theme.palette.lightOrangeTint.main} !important;
    }
  }
`;
// @ts-ignore: Type 'CreateStyled<any>' has no call signatures
const ImageGrid = styled(Grid)`
  && {
    padding-right: 16px;
  }
`;

// @ts-ignore: Type 'CreateStyled<any>' has no call signatures
const AddressMenuItem = styled(MenuItem)`
  && {
    height: 46px;
    font-family: ${theme.bodyFontFamily};
    border-radius: 0 !important;
    width: 100%;
    border-bottom: 1px solid ${theme.palette.greyLight.main};
    border-left: ${(props: any) =>
      props?.selected
        ? `4px solid ${theme.palette.primary.main}`
        : `4px solid ${theme.palette.veryLightGrey.main}`};
    &:hover,
    &.Mui-selected {
      background-color: ${theme.palette.lightOrangeTint.main} !important;
    }
  }
`;

export interface GetItemPropsOptions {
  index?: number;
  item: any;
  isSelected?: boolean;
  disabled?: boolean;
}

interface SuggestionProps {
  highlightedIndex: number | null;
  urlEventImgUploads?: string;
  index: number;
  itemProps: GetItemPropsOptions;
  selectedItem: string;
  suggestion?: string;
  objSuggestion?: any;
  id: string;
}

export function renderSuggestion(
  suggestionProps: SuggestionProps,
): JSX.Element {
  const {
    suggestion,
    objSuggestion,
    index,
    itemProps,
    highlightedIndex,
    urlEventImgUploads,
    selectedItem,
    id,
  } = suggestionProps;
  const isHighlighted = highlightedIndex === index;
  if (typeof objSuggestion !== 'undefined') {
    if (id === 'nameDropDown') {
      const isSelected =
        (selectedItem || '') === objSuggestion?.node?.primaryLabel;
      return (
        <HeaderAndSubMenuItem
          {...itemProps}
          key={`${objSuggestion?.node?.primaryLabel}${index}`}
          selected={isHighlighted}
          component="div"
          style={{
            fontWeight: isSelected ? 500 : 400,
          }}
        >
          <SuggestionContainer height={'64'}>
            <PrimaryLabel>{objSuggestion?.node?.primaryLabel}</PrimaryLabel>
            <SecondaryLabel>
              {objSuggestion?.node?.secondaryLabel || 'Wine Rep'}
            </SecondaryLabel>
          </SuggestionContainer>
        </HeaderAndSubMenuItem>
      );
    }
    if (id === 'eventDropDown') {
      const isSelected =
        (selectedItem || '') === objSuggestion?.node?.primaryLabel;
      const searchImage =
        objSuggestion?.node?.image && urlEventImgUploads
          ? `${urlEventImgUploads}/${objSuggestion?.node?.image}`
          : null;
      return (
        <HeaderAndSubMenuItem
          {...itemProps}
          key={`${objSuggestion?.node?.primaryLabel}${index}`}
          selected={isHighlighted}
          component="div"
          style={{
            height: 103,
            fontWeight: isSelected ? 500 : 400,
          }}
        >
          <Grid container direction="row" alignItems="center" wrap="nowrap">
            <ImageGrid item>
              {searchImage ? (
                <SearchImage url={searchImage} />
              ) : (
                <Icons.COCHeart />
              )}
            </ImageGrid>
            <Grid item xs>
              <PrimaryLabelEvents>
                {objSuggestion?.node?.primaryLabel}
              </PrimaryLabelEvents>
              <SecondaryLabelEvents>
                Host: {objSuggestion?.node?.secondaryLabel || '—'}
              </SecondaryLabelEvents>
              <SecondaryLabelEvents>
                Nonprofit: {objSuggestion?.node?.quaternaryLabel || '—'}
              </SecondaryLabelEvents>
            </Grid>
          </Grid>
        </HeaderAndSubMenuItem>
      );
    }
    if (id === 'contactNameDropDown') {
      const isSelected =
        (selectedItem || '') === objSuggestion?.node?.primaryLabel;
      return (
        <HeaderAndSubMenuItem
          {...itemProps}
          key={`${objSuggestion?.node?.primaryLabel}${index}`}
          selected={isHighlighted}
          component="div"
          style={{
            fontWeight: isSelected ? 500 : 400,
          }}
        >
          <SuggestionContainer height={'64'}>
            <PrimaryLabel>{objSuggestion?.fullName}</PrimaryLabel>
            <SecondaryLabel>{objSuggestion?.email}</SecondaryLabel>
          </SuggestionContainer>
        </HeaderAndSubMenuItem>
      );
    }
    if (id === 'customNonProfitDropDown') {
      const isSelected = (selectedItem || '') === objSuggestion.nonProfitName;
      const nonProfitAddress = formatNonProfitAddress(
        objSuggestion?.nonProfitAddress,
      );
      return (
        <HeaderAndSubMenuItem
          {...itemProps}
          key={`${objSuggestion?.id}${index}`}
          selected={isHighlighted}
          component="div"
          style={{
            height: 103,
            fontWeight: isSelected ? 500 : 400,
          }}
        >
          <Grid container direction="row" alignItems="center" wrap="nowrap">
            <ImageGrid item>
              <Icons.COCHeartHands />
            </ImageGrid>
            <Grid item xs>
              <Grid container direction="row" alignItems="center">
                <PrimaryLabel isIRSVerified={!!objSuggestion?.isIRSVerified}>
                  {objSuggestion?.nonProfitName}
                </PrimaryLabel>
                {objSuggestion?.isIRSVerified ? <Verified /> : <DatabaseEdit />}
              </Grid>
              <SecondaryLabel>{nonProfitAddress}</SecondaryLabel>
              <SecondaryLabel>
                EIN: {objSuggestion?.nonProfitTaxId}
              </SecondaryLabel>
            </Grid>
          </Grid>
        </HeaderAndSubMenuItem>
      );
    }
    if (id === 'displayAddressDropDown' || id === 'shippingAddressDropDown') {
      const address = `${objSuggestion.addressLineOne}, ${objSuggestion.city}, ${objSuggestion.state}`;
      const isSelected = (selectedItem || '') === address;
      return (
        <AddressMenuItem
          {...itemProps}
          key={`${address}${index}`}
          selected={isHighlighted}
          component="div"
          style={{
            fontWeight: isSelected ? 500 : 400,
          }}
        >
          <Grid container direction="row" alignItems="center">
            <Address>
              <TextOverFlowContainer>{address}</TextOverFlowContainer>
            </Address>
          </Grid>
        </AddressMenuItem>
      );
    }
    if (id === 'eventAddressDropDown') {
      const address = `${objSuggestion.addressLineOne}, ${objSuggestion.city}, ${objSuggestion.state}`;
      const isSelected = (selectedItem || '') === address;
      return (
        <AddressMenuItem
          {...itemProps}
          key={`${address}${index}`}
          selected={isHighlighted}
          component="div"
          style={{
            fontWeight: isSelected ? 500 : 400,
          }}
        >
          <SuggestionContainer height={'46'}>
            <Padding14 />
            <Address>
              <TextOverFlowContainer>{address}</TextOverFlowContainer>
            </Address>
            <Padding14 />
          </SuggestionContainer>
        </AddressMenuItem>
      );
    }
  }
  if (typeof suggestion !== 'undefined') {
    const isSelected = (selectedItem || '').indexOf(suggestion) > -1;
    return (
      <MenuItem
        {...itemProps}
        key={`${suggestion}${index}`}
        selected={isHighlighted}
        component="div"
        style={{
          fontWeight: isSelected ? 500 : 400,
        }}
      >
        <TextOverFlowContainer>{suggestion}</TextOverFlowContainer>
      </MenuItem>
    );
  } else {
    return <div />;
  }
}
