import * as React from 'react';

const SvgComponent = (props: any) => (
  <svg
    width={16}
    height={16}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g
      clipPath="url(#a)"
      stroke={props.color || '#373634'}
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M11.333 14v-1.333A2.667 2.667 0 0 0 8.667 10H3.333a2.667 2.667 0 0 0-2.666 2.667V14M6 7.333A2.667 2.667 0 1 0 6 2a2.667 2.667 0 0 0 0 5.333ZM15.333 14v-1.333a2.667 2.667 0 0 0-2-2.58M10.667 2.087a2.666 2.666 0 0 1 0 5.166" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h16v16H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default SvgComponent;
