import React from 'react';

const GoogleLogin = (props: any) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M17.8249 7.23735H17.1V7.2H9V10.8H14.0864C13.3443 12.8956 11.3503 14.4 9 14.4C6.01785 14.4 3.6 11.9822 3.6 9C3.6 6.01785 6.01785 3.6 9 3.6C10.3765 3.6 11.6289 4.1193 12.5824 4.96755L15.1281 2.4219C13.5207 0.92385 11.3706 0 9 0C4.02975 0 0 4.02975 0 9C0 13.9703 4.02975 18 9 18C13.9703 18 18 13.9703 18 9C18 8.39655 17.9379 7.8075 17.8249 7.23735Z"
        fill="#FFC107"
      />
      <path
        d="M1.03809 4.81095L3.99504 6.9795C4.79514 4.9986 6.73284 3.6 9.00039 3.6C10.3769 3.6 11.6293 4.1193 12.5828 4.96755L15.1285 2.4219C13.5211 0.92385 11.371 0 9.00039 0C5.54349 0 2.54559 1.95165 1.03809 4.81095Z"
        fill="#FF3D00"
      />
      <path
        d="M9.00004 17.9999C11.3247 17.9999 13.437 17.1103 15.0341 15.6635L12.2486 13.3064C11.3146 14.0167 10.1734 14.4008 9.00004 14.3999C6.65914 14.3999 4.67149 12.9073 3.92269 10.8242L0.987793 13.0855C2.47729 16.0001 5.50219 17.9999 9.00004 17.9999Z"
        fill="#4CAF50"
      />
      <path
        d="M17.825 7.2373H17.1V7.19995H9V10.8H14.0864C13.7314 11.7973 13.092 12.6689 12.2472 13.3069L12.2485 13.306L15.034 15.6631C14.8369 15.8422 18 13.5 18 8.99995C18 8.3965 17.9379 7.80745 17.825 7.2373Z"
        fill="#1976D2"
      />
    </svg>
  );
};

export default GoogleLogin;
