import * as React from 'react';

const SvgComponent = (props: any) => (
  <svg
    width={18}
    height={18}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g
      className="icon"
      opacity={0.8}
      stroke="#fff"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path
        d="M7.99998 14.6667C11.6819 14.6667 14.6666 11.6819 14.6666 8.00001C14.6666 4.31811 11.6819 1.33334 7.99998 1.33334C4.31808 1.33334 1.33331 4.31811 1.33331 8.00001C1.33331 11.6819 4.31808 14.6667 7.99998 14.6667Z"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M7.99998 10.6667C9.47274 10.6667 10.6666 9.47277 10.6666 8.00001C10.6666 6.52725 9.47274 5.33334 7.99998 5.33334C6.52722 5.33334 5.33331 6.52725 5.33331 8.00001C5.33331 9.47277 6.52722 10.6667 7.99998 10.6667Z"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M3.28662 3.28665L6.11329 6.11332"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9.88666 9.88666L12.7133 12.7133"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9.88666 6.11332L12.7133 3.28665"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9.88666 6.11334L12.24 3.76001"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M3.28662 12.7133L6.11329 9.88666"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
  </svg>
);

export default SvgComponent;
