import * as React from 'react';
import { SelectProps } from '@material-ui/core/Select';
import Select from '@material-ui/core/Select';
import { Label, Error } from './input.styles';
import withStyles from '/withStyles';
import InputBase from '/InputBase';
import Icons from '/Icons';

const { ArrowDropDownCircleIcon } = Icons;

type InputSelectContainer = SelectProps & {
  label?: string;
  error?: boolean;
  helperText?: string;
};

const CustomSelectInput = withStyles(theme => ({
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.barelyThereGrey.main,
    border: '0',
    padding: '24px 16px 24px 16px',
    // Use the system font instead of the default Roboto font.
    fontFamily: theme.bodyFontFamily,
    letterSpacing: '0.01em',
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: 500,
    '&:focus': {
      backgroundColor: '#F9FCFF',
    },
  },
}))(InputBase);

const InputSelect = withStyles(theme => ({
  icon: {
    right: 16,
    color: theme.palette.primary.main,
  },
  iconOpen: {
    transform: 'rotate(180deg)',
  },
}))(Select);

export default function InputSelectContainer(props: InputSelectContainer) {
  const { label, error, helperText, ...rest } = props;
  return (
    <React.Fragment>
      {label && (
        <div>
          <Label htmlFor={props.name}>{label}</Label>
        </div>
      )}
      <InputSelect
        fullWidth
        displayEmpty
        input={<CustomSelectInput />}
        IconComponent={ArrowDropDownCircleIcon}
        renderValue={(selected: any) => {
          if (selected.length === 0) {
            return 'Select from the dropdown';
          }
          return selected;
        }}
        {...rest}
      />
      {error && <Error>{helperText}</Error>}
    </React.Fragment>
  );
}
