import React from 'react';

function ArrowLeftBlack() {
  return (
    <svg
      width="16"
      height="10"
      viewBox="0 0 16 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.999998 5H15.5M0.999998 5L5.27528 9M0.999998 5L5.27408 1"
        stroke="#373634"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export default ArrowLeftBlack;
