import React from 'react';

function Close() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="10"
      height="10"
      fill="none"
      viewBox="0 0 10 10"
    >
      <path
        stroke="#373634"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.75"
        d="M1 9l8-8M1 1l8 8"
      />
    </svg>
  );
}

export default Close;
