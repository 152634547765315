import React from 'react';
import { styled } from '@mui/material/styles';
import CircularProgress, {
  CircularProgressProps,
} from '@mui/material/CircularProgress';

const StyledCircularProgress = styled((props: CircularProgressProps) => (
  <CircularProgress {...props} />
))(() => ({
  color: 'white',
}));

type LoaderProps = {
  size?: number;
};

export default function Loader(props: LoaderProps) {
  return <StyledCircularProgress size={props?.size || 24} {...props} />;
}
