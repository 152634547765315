import React from 'react';

const SvgGift = (props: any) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M13.334 7.99967V14.6663H2.66732V7.99967M8.00065 14.6663V4.66634M8.00065 4.66634H5.00065C4.55862 4.66634 4.1347 4.49075 3.82214 4.17819C3.50958 3.86563 3.33398 3.4417 3.33398 2.99967C3.33398 2.55765 3.50958 2.13372 3.82214 1.82116C4.1347 1.5086 4.55862 1.33301 5.00065 1.33301C7.33398 1.33301 8.00065 4.66634 8.00065 4.66634ZM8.00065 4.66634H11.0007C11.4427 4.66634 11.8666 4.49075 12.1792 4.17819C12.4917 3.86563 12.6673 3.4417 12.6673 2.99967C12.6673 2.55765 12.4917 2.13372 12.1792 1.82116C11.8666 1.5086 11.4427 1.33301 11.0007 1.33301C8.66732 1.33301 8.00065 4.66634 8.00065 4.66634ZM1.33398 4.66634H14.6673V7.99967H1.33398V4.66634Z"
      stroke="#393939"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SvgGift;
