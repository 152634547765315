import styled from '@emotion/styled';
import {
  // neutral2,
  errorColor,
  // inputFocusColor,
  // neutral34,
  // primaryColor,
  typography,
} from '../constants/CSS';

// type InputSelectProps = {
//   error?: boolean;
// };

// @ts-ignore: Type 'CreateStyled<any>' has no call signatures

export const Label = styled.label`
  font-family: ${typography.bodyFontFamily};
  font-size: 12px;
  line-height: 18px;
  font-weight: normal;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.64;
  letter-spacing: 0.01em;
  color: #4f5153;
`;

export const Error = styled.span`
  font-family: ${typography.bodyFontFamily};
  font-size: 11px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.64;
  letter-spacing: normal;
  margin-top: 8px;
  color: ${errorColor};
`;
