import React from 'react';

function SvgDiscover() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="28"
      fill="none"
      viewBox="0 0 28 28"
    >
      <path
        fill="#373634"
        d="M14.335 2.773c0 .354-.18.428-.402.428-.222 0-.402-.074-.402-.428V.488c0-.354.18-.488.402-.488.222 0 .402.134.402.488v2.285z"
      ></path>
      <path
        fill="#373634"
        fillRule="evenodd"
        d="M8 14.077c0-3.346 2.686-6.058 6-6.058s6 2.712 6 6.058c0 3.345-2.686 6.058-6 6.058s-6-2.713-6-6.058zm6.067 3.264l-.467-.391-.004-.004c-1.653-1.38-2.745-2.293-2.745-3.411 0-.913.778-1.63 1.769-1.63.56 0 1.096.24 1.447.62.35-.38.887-.62 1.447-.62.99 0 1.768.717 1.768 1.63 0 1.116-1.088 2.027-2.736 3.407l-.013.01-.466.389z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#373634"
        fillRule="evenodd"
        d="M21.886 19.833a.399.399 0 00-.558-.035l-.884-.935A8.09 8.09 0 0022 14.077C22 9.617 18.418 6 14 6s-8 3.616-8 8.077c0 4.46 3.582 8.077 8 8.077 1.596 0 3.08-.474 4.329-1.286l.873.922-.07.064.005.005a.406.406 0 00.012.526l3.78 4.217c.236.257.564.398.922.398.345 0 .679-.133.94-.374l.756-.7c.273-.252.438-.605.452-.967.013-.34-.103-.66-.324-.898l-3.789-4.228zM6.8 14.077c0-4.008 3.23-7.27 7.2-7.27s7.2 3.262 7.2 7.27c0 4.008-3.23 7.27-7.2 7.27s-7.2-3.262-7.2-7.27zm13.134 5.416c-.291.325-.61.625-.949.899l.812.858.967-.879-.83-.878zm5.073 5.837l-.757.7a.589.589 0 01-.399.162.45.45 0 01-.333-.136l-3.508-3.914 1.58-1.435 3.496 3.9c.1.108.117.234.114.32a.587.587 0 01-.193.403z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#373634"
        d="M13.933 24.8c-.222 0-.402.146-.402.5v2.206c0 .354.18.494.402.494.222 0 .402-.14.402-.494V25.3c0-.354-.18-.5-.402-.5zM25.282 13.597h2.235c.355 0 .483.18.483.4 0 .221-.128.4-.483.4h-2.235c-.355 0-.497-.179-.497-.4 0-.22.142-.4.497-.4zM2.634 13.597H.444c-.34 0-.444.18-.444.4 0 .221.104.4.444.4h2.19c.34 0 .447-.179.447-.4 0-.22-.107-.4-.447-.4zM5.976 6.476a.401.401 0 01-.285-.117L4.84 5.51a.399.399 0 010-.565.403.403 0 01.568 0l.853.848a.399.399 0 01-.284.683zM5.692 21.636l-.853.848a.399.399 0 00.284.683.401.401 0 00.284-.117l.853-.849a.399.399 0 000-.565.403.403 0 00-.568 0zM22.175 6.36a.401.401 0 01-.569-.567l.853-.848a.403.403 0 01.568 0 .399.399 0 010 .565l-.852.85z"
      ></path>
    </svg>
  );
}

export default SvgDiscover;
