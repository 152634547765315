import React from 'react';

function CardPlayIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="51"
      height="48"
      fill="none"
      viewBox="0 0 51 48"
    >
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M25.251 44c11.622 0 21.043-8.954 21.043-20S36.874 4 25.251 4C13.63 4 4.208 12.954 4.208 24s9.422 20 21.043 20z"
      ></path>
      <path
        fill="#fff"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M21.043 16l12.626 8-12.626 8V16z"
      ></path>
    </svg>
  );
}

export default CardPlayIcon;
