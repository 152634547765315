import React from 'react';

const Facebook = (props: any) => {
  const { fill, ...rest } = props;
  return (
    <svg
      width="40"
      height="41"
      viewBox="0 0 40 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <rect y="0.464844" width="40" height="40" rx="20" fill="white" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.4407 29.4648H17.8128V20.4637H16V17.3622H17.8128V15.5002C17.8128 12.9701 18.8359 11.4648 21.7444 11.4648H24.1652V14.5675H22.6522C21.5199 14.5675 21.4451 15.0009 21.4451 15.8098L21.4401 17.3622H24.1818L23.8609 20.4637H21.4401V29.4648H21.4407Z"
        fill="#373634"
      />
    </svg>
  );
};

export default Facebook;
