import * as React from 'react';
import theme from '../../../theme';

const SvgComponent = (props: any) => {
  const { fill, ...rest } = props;
  return (
    <svg
      width={12}
      height={10}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M11.054.037c.322-.074.925 0 .604.22-2.695 1.949-5.712 5.625-7.16 8.567C4.016 9.669 3.493 9.89 2.85 10 2.487 8.787 1.441 6.213.235 5.81c.603-.588 1.166-.882 1.649-.882.724 0 1.408 1.617 1.69 2.242 1.608-2.684 4.665-5.625 7.48-7.132Z"
        fill={fill || theme.palette.forestGreen.main}
      />
    </svg>
  );
};

export default SvgComponent;
