import { createTheme } from '@material-ui/core/styles';
import createBreakpoints from '@material-ui/core/styles/createBreakpoints';
import { MuiPickersOverrides } from '@material-ui/pickers/typings/overrides';
import {
  black1,
  blossom,
  blue0,
  bronzeNeutral0,
  bronzeNeutral2,
  bronzeNeutral3,
  darkGreenTintColor,
  disabledColor,
  errorBackgroundColor,
  forestGreen,
  green,
  greenTintColor,
  greyColors,
  lightGreen,
  lightPlum,
  linkColor,
  neutral0,
  neutral2,
  neutralColors,
  plum,
  primaryColors,
  rose0,
  secondaryColors,
  secondaryRed,
  secondaryTextColor,
  tealTint,
  typography,
} from './../constants/CSS';

declare module '@material-ui/core/styles/createTheme' {
  interface Theme {
    accentFontFamily: string;
    alexBrush: string;
    bodyFontFamily: string;
    gattewaySignatureFontFamily: string;
    headerFontFamily: string;
    hestinaFontFamily: string;
    montserrat: string;
    prata: string;
    sacramento: string;
  }
  interface ThemeOptions {
    accentFontFamily: string;
    alexBrush: string;
    bodyFontFamily: string;
    gattewaySignatureFontFamily: string;
    headerFontFamily: string;
    hestinaFontFamily: string;
    montserrat: string;
    prata: string;
    sacramento: string;
  }
}
declare module '@material-ui/core/styles/createPalette' {
  export interface Palette {
    activeGrey: Palette['primary'];
    barelyThereGrey: Palette['primary'];
    black1: Palette['primary'];
    blossom: Palette['primary'];
    blue: Palette['primary'];
    blue0: Palette['primary'];
    bronzeNeutral0: Palette['primary'];
    bronzeNeutral2: Palette['primary'];
    bronzeNeutral3: Palette['primary'];
    bronzeTint: Palette['primary'];
    cream: Palette['primary'];
    darkBlue100: Palette['primary'];
    darkBlue80: Palette['primary'];
    darkYellowBeige: Palette['primary'];
    deepRedText: Palette['primary'];
    forestGreen: Palette['primary'];
    gold: Palette['primary'];
    goldStar: Palette['primary'];
    green: Palette['primary'];
    greenTintColor: Palette['primary'];
    greyDark: Palette['primary'];
    greyLight: Palette['primary'];
    greyMedium: Palette['primary'];
    lightBlueTint: Palette['primary'];
    lightGreen: Palette['primary'];
    lightOrangeTint: Palette['primary'];
    lightPlum: Palette['primary'];
    lightYellowBeige: Palette['primary'];
    lightYellowTint: Palette['primary'];
    linkColor: Palette['primary'];
    mediumOrangeBeige: Palette['primary'];
    mud: Palette['primary'];
    neutral0: Palette['primary'];
    neutral2: Palette['primary'];
    orangeCream: Palette['primary'];
    plum: Palette['primary'];
    redText: Palette['primary'];
    redTint: Palette['primary'];
    rose0: Palette['primary'];
    secondaryRed: Palette['primary'];
    teal: Palette['primary'];
    tealTint: Palette['primary'];
    veryLightGrey: Palette['primary'];
    white: Palette['primary'];
    yellow: Palette['primary'];
  }
  interface PaletteOptions {
    activeGrey: PaletteOptions['primary'];
    barelyThereGrey: PaletteOptions['primary'];
    black1: PaletteOptions['primary'];
    blossom: PaletteOptions['primary'];
    blue: PaletteOptions['primary'];
    blue0: PaletteOptions['primary'];
    bronzeNeutral0: PaletteOptions['primary'];
    bronzeNeutral2: PaletteOptions['primary'];
    bronzeNeutral3: PaletteOptions['primary'];
    bronzeTint: PaletteOptions['primary'];
    cream: PaletteOptions['primary'];
    darkBlue100: PaletteOptions['primary'];
    darkBlue80: PaletteOptions['primary'];
    darkYellowBeige: PaletteOptions['primary'];
    deepRedText: PaletteOptions['primary'];
    forestGreen: PaletteOptions['primary'];
    gold: PaletteOptions['primary'];
    goldStar: PaletteOptions['primary'];
    green: PaletteOptions['primary'];
    greenTintColor: PaletteOptions['primary'];
    greyDark: PaletteOptions['primary'];
    greyLight: PaletteOptions['primary'];
    greyMedium: PaletteOptions['primary'];
    lightBlueTint: PaletteOptions['primary'];
    lightGreen: PaletteOptions['primary'];
    lightOrangeTint: PaletteOptions['primary'];
    lightPlum: PaletteOptions['primary'];
    lightYellowBeige: PaletteOptions['primary'];
    lightYellowTint: PaletteOptions['primary'];
    linkColor: PaletteOptions['primary'];
    mediumOrangeBeige: PaletteOptions['primary'];
    mud: PaletteOptions['primary'];
    neutral0: PaletteOptions['primary'];
    neutral2: PaletteOptions['primary'];
    orangeCream: PaletteOptions['primary'];
    plum: PaletteOptions['primary'];
    redText: PaletteOptions['primary'];
    redTint: PaletteOptions['primary'];
    rose0: PaletteOptions['primary'];
    secondaryRed: PaletteOptions['primary'];
    teal: PaletteOptions['primary'];
    tealTint: PaletteOptions['primary'];
    veryLightGrey: PaletteOptions['primary'];
    white: PaletteOptions['primary'];
    yellow: PaletteOptions['primary'];
  }
}

type overridesNameToClassKey = {
  [P in keyof MuiPickersOverrides]: keyof MuiPickersOverrides[P];
};

type MuiPickersBasePickerType = {
  MuiPickersBasePicker: {
    pickerView: {
      maxWidth?: string;
    };
  };
};

declare module '@material-ui/core/styles/overrides' {
  interface ComponentNameToClassKey extends overridesNameToClassKey {}
  export interface ComponentNameToClassKey extends MuiPickersBasePickerType {}
}

const breakpoints = createBreakpoints({
  /** Defaults **/
  // values: {
  //   xs: 0,
  //   sm: 600,
  //   md: 900,
  //   lg: 1200,
  //   xl: 1536,
  // },
});

const theme = createTheme({
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: primaryColors.black,
      light: primaryColors.blackHover,
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    white: {
      main: primaryColors.white,
    },
    black1: {
      main: black1,
    },
    secondary: {
      main: secondaryTextColor,
    },
    activeGrey: {
      main: greyColors.activeGrey,
    },
    blossom: {
      main: blossom,
    },
    mediumOrangeBeige: {
      main: neutralColors.mediumOrangeBeige,
    },
    bronzeNeutral0: {
      main: bronzeNeutral0,
    },
    bronzeNeutral2: {
      main: bronzeNeutral2,
    },
    bronzeNeutral3: {
      main: bronzeNeutral3,
    },
    cream: {
      main: neutralColors.cream,
    },
    darkBlue100: {
      main: primaryColors.darkBlue100,
    },
    darkBlue80: {
      main: primaryColors.darkBlue80,
    },
    blue: {
      main: primaryColors.blue,
    },
    bronzeTint: {
      main: neutralColors.bronzeTint,
    },
    mud: {
      main: primaryColors.mud,
      light: primaryColors.mudHover,
    },
    lightBlueTint: {
      main: neutralColors.lightBlueTint,
    },
    lightOrangeTint: {
      main: neutralColors.lightOrangeTint,
    },
    orangeCream: {
      main: neutralColors.orangeCream,
    },
    neutral0: {
      main: neutral0,
    },
    greenTintColor: {
      main: greenTintColor,
      dark: darkGreenTintColor,
    },
    greyDark: {
      main: greyColors.greyDark,
    },
    greyMedium: {
      main: greyColors.greyMedium,
    },
    greyLight: {
      main: greyColors.lightGrey,
    },
    veryLightGrey: {
      main: greyColors.veryLightGrey,
    },
    barelyThereGrey: {
      main: greyColors.barelyThereGrey,
    },
    rose0: {
      main: rose0,
    },
    darkYellowBeige: {
      main: neutralColors.darkYellowBeige,
    },
    lightYellowBeige: {
      main: neutralColors.lightYellowBeige,
    },
    lightYellowTint: {
      main: neutralColors.lightYellowTint,
    },
    gold: {
      light: neutralColors.goldTint,
      main: neutralColors.gold,
    },
    goldStar: {
      main: primaryColors.goldStar,
    },
    deepRedText: {
      main: secondaryColors.darkRed,
    },
    linkColor: {
      main: linkColor,
    },
    redText: {
      main: secondaryColors.red,
    },
    secondaryRed: {
      main: secondaryRed,
    },
    teal: {
      main: secondaryColors.teal,
    },
    yellow: {
      main: secondaryColors.yellow,
    },
    tealTint: {
      main: tealTint,
    },
    neutral2: {
      main: neutral2,
    },
    plum: {
      main: plum,
    },
    lightPlum: {
      main: lightPlum,
    },
    forestGreen: {
      main: forestGreen,
    },
    blue0: {
      main: blue0,
    },
    green: {
      main: green,
    },
    lightGreen: {
      main: lightGreen,
    },
    redTint: {
      main: errorBackgroundColor,
    },
  },

  // Typography fonts
  headerFontFamily: typography.headerFontFamily,
  bodyFontFamily: typography.bodyFontFamily,
  accentFontFamily: typography.accentFontFamily,
  hestinaFontFamily: typography.hestinaFontFamily,
  gattewaySignatureFontFamily: typography.gattewaySignatureFontFamily,
  montserrat: typography.montserrat,
  sacramento: typography.sacramento,
  prata: typography.prata,
  alexBrush: typography.alexBrush,

  // MUI Typography Overrides
  typography: {
    button: {
      textTransform: 'none',
    },
  },

  overrides: {
    // Style sheet name ⚛️
    MuiButton: {
      root: {
        fontFamily: `${typography.bodyFontFamily}`,
      },
    },
    MuiPickersCalendarHeader: {
      daysHeader: {
        maxHeight: 'unset',
      },
      dayLabel: {
        paddingBottom: '16px',
        textTransform: 'uppercase',
        fontSize: '12px',
        lineHeight: '18px',
        fontWeight: '420',
        color: `${greyColors.greyDark}`,
        letterSpacing: '0.01em',
        fontFamily: `${typography.bodyFontFamily}`,
      },
      transitionContainer: {
        order: 1,
        textAlignLast: 'start',
        border: 'unset',
        padding: 0,
        height: '28px',
        '& p': {
          fontSize: '18px',
          lineHeight: '27px',
          fontWeight: '500',
          letterSpacing: '0.01em',
        },
      },
      switchHeader: {
        marginTop: 'unset',
        marginBottom: 'unset',
      },
    },
    MuiPickersBasePicker: {
      pickerView: {
        maxWidth: 'unset',
        minHeight: 'unset',
        overflowX: 'unset',
        justifyContent: 'unset',
        padding: '16px',
      },
      pickerViewLandscape: {
        padding: '16px',
        paddingTop: '0px',
        minWidth: '342px',
      },
    },
    MuiPickersSlideTransition: {
      transitionContainer: {
        border: 'solid 1px',
      },
    },
    MuiPickersCalendar: {
      week: {
        paddingRight: '8px',
        paddingLeft: '8px',
        height: '42px',
        justifyContent: 'space-evenly',
        '&:first-of-type': {
          marginTop: '24px',
        },
        [breakpoints.down('md')]: {
          paddingRight: 'unset',
          paddingLeft: 'unset',
          height: 'unset',
          '&:first-of-type': {
            marginTop: '16px',
          },
        },
      },
    },
    MuiPickersDay: {
      hidden: {
        opacity: 'unset !important',
        color: `${disabledColor} !important`,
      },
      dayDisabled: {
        color: disabledColor,
      },
      day: {
        '&:hover': {
          color: 'white',
          backgroundColor: primaryColors.mud,
          '& p': {
            fontWeight: '420',
          },
        },
      },
      daySelected: {
        backgroundColor: primaryColors.mud,
        '& p': {
          fontWeight: '420',
        },
        '&:hover': {
          color: 'white',
        },
      },
    },
    MuiTypography: {
      body1: {
        fontFamily: `${typography.bodyFontFamily}`,
      },
      body2: {
        fontFamily: `${typography.bodyFontFamily}`,
      },
    },
  },
  props: {
    // Name of the component ⚛️
    MuiButtonBase: {
      // The default props to change
      disableRipple: true, // No more ripple, on the whole application 💣!
    },
  },
});

export default theme;
