import React from 'react';
import clsx from 'clsx';
import { Theme } from '@material-ui/core/styles';
import Button, { ButtonProps } from '@material-ui/core/Button';
import Loader from '../Loader';
import LoaderDark from '../Loader/LoaderDark';

import withDefaults from '../withDefaults';

export const styles = (theme: Theme) => ({
  root: {
    padding: '16px 24px',
    height: '48px',
    borderRadius: '1px',
    fontSize: '12px',
    fontWeight: '420',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: '16px',
    letterSpacing: '0.1em',
    fontFamily: theme.bodyFontFamily,
    textTransform: 'uppercase',
  },

  // --------------- Used for ButtonLink to fill in missing MUI Button styles
  linkRoot: {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    textDecoration: 'none',
    position: 'relative',
  },

  // --------------- Standard Buttons -----------------
  primary: {
    color: 'white !important',
    backgroundColor: ({ disabled }: ButtonProps) =>
      disabled ? theme.palette.greyMedium.main : theme.palette.primary.main,
    '&:hover': {
      backgroundColor: ({ disabled }: ButtonProps) =>
        disabled ? theme.palette.greyMedium.main : theme.palette.primary.light,
    },
    '&.Mui-disabled': {
      color: 'white',
      backgroundColor: theme.palette.greyMedium.main,
    },
  },
  secondary: {
    color: ({ disabled }: ButtonProps) =>
      disabled ? theme.palette.greyDark.main : theme.palette.primary.main,
    backgroundColor: 'white',
    border: ({ disabled }: ButtonProps) =>
      disabled
        ? `1px solid ${theme.palette.greyDark.main}`
        : `1px solid ${theme.palette.primary.main}`,
    '&:hover': {
      backgroundColor: ({ disabled }: ButtonProps) =>
        disabled ? 'white' : theme.palette.greyLight.main,
      border: ({ disabled }: ButtonProps) =>
        disabled
          ? `1px solid ${theme.palette.greyDark.main}`
          : `1px solid ${theme.palette.activeGrey.main}`,
    },
    '&.Mui-disabled': {
      color: theme.palette.greyDark.main,
      backgroundColor: 'white',
    },
  },
  tertiary: {
    color: ({ disabled }: ButtonProps) =>
      disabled ? theme.palette.greyDark.main : theme.palette.primary.main,
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: ({ disabled }: ButtonProps) =>
        disabled ? 'transparent' : theme.palette.greyLight.main,
    },
    '&.Mui-disabled': {
      color: theme.palette.greyDark.main,
      backgroundColor: 'transparent',
    },
  },
  large: {
    height: '56px',
    fontSize: '16px',
  },
  small: {
    height: '33px',
    borderRadius: '36px', // Small standard buttons are pill shaped.
    fontSize: '14px',
    lineHeight: '21px',
    letterSpacing: '0.01em',
    textTransform: 'capitalize',
    padding: '6px 16px',
  },
  mud: {
    backgroundColor: ({ disabled }: ButtonProps) =>
      disabled ? theme.palette.greyMedium.main : theme.palette.mud.main,
    '&:hover': {
      backgroundColor: ({ disabled }: ButtonProps) =>
        disabled ? theme.palette.greyMedium.main : theme.palette.mud.light,
    },
    '&.MuiButton-root:hover.Mui-disabled': {
      backgroundColor: theme.palette.greyMedium.main,
    },
  },

  // temporary color override for Wildthings launch
  wildThingsOrange: {
    backgroundColor: ({ disabled }: ButtonProps) =>
      disabled ? theme.palette.greyMedium.main : '#C75E30',
    '&:hover': {
      backgroundColor: ({ disabled }: ButtonProps) =>
        disabled ? theme.palette.greyMedium.main : '#D07C57',
    },
    '&.MuiButton-root:hover.Mui-disabled': {
      backgroundColor: theme.palette.greyMedium.main,
    },
  },

  // --------------- Product Selection -----------------
  addToCart: {
    height: '40px !important',
    color: ({ disabled }: ButtonProps) =>
      disabled
        ? `${theme.palette.activeGrey.main} !important`
        : theme.palette.primary.main,
    backgroundColor: ({ disabled }: ButtonProps) =>
      disabled
        ? theme.palette.greyLight.main
        : theme.palette.bronzeNeutral3.main,
    '&:hover': {
      backgroundColor: ({ disabled }: ButtonProps) =>
        disabled
          ? theme.palette.greyLight.main
          : theme.palette.bronzeNeutral3.main,
    },
    '&.MuiButton-root:hover.Mui-disabled': {
      backgroundColor: theme.palette.greyMedium.main,
    },
  },
  // Select wine to swap
  wineClub: {
    height: '40px !important',
    color: ({ disabled, locked }: ButtonProps) =>
      disabled || locked
        ? `${theme.palette.activeGrey.main} !important`
        : theme.palette.primary.main,
    backgroundColor: ({ disabled, selected, locked }: ButtonProps) =>
      disabled || locked
        ? theme.palette.greyLight.main
        : selected
        ? theme.palette.bronzeNeutral3.main
        : 'white',
    border: ({ disabled, selected, locked }: ButtonProps) =>
      disabled || selected || locked
        ? '1px solid transparent'
        : `1px solid ${theme.palette.primary.main}`,
    '&:hover': {
      backgroundColor: ({ disabled, locked }: ButtonProps) =>
        disabled || locked
          ? theme.palette.greyLight.main
          : theme.palette.bronzeNeutral3.main,
      border: '1px solid transparent',
    },
    '&.MuiButton-root:hover.Mui-disabled': {
      backgroundColor: theme.palette.greyMedium.main,
    },
  },
  // Literally the plus or minus icon of the add to cart button
  addToCartIcon: {
    height: '40px !important',
    color: ({ disabled }: ButtonProps) =>
      disabled ? theme.palette.greyDark.main : theme.palette.primary.main,
    backgroundColor: 'white',
    border: ({ disabled }: ButtonProps) =>
      disabled
        ? `1px solid ${theme.palette.greyDark.main}`
        : `1px solid ${theme.palette.bronzeNeutral3.main}`,
    '&:hover': {
      backgroundColor: `${theme.palette.barelyThereGrey.main}`,
      border: ({ disabled }: ButtonProps) =>
        disabled
          ? `1px solid ${theme.palette.greyDark.main}`
          : `1px solid ${theme.palette.bronzeNeutral3.main}`,
    },
  },

  // --------------- Links -----------------
  link: {
    border: 'none',
    padding: '0px',
    paddingBottom: '6px',
    minWidth: '20px',
    borderRadius: 0,

    // These were the sizes already here:
    fontSize: '14px',
    lineHeight: '21px',
    height: '21px',

    backgroundColor: 'transparent',
    color: ({ disabled }: ButtonProps) =>
      disabled ? theme.palette.greyMedium.main : theme.palette.primary.main,
    borderBottom: ({ disabled }: ButtonProps) =>
      disabled
        ? `2px solid ${theme.palette.greyMedium.main}`
        : `2px solid ${theme.palette.primary.main}`,
    '&:hover': {
      backgroundColor: 'transparent',
      color: ({ disabled }: ButtonProps) =>
        disabled ? theme.palette.greyMedium.main : theme.palette.primary.light,
      borderBottom: ({ disabled }: ButtonProps) =>
        disabled
          ? `2px solid ${theme.palette.greyMedium.main}`
          : `2px solid ${theme.palette.primary.light}`,
    },
  },
  textLink: {
    border: 'none',
    padding: '0px',
    paddingBottom: '6px',
    minWidth: '20px',
    borderRadius: 0,

    // These were the sizes already here:
    fontSize: '14px',
    lineHeight: '21px',
    height: '21px',

    backgroundColor: 'transparent',
    color: ({ disabled }: ButtonProps) =>
      disabled ? theme.palette.greyMedium.main : theme.palette.primary.main,
    textDecoration: 'underline',
    '&:hover': {
      textDecoration: 'underline',
      backgroundColor: 'transparent',
      color: ({ disabled }: ButtonProps) =>
        disabled ? theme.palette.greyMedium.main : theme.palette.primary.light,
    },
  },
  smallLink: {
    fontSize: '12px',
    lineHeight: '16px',
  },
  largeLink: {
    fontSize: '16px',
    lineHeight: '24px',

    // border: 'none',
    // padding: '0px',
    // paddingBottom: '6px',
    // minWidth: '20px',
  },
  mudLink: {
    color: ({ disabled }: ButtonProps) =>
      disabled ? theme.palette.greyMedium.main : theme.palette.mud.main,
    borderBottom: ({ disabled }: ButtonProps) =>
      disabled
        ? `2px solid ${theme.palette.greyMedium.main}`
        : `2px solid ${theme.palette.mud.main}`,
    '&:hover': {
      backgroundColor: 'transparent',
      color: ({ disabled }: ButtonProps) =>
        disabled ? theme.palette.greyMedium.main : theme.palette.mud.light,
      borderBottom: ({ disabled }: ButtonProps) =>
        disabled
          ? `2px solid ${theme.palette.greyMedium.main}`
          : `2px solid ${theme.palette.mud.light}`,
    },
  },

  // temporary color override for Wildthings launch
  wildThingsOrangeLink: {
    color: ({ disabled }: ButtonProps) =>
      disabled ? theme.palette.greyMedium.main : '#C75E30',
    borderBottom: ({ disabled }: ButtonProps) =>
      disabled
        ? `2px solid ${theme.palette.greyMedium.main}`
        : `2px solid #C75E30`,
    '&:hover': {
      backgroundColor: 'transparent',
      color: ({ disabled }: ButtonProps) =>
        disabled ? theme.palette.greyMedium.main : '#D07C57',
      borderBottom: ({ disabled }: ButtonProps) =>
        disabled
          ? `2px solid ${theme.palette.greyMedium.main}`
          : `2px solid #D07C57`,
    },
  },
});

const OHWButtonV2 = ({
  // Nested components
  loading,
  children,
  // Variations to support what is in Figma design system
  // Note: The term "hug" in figma doesn't need to be passed in;
  // it just means it hugs the text rather than taking the container's width.
  type = 'primary', // primary, secondary, tertiary, link, addToCart, wineClub
  size = 'default', // small, large
  color = 'primary', // primary, mud
  classes,
  // MUI Overrides
  className: classNamesProp,
  disabled = false,
  selected = false, // for multiselect or radio buttons
  locked = false, // button still does an action like open a modal, but probably looks disabled
  href, // if this is passed in, an anchor tag will be rendered instead of a button
  ...rest
}: ButtonProps & { href?: string }) => {
  const isLink = type === 'link';
  const isStandardButton = ['primary', 'secondary', 'tertiary'].includes(type);

  // ---------------------- TYPE ----------------------
  // Supported types - Naming is consistent with figma DS
  // Note: primary, secondary, and teriary are the most common base button
  // And the rest are special types
  const baseType = {
    [classes.primary]: type === 'primary',
    [classes.secondary]: type === 'secondary',
    [classes.tertiary]: type === 'tertiary',
    [classes.link]: type === 'link',
    [classes.textLink]: type === 'textLink',
    // Could eventually pull out the following specific buttons into another component export
    [classes.addToCart]: type === 'addToCart',
    [classes.wineClub]: type === 'wineClub',
    // TODO(gemma): ButtonV2 turn this into a IconButton export and then have the megalith construct it with startIcon and endIcon?
    // [classes.addToCartIcon]: type === 'addToCartIcon',
  };

  // ---------------------- SIZE ----------------------
  // Size: default, large, small
  const sizeVariant = {
    [classes.large]: size === 'large' && isStandardButton,
    [classes.small]: size === 'small' && isStandardButton,
    [classes.largeLink]: size === 'large' && isLink,
    [classes.smallLink]: size === 'small' && isLink,
  };

  // ---------------------- COLOR ----------------------
  // Color: primary, mud
  // Note: Ideally we'd do something less specific like "primary" and "light",
  // but we'd need to change it in contentful and lots of other places too.
  // Maybe color we just use super sparingly for now..?
  const colorVariant = {
    [classes.mud]: color === 'mud' && type === 'primary',
    [classes.wildThingsOrange]:
      color === 'wildThingsOrange' && type === 'primary',
    [classes.mudLink]: color === 'mud' && isLink,
    [classes.wildThingsOrangeLink]: color === 'wildThingsOrange' && isLink,
  };

  const className = clsx(
    classes.root,
    !!href ? classes.linkRoot : '',
    {
      ...baseType,
      ...sizeVariant,
      ...colorVariant,
    },
    classNamesProp, // override with one-off colors rather design system for blue and red
  );

  if (!!href) {
    return (
      <a href={href} className={className} {...rest}>
        {!loading && children}
        {loading && type !== 'secondary' && <Loader />}
        {loading && type === 'secondary' && <LoaderDark />}
      </a>
    );
  }

  return (
    <Button className={className} disabled={disabled} disableRipple {...rest}>
      {!loading && children}
      {loading && type !== 'secondary' && <Loader />}
      {loading && type === 'secondary' && <LoaderDark />}
    </Button>
  );
};

export default withDefaults(styles, { name: 'OH-Button' })(OHWButtonV2);
