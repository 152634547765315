import React from 'react';
import AppBar, { AppBarProps } from '@mui/material/AppBar';

import clsx from 'clsx';
import withDefaults from '../withDefaults';

const styles = {
  root: {},
};

const OHWAppBar = ({
  classes,
  className: classNamesProp,
  children,
  ...rest
}: AppBarProps) => {
  const className = clsx(classes?.root, classNamesProp);
  return (
    <AppBar className={className} color="inherit" {...rest}>
      {children}
    </AppBar>
  );
};

export default withDefaults(styles, { name: 'OH-AppBar' })(OHWAppBar);
export { AppBarProps };
