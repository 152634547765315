import * as React from "react"

const SvgComponent = (props: any) => (
    <svg
        width={18}
        height={19}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g clipPath="url(#a)">
            <path
                d="m16.787 9.5 1.157-2.067a.451.451 0 0 0-.164-.606l-2.037-1.211-.03-2.37a.454.454 0 0 0-.449-.45l-2.37-.03L11.68.72a.451.451 0 0 0-.606-.164L9 1.713 6.932.556a.451.451 0 0 0-.605.164L5.116 2.758l-2.371.03a.454.454 0 0 0-.448.448l-.03 2.37L.22 6.822a.451.451 0 0 0-.164.605l1.157 2.075-1.157 2.068a.451.451 0 0 0 .164.605l2.037 1.211.03 2.37a.454.454 0 0 0 .449.45l2.37.03 1.211 2.037a.451.451 0 0 0 .606.164L9 17.288l2.068 1.157c.213.116.48.044.605-.164l1.211-2.037 2.371-.03a.454.454 0 0 0 .448-.449l.03-2.37 2.038-1.212a.451.451 0 0 0 .163-.605L16.788 9.5Zm-4.366-2.767a.451.451 0 0 1 0 .642 30.873 30.873 0 0 0-3.893 4.802.457.457 0 0 1-.342.218H8.14a.448.448 0 0 1-.333-.145L5.555 9.803a.454.454 0 0 1 .669-.605l1.868 2.007a34.28 34.28 0 0 1 3.694-4.463.457.457 0 0 1 .641-.009h-.006Z"
                fill="#268794"
            />
            <path
                d="M12.422 6.72a.451.451 0 0 1 0 .643 30.862 30.862 0 0 0-3.893 4.802.457.457 0 0 1-.342.218H8.14a.448.448 0 0 1-.333-.146L5.556 9.791a.454.454 0 0 1 .669-.606l1.868 2.008a34.277 34.277 0 0 1 3.694-4.463.457.457 0 0 1 .641-.01h-.006Z"
                fill="#fff"
            />
        </g>
        <defs>
            <clipPath id="a">
                <path fill="#fff" transform="translate(0 .5)" d="M0 0h18v18H0z" />
            </clipPath>
        </defs>
    </svg>
)

export default SvgComponent
