import React from 'react';

const UpsellTopBorder = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={38} height={38} fill="none">
    <path
      fill="#9F7058"
      d="M0 4a4 4 0 0 1 4-4h34l-2.5 2.273a40.191 40.191 0 0 0-9.64 13.299A40.19 40.19 0 0 1 3.297 36.763L0 38V4Z"
    />
  </svg>
);
export default UpsellTopBorder;
