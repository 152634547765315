import React from "react";

function Icon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="30"
            height="30"
            fill="none"
            viewBox="0 0 30 30"
        >
            <path
                fill="#373634"
                fillRule="evenodd"
                d="M19.488 6.18a.626.626 0 01-.882 0 .603.603 0 010-.868l1.948-1.94a.65.65 0 01.902 0c.247.243.247.647 0 .869l-1.968 1.94zm-7.425-2.02c.144.323.02.687-.308.828a.64.64 0 01-.84-.303l-1.17-2.464a.633.633 0 01.308-.829.65.65 0 01.841.303l1.17 2.465zm4.04-.141a.631.631 0 01-.738.505c-.328-.06-.574-.384-.513-.707l.493-2.708c.061-.323.39-.545.717-.505.35.061.575.384.513.728l-.471 2.687zm-.45 5.535l10.192-2.707c.35-.08.698.121.78.445l1.517 5.535a6.34 6.34 0 01-.656 4.829 6.483 6.483 0 01-3.322 2.768l1.845 6.747 2.605-.687a.617.617 0 01.759.445.595.595 0 01-.43.747l-6.44 1.697a.606.606 0 01-.76-.424.607.607 0 01.431-.768l2.625-.686-1.845-6.748a6.562 6.562 0 01-4.266-.768c-1.436-.828-2.564-2.162-3.015-3.859l-.903-3.313-.43 1.616a6.375 6.375 0 01-3.015 3.839 6.4 6.4 0 01-4.287.767l-1.825 6.748 2.605.687c.328.101.533.445.45.768a.656.656 0 01-.778.444L1.07 25.98a.627.627 0 01-.451-.767c.082-.324.43-.526.78-.445l2.604.707 1.825-6.747c-1.435-.546-2.584-1.536-3.302-2.789a6.212 6.212 0 01-.656-4.808l1.497-5.556c.103-.323.451-.525.78-.424l11.239 2.97c.348.08.553.424.45.747l-.184.687zm6.747 4.223c.964 0 1.825-.04 2.605-.243a4.922 4.922 0 001.804-.788l-1.23-4.525-10.05 2.646.78 2.788c1.517-.12 2.85-.04 4.101.04.698.041 1.354.082 1.99.082zm-8.696-1.819c-1.538-.141-2.85-.06-4.122.02-.677.041-1.354.082-1.99.082-.943 0-1.805-.04-2.584-.223a5.703 5.703 0 01-1.805-.788l1.23-4.545 10.03 2.646-.76 2.808z"
                clipRule="evenodd"
            ></path>
        </svg>
    );
}

export default Icon;
