import * as React from 'react';

const SvgComponent = (props: any) => (
  <svg
    width={18}
    height={18}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      className="icon"
      d="M7.5 2.25H2.25V7.5H7.5V2.25ZM15.75 2.25H10.5V7.5h5.25V2.25ZM15.75 10.5H10.5v5.25h5.25V10.5ZM7.5 10.5H2.25v5.25H7.5V10.5Z"
      stroke="#fff"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SvgComponent;
