import React from 'react';
import Chip from '@mui/material/Chip';
import { styled } from '@mui/material/styles';

import theme from '../theme';
import Icons from '/Icons';

const noOp = () => {};

const capitalizeFirstLetter = (string?: string) => {
  if (!string) {
    return '';
  }
  return string.charAt(0).toUpperCase() + string.slice(1);
};

type OHWChipProps = {
  label: string;
  onClick?: () => void;
  onDelete?: () => void;
  secondary?: boolean;
  tertiary?: boolean;
  checked?: boolean;
};

const StyledChip = styled(Chip)(({ secondary, tertiary }: OHWChipProps) => {
  const primary = !secondary && !tertiary;
  return {
    fontFamily: theme.bodyFontFamily,
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '22px',
    letterSpacing: '0.01em',
    textAlign: 'center',
    padding: '6px 16px',
    ...(primary && {
      border: `1px solid ${theme.palette.primary.main}`,
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.neutral0.main,
    }),
    ...(secondary && {
      border: `1px solid ${theme.palette.primary.main}`,
      backgroundColor: theme.palette.neutral0.main,
      color: theme.palette.primary.main,
    }),
    ...(tertiary && {
      border: `1px solid ${theme.palette.greyLight.main}`,
      backgroundColor: theme.palette.greyLight.main,
      color: theme.palette.primary.main,
    }),
    '&:hover': {
      ...(primary && {
        background: theme.palette.primary.main,
        opacity: 0.85,
      }),
      ...(secondary && {
        backgroundColor: theme.palette.greyLight.main,
        border: `1px solid ${theme.palette.greyLight.main}`,
      }),
      ...(tertiary && {
        backgroundColor: theme.palette.veryLightGrey.main,
      }),
    },
    '& span': {
      '&.MuiChip-label': {
        padding: `0px`,
      },
    },
    '& svg': {
      '&.MuiChip-deleteIcon': {
        margin: '0px 0px 0px 7px',
      },
    },
  };
});

const OHWChip = ({
  label,
  onClick = noOp,
  onDelete,
  secondary,
  tertiary,
  checked,
  ...rest
}: OHWChipProps) => {
  const props = {
    ...rest,
    ...(checked && {
      deleteIcon: <Icons.OHCheckCircleIcon />,
      onDelete: () => {},
    }),
    ...(onDelete && {
      deleteIcon: <Icons.CloseCircleBlack />,
      onDelete: onDelete,
    }),
  };

  return (
    <StyledChip
      label={capitalizeFirstLetter(label)}
      onClick={onClick}
      secondary={secondary}
      tertiary={tertiary}
      {...props}
    />
  );
};

export default OHWChip;
