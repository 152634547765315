import React from 'react';

const ReachOutsDownloadButton = (props: any) => {
  const { fill, ...rest } = props;
  return (
    <svg
      width="60"
      height="61"
      viewBox="0 0 60 61"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <rect
        y="0.5"
        width="60"
        height="60"
        rx="12"
        fill="url(#paint0_linear_2118_52908)"
      />
      <path
        d="M45 35.5V42.1667C45 43.0507 44.6488 43.8986 44.0237 44.5237C43.3986 45.1488 42.5507 45.5 41.6667 45.5H18.3333C17.4493 45.5 16.6014 45.1488 15.9763 44.5237C15.3512 43.8986 15 43.0507 15 42.1667V35.5"
        stroke="white"
        stroke-width="2.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M21.6667 27.1665L30 35.4998L38.3333 27.1665"
        stroke="white"
        stroke-width="2.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M30 35.5V15.5"
        stroke="white"
        stroke-width="2.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2118_52908"
          x1="30"
          y1="0.5"
          x2="30"
          y2="60.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#3490AD" />
          <stop offset="1" stop-color="#335E65" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default ReachOutsDownloadButton;
