import React from "react";

function Icon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="30"
            height="27"
            fill="none"
            viewBox="0 0 30 27"
        >
            <path
                fill="#373634"
                fillRule="evenodd"
                d="M17.335 24.881c-.398 0-.798-.02-1.2-.06l.217-2.143a9.937 9.937 0 009.458-4.715C28.634 13.3 27.138 7.21 22.477 4.388a9.823 9.823 0 00-7.478-1.144A9.825 9.825 0 008.9 7.722a9.784 9.784 0 00-1.404 4.384l-2.147-.158a11.91 11.91 0 011.71-5.341 11.953 11.953 0 017.424-5.455 11.962 11.962 0 019.107 1.394 11.953 11.953 0 015.455 7.425 11.959 11.959 0 01-1.393 9.107c-2.187 3.61-6.145 5.803-10.318 5.803z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#373634"
                fillRule="evenodd"
                d="M12.35 23.409l4.862-2.418-.338 5.419-4.523-3.001zm6.152-6.567v1.214l-1.734.06v-1.111c-1.008-.06-1.955-.386-2.697-.785l.741-1.63c.756.355 1.556.593 2.297.593.962 0 1.362-.46 1.362-.949 0-.533-.651-.77-1.437-1.051-1.14-.4-2.548-.904-2.548-2.563 0-1.17.696-2.37 2.326-2.74V6.9l1.734-.058v.977a6.728 6.728 0 012.102.592l-.621 1.616c-.697-.326-1.467-.52-2.06-.52-.829 0-1.199.43-1.199.904 0 .505.429.786 1.007 1.023 1.215.488 3.126.785 3.126 2.592 0 1.288-.918 2.4-2.4 2.815zm-16.76 3.28a5.111 5.111 0 014.518-5.85 5.083 5.083 0 011.893.158 4.817 4.817 0 00-3.195-.791A4.865 4.865 0 00.625 18.97a4.852 4.852 0 002.183 3.567 5.058 5.058 0 01-1.066-2.415z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#373634"
                fillRule="evenodd"
                d="M10.673 21.998a4.064 4.064 0 11-5.75-5.627 4.072 4.072 0 013.473-.712c.253.065.494.152.724.261a4.063 4.063 0 011.553 6.078zm-.077-6.045a4.856 4.856 0 00-2.638-1.179l-.085-.007a4.828 4.828 0 00-3.288.87 4.853 4.853 0 00-.295 7.7 4.832 4.832 0 003.023 1.12 4.865 4.865 0 004.936-4.78 4.848 4.848 0 00-1.653-3.723"
                clipRule="evenodd"
            ></path>
            <path
                fill="#373634"
                fillRule="evenodd"
                d="M7.33 19.82l-.165.017-.159-1.535c.065-.012.124-.018.183-.024.507-.053.84.11.897.652.05.49-.195.832-.755.89H7.33zm-.307-2.223a8.415 8.415 0 00-.966.17l.427 4.132.884-.091-.134-1.3.125-.012c.937-.096 1.734-.59 1.619-1.705-.107-1.028-.892-1.304-1.956-1.194"
                clipRule="evenodd"
            ></path>
        </svg>
    );
}

export default Icon;
