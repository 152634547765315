import React from 'react';
import Icons from '../Icons';
import makeStyles from '../makeStyles';

const DEFAULT_SIZE = 64;

//#region Styles

export const useImageStyles = (size?: number, isCircle?: boolean) =>
  makeStyles(() => ({
    // to correctly display previously uploaded images (HC)
    // that aren't square
    image: {
      width: size ?? DEFAULT_SIZE,
      height: size ?? DEFAULT_SIZE,
      verticalAlign: 'middle',
      marginBottom: 8,
      borderRadius: `${isCircle ? 50 : 100}%`,
    },
  }));

//#region Profile Image Component

export type ProfileImageProps = {
  fullName?: string;
  fullProfileImgUrl: string;
  size: number;
  isCircle?: boolean;
};

export default function ProfileImage(props: ProfileImageProps) {
  const { fullName, fullProfileImgUrl, size, isCircle } = props;

  const hasUserImage: boolean = !!fullProfileImgUrl;

  const imageClasses = useImageStyles(size, isCircle)();

  const imageBackgroundStyle = {
    backgroundImage: `url(${fullProfileImgUrl}) center center / cover no-repeat`,
  };

  //#region HTML

  return (
    <>
      {hasUserImage ? (
        <div
          id="DesignSystemV2_ProfileImage"
          className={imageClasses.image + ' ProfileImage'}
          style={imageBackgroundStyle}
          title={fullName ?? ''}
        />
      ) : (
        <Icons.DefaultProfile
          width={size ?? DEFAULT_SIZE}
          height={size ?? DEFAULT_SIZE}
        />
      )}
    </>
  );
}
