import React from 'react';

const User = (props: any) => {
  const { fill, ...rest } = props;
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
    <path d="M19.9999 21.5V19.5C19.9999 18.4391 19.5785 17.4217 18.8284 16.6716C18.0782 15.9214 17.0608 15.5 15.9999 15.5H7.99994C6.93907 15.5 5.92166 15.9214 5.17151 16.6716C4.42137 17.4217 3.99994 18.4391 3.99994 19.5V21.5" stroke="#373634" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M11.9999 11.5C14.2091 11.5 15.9999 9.70914 15.9999 7.5C15.9999 5.29086 14.2091 3.5 11.9999 3.5C9.7908 3.5 7.99994 5.29086 7.99994 7.5C7.99994 9.70914 9.7908 11.5 11.9999 11.5Z" stroke="#373634" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
    
    </svg>
  );
};

export default User;
