import React from 'react';

const SvgDelete = (props: any) => (
  <svg
    width="10"
    height="10"
    viewBox="0 0 10 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 5C0 2.235 2.235 0 5 0C7.765 0 10 2.235 10 5C10 7.765 7.765 10 5 10C2.235 10 0 7.765 0 5ZM6.795 7.5L7.5 6.795L5.705 5L7.5 3.205L6.795 2.5L5 4.295L3.205 2.5L2.5 3.205L4.295 5L2.5 6.795L3.205 7.5L5 5.705L6.795 7.5Z"
      fill="#393939"
    />
  </svg>
);

export default SvgDelete;
