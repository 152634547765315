import React from 'react';

const OHLogo = (props: any) => {
  const { fill, ...rest } = props;
  return (
    <svg
      width="113"
      height="18"
      viewBox="0 0 113 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M0.648 8.8C0.648 11.176 2.58 13.108 4.956 13.108C7.332 13.108 9.276 11.176 9.276 8.8C9.276 6.424 7.332 4.48 4.956 4.48C2.58 4.48 0.648 6.424 0.648 8.8ZM1.368 8.8C1.368 6.808 2.976 5.212 4.956 5.212C6.948 5.212 8.544 6.808 8.544 8.8C8.544 10.78 6.948 12.388 4.956 12.388C2.976 12.388 1.368 10.78 1.368 8.8ZM2.82 8.824C2.82 10.06 3.828 11.068 5.064 11.068C5.58 11.068 6.048 10.924 6.468 10.564C6.564 10.468 6.564 10.324 6.48 10.228L6.288 10C6.192 9.88 6.072 9.904 5.94 9.988C5.688 10.156 5.388 10.252 5.088 10.252C4.26 10.252 3.66 9.616 3.66 8.788C3.66 7.996 4.26 7.324 5.088 7.324C5.388 7.324 5.688 7.408 5.976 7.6C6.072 7.66 6.204 7.72 6.3 7.588L6.468 7.372C6.564 7.264 6.552 7.12 6.444 7.024C6.048 6.712 5.544 6.568 5.064 6.568C3.828 6.568 2.82 7.564 2.82 8.824Z"
        fill="#373634"
      />
      <path
        d="M17.9405 4.9871C17.2781 4.9663 16.6248 5.1451 16.0653 5.50028C15.5522 5.84276 15.1468 6.32368 14.8961 6.88724C14.6198 7.51098 14.4816 8.18709 14.4911 8.86921C14.4842 9.54705 14.6237 10.2184 14.9002 10.8373C15.15 11.3976 15.5541 11.8753 16.0653 12.2146C16.6257 12.5647 17.2772 12.7408 17.9377 12.7208C18.5982 12.7417 19.25 12.5654 19.8101 12.2146C20.322 11.875 20.7273 11.3975 20.9793 10.8373C21.2549 10.2173 21.393 9.54492 21.3843 8.86644C21.3937 8.18431 21.2556 7.50821 20.9793 6.88447C20.7233 6.29858 20.296 5.80383 19.7537 5.4652C19.2113 5.12658 18.5793 4.95991 17.9405 4.9871ZM17.9405 13.5863C16.9377 13.5863 16.0625 13.3727 15.3385 12.9538C14.6315 12.5532 14.0572 11.9544 13.6866 11.2312C13.3155 10.501 13.1253 9.69241 13.1319 8.87337C13.1236 8.04794 13.3139 7.23262 13.6866 6.49612C14.0579 5.77314 14.6313 5.17368 15.3371 4.77074C16.0611 4.34633 16.9377 4.13135 17.9391 4.13135C18.9405 4.13135 19.817 4.34633 20.5424 4.77074C21.2482 5.17375 21.8216 5.77318 22.1929 6.49612C22.5626 7.23344 22.7505 8.04858 22.7407 8.87337C22.7514 9.69286 22.5609 10.5025 22.1859 11.2312C21.8067 11.9527 21.2293 12.5508 20.5216 12.9552C19.7354 13.3953 18.8454 13.6161 17.9446 13.5946"
        fill="#373634"
      />
      <path
        d="M28.2829 13.381V12.8484L28.4563 12.7707C28.5542 12.7198 28.6358 12.6423 28.6917 12.5471C28.7476 12.4519 28.7756 12.3429 28.7725 12.2326V5.43787C28.7114 5.33919 28.6413 5.24633 28.5631 5.16048C28.4837 5.08293 28.3807 5.03411 28.2705 5.02178L28 5.00098V4.32275H29.2926L29.38 4.424C29.521 4.5987 29.6481 4.7842 29.7601 4.97879C29.8017 5.04675 29.8377 5.10777 29.8682 5.15354C30.57 6.2118 31.9154 7.27421 33.8669 8.30888C34.9574 8.8852 35.9351 9.65338 36.7531 10.5766V5.47671C36.7563 5.36619 36.7281 5.25704 36.6719 5.16183C36.6157 5.06662 36.5338 4.98923 36.4355 4.93857L36.2635 4.8609V4.3283H38.534V4.8609L38.3606 4.93857C38.2625 4.98929 38.1807 5.06674 38.1248 5.16197C38.0688 5.25719 38.0409 5.36631 38.0444 5.47671V13.3824H37.086L37.0319 13.1535C37.0319 13.1341 36.4619 10.9427 33.2316 9.18822C31.9085 8.49474 30.846 7.77352 30.0638 7.04814V12.234C30.0606 12.3445 30.0885 12.4537 30.1445 12.5491C30.2004 12.6446 30.282 12.7223 30.38 12.7735L30.5534 12.8512V13.3838L28.2829 13.381Z"
        fill="#373634"
      />
      <path
        d="M52.0207 13.3813H44.5658V12.8501L44.7377 12.7711C44.8353 12.7201 44.9165 12.6428 44.9722 12.5479C45.0279 12.4529 45.0557 12.3443 45.0526 12.2343V5.47841C45.0558 5.36787 45.0278 5.25865 44.9719 5.16325C44.916 5.06784 44.8344 4.99009 44.7364 4.93888L44.563 4.86121V4.32861H52.0179L52.1663 5.91807H51.6115L51.5325 5.74332C51.4382 5.52834 51.2065 5.29671 50.9985 5.29671H46.3383V7.52695H48.545C48.7516 7.52695 48.9832 7.29533 49.0789 7.08173L49.1566 6.90698H49.6823V9.11502H49.1552L49.0748 8.94026C48.9805 8.72667 48.7488 8.49505 48.5408 8.49505H46.3383V12.4132H50.9999C51.2065 12.4132 51.4396 12.1816 51.5339 11.9666L51.6129 11.7919H52.1677L52.0207 13.3813Z"
        fill="#373634"
      />
      <path
        d="M64.2067 13.3813H61.9446V12.8501L62.1166 12.7711C62.2147 12.7203 62.2965 12.6429 62.3524 12.5477C62.4084 12.4524 62.4362 12.3433 62.4328 12.2329V5.47841C62.436 5.36737 62.4075 5.25772 62.3508 5.16221C62.2941 5.0667 62.2114 4.98925 62.1124 4.93888L61.9404 4.86121V4.32861H64.2067V4.86121L64.0347 4.93888C63.9364 4.98977 63.8544 5.06741 63.7982 5.16286C63.742 5.25831 63.7139 5.36769 63.7171 5.47841V7.84456C64.8601 7.80839 66.002 7.94254 67.1055 8.24262C68.3801 8.60323 69.4924 8.7877 70.4189 8.78908V5.47841C70.4225 5.36781 70.3947 5.25846 70.3387 5.16299C70.2827 5.06753 70.2009 4.98983 70.1027 4.93888L69.9293 4.86121V4.32861H72.1929V4.86121L72.0209 4.93888C71.9226 4.98983 71.8408 5.06753 71.7849 5.16299C71.7289 5.25846 71.7011 5.36781 71.7046 5.47841V12.2343C71.7011 12.3449 71.7289 12.4542 71.7849 12.5497C71.8408 12.6452 71.9226 12.7229 72.0209 12.7738L72.1929 12.8515V13.3827H69.9238V12.8515L70.0972 12.7738C70.1954 12.7229 70.2772 12.6452 70.3331 12.5497C70.3891 12.4542 70.4169 12.3449 70.4134 12.2343V9.74886C69.4162 9.76689 68.2275 9.56717 66.8697 9.18159C65.8429 8.90288 64.7807 8.77624 63.7171 8.80573V12.2343C63.7139 12.345 63.742 12.4544 63.7982 12.5498C63.8544 12.6453 63.9364 12.7229 64.0347 12.7738L64.2067 12.8515V13.3813Z"
        fill="#373634"
      />
      <path
        d="M82.0223 4.98734C81.3614 4.96738 80.7096 5.14614 80.1513 5.50051C79.6383 5.84299 79.2329 6.32391 78.9821 6.88748C78.7058 7.51122 78.5677 8.18732 78.5771 8.86945C78.5685 9.54791 78.7066 10.2202 78.9821 10.8403C79.2327 11.401 79.6377 11.8787 80.1499 12.2176C80.7181 12.5494 81.3643 12.7243 82.0223 12.7243C82.6803 12.7243 83.3265 12.5494 83.8947 12.2176C84.4069 11.8784 84.8124 11.4008 85.0639 10.8403C85.3395 10.2203 85.4777 9.54793 85.4689 8.86945C85.4784 8.18732 85.3402 7.51122 85.0639 6.88748C84.8078 6.30136 84.3804 5.80645 83.8378 5.46781C83.2951 5.12917 82.6627 4.96264 82.0237 4.99011L82.0223 4.98734ZM82.0237 13.5893C81.1157 13.6124 80.2178 13.3942 79.4218 12.9568C78.7149 12.5566 78.1407 11.9583 77.7699 11.2356C77.4019 10.5046 77.214 9.69616 77.222 8.87777C77.2123 8.05298 77.4001 7.23784 77.7699 6.50051C78.1411 5.77753 78.7146 5.17808 79.4204 4.77513C80.1458 4.35072 81.0209 4.13574 82.0237 4.13574C83.0265 4.13574 83.9003 4.35072 84.6256 4.77513C85.3314 5.17814 85.9048 5.77758 86.2761 6.50051C86.6465 7.23762 86.8344 8.05291 86.824 8.87777C86.8346 9.69726 86.6441 10.5069 86.2692 11.2356C85.8904 11.9574 85.3128 12.5556 84.6048 12.9596C83.8187 13.3999 82.9286 13.6207 82.0279 13.599"
        fill="#373634"
      />
      <path
        d="M94.0278 9.44237H96.1762C96.3652 9.44314 96.5539 9.42923 96.7407 9.40076C97.0139 9.36322 97.2791 9.28123 97.5258 9.15804C97.8602 8.99624 98.1409 8.74151 98.3344 8.42434C98.5074 8.12938 98.6029 7.79534 98.6118 7.45346V7.32725C98.6055 6.98236 98.5099 6.64497 98.3344 6.34806C98.1407 6.03053 97.8594 5.77574 97.5244 5.61435C97.2779 5.49199 97.0133 5.41004 96.7407 5.37163C96.6714 5.35915 96.609 5.34806 96.5466 5.34112L96.4786 5.33419H96.4079L96.2941 5.32586H94.0264L94.0278 9.44237ZM92.2539 13.3813V12.8487L92.4259 12.7711C92.5243 12.7202 92.6063 12.6425 92.6625 12.5471C92.7187 12.4516 92.7467 12.3423 92.7435 12.2316V5.47704C92.7466 5.36653 92.7185 5.25738 92.6623 5.16217C92.6061 5.06696 92.5242 4.98956 92.4259 4.9389L92.2539 4.86123V4.32864H96.8739C97.4142 4.32647 97.9456 4.46626 98.4149 4.73401C98.8842 5.00176 99.2749 5.38808 99.548 5.8543C99.8209 6.31638 99.9646 6.84331 99.964 7.37996C99.9646 7.91661 99.8209 8.44353 99.548 8.90561C99.2769 9.36781 98.889 9.7505 98.4231 10.0152C97.9517 10.2854 97.4172 10.4261 96.8739 10.423H94.0278V12.2343C94.0243 12.3449 94.0521 12.4543 94.1081 12.5497C94.164 12.6452 94.2458 12.7229 94.3441 12.7739L94.5174 12.8515V13.3841L92.2539 13.3813Z"
        fill="#373634"
      />
      <path
        d="M112.852 13.3813H105.395V12.8501L105.569 12.7711C105.667 12.7204 105.749 12.643 105.805 12.5478C105.861 12.4526 105.889 12.3434 105.886 12.2329V5.47841C105.889 5.36769 105.861 5.25831 105.805 5.16286C105.749 5.06741 105.667 4.98977 105.569 4.93888L105.395 4.86121V4.32861H112.852L113 5.91807H112.445L112.366 5.74332C112.27 5.52834 112.043 5.29949 111.831 5.29949H107.171V7.52695H109.376C109.584 7.52695 109.816 7.29533 109.91 7.08173L109.989 6.90698H110.517V9.11502H109.99L109.913 8.94026C109.818 8.72667 109.587 8.49505 109.379 8.49505H107.169V12.4132H111.831C112.039 12.4132 112.27 12.1816 112.365 11.9666L112.444 11.7919H112.999L112.852 13.3813Z"
        fill="#373634"
      />
    </svg>
  );
};

export default OHLogo;
