import React, { createContext, createRef, useContext, useEffect } from 'react';
import ReactSlick from 'react-slick';

let firstClientX: number, clientX;

const preventTouch = (e: TouchEvent) => {
  const minValue = 5; // threshold

  clientX = e.touches[0].clientX - firstClientX;

  // Vertical scrolling does not work when you start swiping horizontally.
  if (Math.abs(clientX) > minValue) {
    e.preventDefault();
    e.returnValue = false;

    return false;
  }
  return;
};

const touchStart = (e: TouchEvent) => {
  firstClientX = e.touches[0].clientX;
};

type SettingsType = {
  dots: boolean;
  arrows: boolean;
  infinite: boolean;
  speed: number;
  slidesToShow: number;
  slidesToScroll: number;
  initialSlide: number;
  prevArrow?: any;
  nextArrow?: any;
  afterChange?: (index: number) => void;
};

interface SliderProps {
  settings: SettingsType;
  children: React.ReactChildren;
}

// @ts-ignore: An argument for 'defaultValue' was not provided.
export const SliderContext = createContext<{
  slider: React.RefObject<ReactSlick>;
}>();

export const useSliderContext = () =>
  useContext<{
    slider: React.RefObject<ReactSlick>;
  }>(SliderContext);

// Custom arrow components
const NextArrow = (props: any) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: 'block' }}
      onClick={onClick}
      aria-label="Next"
      role="button"
    />
  );
};

const PrevArrow = (props: any) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: 'block' }}
      onClick={onClick}
      aria-label="Previous"
      role="button"
    />
  );
};

const Slider = ({ children, settings, ...props }: SliderProps) => {
  let containerRef = createRef<HTMLDivElement>();

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.addEventListener('touchstart', touchStart);
      containerRef.current.addEventListener('touchmove', preventTouch, {
        passive: false,
      });
    }

    return () => {
      if (containerRef.current) {
        containerRef.current.removeEventListener('touchstart', touchStart);
        // @ts-ignore
        containerRef.current.removeEventListener('touchmove', preventTouch, {
          passive: false,
        });
      }
    };
  });

  const slider = React.useRef<ReactSlick>(null);

  // Update settings to include custom arrows
  const updatedSettings = {
    ...settings,
    accessibility: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  //#region HTML

  return (
    <div ref={containerRef} id="Carousel_DS2">
      <SliderContext.Provider value={{ slider }}>
        <ReactSlick ref={slider} {...updatedSettings} {...props}>
          {children}
        </ReactSlick>
      </SliderContext.Provider>
    </div>
  );
};

export default Slider;
