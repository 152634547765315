import React from 'react';
import { TooltipProps } from '@material-ui/core/Tooltip';

import { styles } from './index.styles';
import withDefaults from '/withDefaults';
import ToolTip from '../ToolTip';
import Icons from '../Icons';

const InputToolTip = (props: TooltipProps) => {
  return (
    <ToolTip {...props}>
      <Icons.InfoOutLinedIcon />
    </ToolTip>
  );
};

export default withDefaults(styles, { name: 'OH-MuiToolTip' })(InputToolTip);
