import React from 'react';

const FilterTwoLines = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="25"
      fill="none"
      viewBox="0 0 24 25"
    >
      <g
        stroke="#373634"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        clipPath="url(#clip0_1473_7361)"
      >
        <path d="M16 17.5a2 2 0 100-4 2 2 0 000 4zM4 15.5h10M18 15.5h2M7 11.5a2 2 0 100-4 2 2 0 000 4zM4 9.5h1M9 9.5h11"></path>
      </g>
      <defs>
        <clipPath id="clip0_1473_7361">
          <path
            fill="#fff"
            d="M0 0H24V24H0z"
            transform="rotate(-90 12.25 12.25)"
          ></path>
        </clipPath>
      </defs>
    </svg>
  );
};

export default FilterTwoLines;
