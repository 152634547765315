import * as React from "react"

const SvgComponent = (props: any) => (
    <svg
        width={49}
        height={50}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g filter="url(#a)">
            <rect x={5} y={4} width={39} height={40} rx={19.5} fill="#E9EAE9" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M31.773 19.047a.774.774 0 0 1 0 1.097l-1.424 1.423-2.916-2.916 1.423-1.424a.774.774 0 0 1 1.097 0l1.82 1.82ZM18 31v-2.916l8.601-8.601 2.916 2.916-8.6 8.601H18Z"
                fill="#373634"
            />
            <rect x={4.5} y={3.5} width={40} height={41} rx={20} stroke="#fff" />
        </g>
        <defs>
            <filter
                id="a"
                x={0}
                y={0}
                width={49}
                height={50}
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
            >
                <feFlood floodOpacity={0} result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feOffset dy={1} />
                <feGaussianBlur stdDeviation={2} />
                <feColorMatrix values="0 0 0 0 0.101961 0 0 0 0 0.101961 0 0 0 0 0.101961 0 0 0 0.25 0" />
                <feBlend
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_2506_115882"
                />
                <feBlend
                    in="SourceGraphic"
                    in2="effect1_dropShadow_2506_115882"
                    result="shape"
                />
            </filter>
        </defs>
    </svg>
)

export default SvgComponent
