import React from 'react';

const LockClosedV2 = (props: any) => {
  const { fill, ...rest } = props;

  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10 0.399994C7.01289 0.399994 4.59459 2.84027 4.59459 5.85454V8.25454H3.53378C2.69172 8.25454 2 8.95254 2 9.80227V18.0523C2 18.902 2.69172 19.6 3.53378 19.6H16.4662C17.3083 19.6 18 18.902 18 18.0523V9.80227C18 8.95254 17.3083 8.25454 16.4662 8.25454H15.4054V5.85454C15.4054 2.84027 12.9871 0.399994 10 0.399994ZM9.99992 1.27272C12.5229 1.27272 14.5405 3.30867 14.5405 5.85454V8.25454H5.45938V5.85454C5.45938 3.30867 7.47698 1.27272 9.99992 1.27272ZM12.1618 13.9272C12.1618 12.7274 11.1887 11.7454 9.99966 11.7454C8.81065 11.7454 7.8375 12.7274 7.8375 13.9272C7.8375 15.127 8.81065 16.109 9.99966 16.109C11.1887 16.109 12.1618 15.127 12.1618 13.9272Z"
        fill="#373634"
      />
    </svg>
  );
};

export default LockClosedV2;
