import React from 'react';

const Archive = (props: any) => {
  const { fill, ...rest } = props;
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M16.6667 9.86666V16.8H7.06667V9.86666M10.8 12H12.9333M6 7.2H17.7333V9.86666H6V7.2Z"
        stroke="#B04A3D"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default Archive;
