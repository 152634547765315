import React from 'react';

const CeBrownIcon = (props: any) => {
  const { ...rest } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="21"
      fill="none"
      viewBox="0 0 20 21"
      {...rest}
    >
      <rect width="20" height="20" y="0.5" fill="#9F7058" rx="4"></rect>
      <text
        x="50%"
        y="50%"
        dominantBaseline="middle"
        textAnchor="middle"
        fill="#fff"
        fontSize="9"
        fontFamily="Arial, sans-serif"
        fontWeight="bold"
        letterSpacing="0.25"
      >
        REP
      </text>
    </svg>
  );
};

export default CeBrownIcon;
