import React from 'react';

function ArrowRightWhite() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="10"
      fill="none"
      viewBox="0 0 16 10"
    >
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="M15 5H.5M15 5l-4.275 4M15 5l-4.274-4"
      />
    </svg>
  );
}

export default ArrowRightWhite;
