import React, { useMemo, useEffect } from 'react';
import Downshift from 'downshift';
import debounce from 'lodash/debounce';
import styled from '@emotion/styled';
import Icons from '/Icons';
import MenuItem from '/MenuItem';
import Paper from '/Paper';
import theme from '/theme';
import Grid from '/Grid';
import InputSearch from '/InputSearch';

import { renderSuggestion } from './autoCompleteUtils';

const { Search, LocationOnIcon, AddCircleAutocompleteIcon } = Icons;

// @ts-ignore: Type 'CreateStyled<any>' has no call signatures
export const MenuContainer = styled(Paper)`
  && {
    max-height: 360px;
    overflow: auto;
    z-index: 100;
    padding-left: 0;
    width: 100%;
    position: absolute;
    border-radius: 0;
    @media all and (min-width: 768px) {
    }
    filter: drop-shadow(0px 8px 16px rgba(26, 26, 26, 0.16));
    box-shadow: none;
  }
`;

// @ts-ignore: Type 'CreateStyled<any>' has no call signatures
const AddMenuItem = styled(MenuItem)`
  && {
    height: ${(props: any) => `${props.height}px`};
    border-left: 4px solid ${theme.palette.veryLightGrey.main};
  }
`;
// @ts-ignore: Type 'CreateStyled<any>' has no call signatures
const AddManualGrid = styled(Grid)`
  && {
    margin-top: 6px;
  }
`;

const AddText = styled.div`
  font-family: ${theme.bodyFontFamily};
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.01em;
  color: ${theme.palette.primary.main};
`;

export interface CEData {
  node: {
    id: string;
    ceoId: string;
    customUrl: string;
    image: string;
    primaryLabel: string;
    secondaryLabel: string;
    routePath: string;
  };
}

interface AddressData {
  addressId: string;
  addressLineOne: string;
  addressLineTwo: string | null;
  city: string;
  country: string;
  id: string;
  placeId: string;
  state: string;
  zip: string | null;
}

interface AutoCompleteProps {
  value: string | undefined;
  error?: any;
  touched: any;
  label?: string;
  id: string;
  inputId: string;
  placeholder: string;
  isSubmitting: boolean;
  handleInputChange: (value: string) => void;
  handleSelectChange?: (field: string, selectedItem: string) => void;
  handleCeSelected?: (selectedItem: CEData) => void;
  handleAddressSelected?: (selectedItem: any) => void;
  handleCustomNonProfitSelected?: () => void;
  handleOuterClick: (field: string) => void;
  predictions?: string[];
  objectPredictions?: CEData[] | AddressData[] | Object[];
  isMenuOpen: boolean;
  validate?: boolean;
  handleNewCustomerOpen?: any;
  toggleAddManualAddress?: any;
  toggleAddManualNonProfit?: any;
  editing?: boolean;
  isWithinDialog?: boolean;
  showLocationIcon: boolean;
  handleChange: (e: React.ChangeEvent<{ value: string }>) => void;
  setFieldValue: (inputId: string, item: string) => void;
  getAddressZipMutation?: (variables: any) => any;
  urlEventImgUploads?: string;
  handleOnFocus?: () => void;
  autoFocus?: boolean;
}

export default function AutoComplete(props: AutoCompleteProps) {
  const {
    value,
    error,
    touched,
    label,
    id,
    inputId,
    placeholder,
    autoFocus,
    handleInputChange,
    handleSelectChange,
    handleCeSelected,
    handleAddressSelected,
    handleCustomNonProfitSelected,
    handleNewCustomerOpen,
    toggleAddManualAddress,
    toggleAddManualNonProfit,
    handleOuterClick,
    urlEventImgUploads,
    predictions,
    objectPredictions,
    isMenuOpen,
    handleChange,
    showLocationIcon,
    setFieldValue,
    getAddressZipMutation,
    handleOnFocus,
  } = props;

  const debounceHandler = useMemo(
    () =>
      debounce((searchVal: string) => {
        handleInputChange(searchVal);
      }, 100),
    [],
  );

  useEffect(() => {
    return () => {
      debounceHandler.cancel();
    };
  }, [debounceHandler]);

  const changeQuery = (event: React.ChangeEvent<{ value: string }>) => {
    // @ts-ignore
    const searchValue = event?.target?.value;
    handleChange(event);
    debounceHandler(searchValue);
  };

  const showAddCustomer =
    (id === 'hostFullNameDropDown' ||
      id === 'nameDropDown' ||
      id === 'contactNameDropDown') &&
    value !== '';

  const showAddAddress =
    (id === 'eventAddressDropDown' || id === 'displayAddressDropDown') &&
    value !== '';

  const showAddNonProfit = id === 'customNonProfitDropDown' && value !== '';
  return (
    <Downshift
      id={id}
      initialSelectedItem={value}
      isOpen={isMenuOpen}
      onSelect={(selectedItem) => {
        if (handleCeSelected) {
          setFieldValue(inputId, selectedItem?.node?.primaryLabel);
          handleCeSelected(selectedItem);
        } else if (handleAddressSelected) {
          const address = `${selectedItem.addressLineOne}, ${selectedItem.city}, ${selectedItem.state}`;
          setFieldValue(inputId, address);
          setFieldValue('addressLineOne', selectedItem.addressLineOne);
          setFieldValue('city', selectedItem.city);
          setFieldValue('state', selectedItem.state);
          handleAddressSelected(selectedItem);
          if (!selectedItem.zip) {
            const variables = {
              input: {
                placeId: selectedItem.placeId,
              },
            };
            if (getAddressZipMutation) {
              return getAddressZipMutation({ variables }).then(
                ({
                  data: {
                    // @ts-ignore
                    googlePlacesZip: { zip },
                  },
                }) => {
                  setFieldValue('zip', zip || '');
                },
              );
            }
          } else {
            setFieldValue('zip', selectedItem.zip);
          }
        } else if (handleCustomNonProfitSelected) {
          setFieldValue(inputId, selectedItem?.name);
          setFieldValue('nonProfitId', selectedItem?.nonProfitId);
          setFieldValue('nonProfitEditId', selectedItem?.nonProfitEditId);
          handleCustomNonProfitSelected();
        } else if (handleSelectChange) {
          handleSelectChange(inputId, selectedItem);
        }
      }}
      onOuterClick={() => handleOuterClick(inputId)}
      itemToString={(item) => {
        return item == null
          ? ''
          : typeof item === 'object'
          ? item[Object.keys(item)[0]]
          : String(item);
      }}
    >
      {({
        getItemProps,
        getMenuProps,
        getInputProps,
        highlightedIndex,
        selectedItem,
      }) => {
        return (
          <div style={{ position: 'relative', width: '100%' }}>
            <InputSearch
              {...getInputProps()}
              inputRef={(input: any) => {
                if (autoFocus && input !== null) {
                  input.focus();
                }
              }}
              fullWidth
              variant="outlined"
              label={label}
              placeholder={placeholder}
              id={inputId}
              name={inputId}
              value={value}
              adornmentIcon={showLocationIcon ? <LocationOnIcon /> : <Search />}
              error={error}
              helperText={error && touched}
              onChange={changeQuery}
              onFocus={handleOnFocus}
            />
            <MenuContainer {...getMenuProps()}>
              {isMenuOpen && (
                <div>
                  {predictions &&
                    predictions.map((suggestion: string, index: number) =>
                      renderSuggestion({
                        suggestion,
                        index,
                        itemProps: getItemProps({
                          item: suggestion,
                        }),
                        highlightedIndex,
                        selectedItem,
                        id,
                      }),
                    )}
                  {objectPredictions && (
                    <div>
                      {Object.keys(objectPredictions).map((_, index: number) =>
                        renderSuggestion({
                          urlEventImgUploads,
                          objSuggestion: objectPredictions[index],
                          index,
                          itemProps: getItemProps({
                            item: objectPredictions[index],
                          }),
                          highlightedIndex,
                          selectedItem,
                          id,
                        }),
                      )}
                      {showAddCustomer && handleNewCustomerOpen && (
                        <AddMenuItem
                          height="56"
                          component="div"
                          onClick={() => handleNewCustomerOpen({ name: value })}
                        >
                          <Grid
                            spacing={2}
                            direction="row"
                            container
                            alignItems="center"
                          >
                            <AddManualGrid item>
                              <AddCircleAutocompleteIcon />
                            </AddManualGrid>
                            <Grid xs item>
                              <AddText>Add manually</AddText>
                            </Grid>
                          </Grid>
                        </AddMenuItem>
                      )}
                      {showAddAddress && (
                        <AddMenuItem
                          height="56"
                          component="div"
                          onClick={toggleAddManualAddress}
                        >
                          <Grid
                            spacing={2}
                            direction="row"
                            container
                            alignItems="center"
                          >
                            <AddManualGrid item>
                              <AddCircleAutocompleteIcon />
                            </AddManualGrid>
                            <Grid xs item>
                              <AddText>Add manually</AddText>
                            </Grid>
                          </Grid>
                        </AddMenuItem>
                      )}
                      {showAddNonProfit && (
                        <AddMenuItem
                          height="56"
                          component="div"
                          onClick={toggleAddManualNonProfit}
                        >
                          <Grid
                            spacing={2}
                            direction="row"
                            container
                            alignItems="center"
                          >
                            <AddManualGrid item>
                              <AddCircleAutocompleteIcon />
                            </AddManualGrid>
                            <Grid xs item>
                              <AddText>Add manually</AddText>
                            </Grid>
                          </Grid>
                        </AddMenuItem>
                      )}
                    </div>
                  )}
                </div>
              )}
            </MenuContainer>
          </div>
        );
      }}
    </Downshift>
  );
}
