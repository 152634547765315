import * as React from 'react';

const SvgComponent = (props: any) => (
  <svg
    width={64}
    height={46}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect
      x={58.167}
      y={45.785}
      width={45.571}
      height={5.333}
      rx={2.666}
      transform="rotate(-90 58.167 45.785)"
      fill="#EDE0BF"
    />
    <rect
      x={58.167}
      y={45.785}
      width={10.867}
      height={5.333}
      rx={2.666}
      transform="rotate(-90 58.167 45.785)"
      fill="#D19400"
    />
    <rect
      x={43.648}
      y={45.785}
      width={45.571}
      height={5.333}
      rx={2.666}
      transform="rotate(-90 43.648 45.785)"
      fill="#EDE0BF"
    />
    <rect
      x={43.648}
      y={45.785}
      width={41.364}
      height={5.333}
      rx={2.666}
      transform="rotate(-90 43.648 45.785)"
      fill="#D19400"
    />
    <rect
      x={29.13}
      y={45.785}
      width={45.571}
      height={5.333}
      rx={2.666}
      transform="rotate(-90 29.13 45.785)"
      fill="#EDE0BF"
    />
    <rect
      x={29.13}
      y={45.785}
      width={31.549}
      height={5.333}
      rx={2.666}
      transform="rotate(-90 29.13 45.785)"
      fill="#D19400"
    />
    <rect
      x={14.612}
      y={45.785}
      width={45.571}
      height={5.333}
      rx={2.666}
      transform="rotate(-90 14.612 45.785)"
      fill="#EDE0BF"
    />
    <rect
      x={14.612}
      y={45.785}
      width={23.486}
      height={5.333}
      rx={2.666}
      transform="rotate(-90 14.612 45.785)"
      fill="#D19400"
    />
    <rect
      x={0.093}
      y={45.785}
      width={45.571}
      height={5.333}
      rx={2.666}
      transform="rotate(-90 .093 45.785)"
      fill="#EDE0BF"
    />
    <rect
      x={0.093}
      y={45.785}
      width={37.508}
      height={5.333}
      rx={2.666}
      transform="rotate(-90 .093 45.785)"
      fill="#D19400"
    />
  </svg>
);

export default SvgComponent;
