import * as React from 'react';
import MaskedInput from 'react-text-mask';

export default function ReactMuiMask(props: any) {
  const { inputRef, mask, ...rest } = props;
  return (
    <MaskedInput
      ref={ref => {
        // @ts-ignore: Type 'inputRef<any>' has no call signatures
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={mask}
      {...rest}
    />
  );
}
