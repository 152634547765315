import React from 'react';
import StackedButtons from './StackedButtons';
import FooterButtonBar from './FooterButtonBar';

type StackedButtonProps = {
  direction: string;
  onPrimaryClick: () => any;
  onSecondaryClick?: () => any;
  primaryLabel?: string;
  secondaryLabel?: string;
  primaryDisabled?: boolean;
  hideSecondary?: boolean;
};

const FooterButton = ({
  direction,
  primaryLabel,
  onPrimaryClick,
  primaryDisabled,
  secondaryLabel,
  onSecondaryClick,
}: StackedButtonProps) => {
  return (
    <FooterButtonBar>
      <StackedButtons
        direction={direction}
        primaryLabel={primaryLabel}
        onPrimaryClick={onPrimaryClick}
        primaryDisabled={primaryDisabled}
        secondaryLabel={secondaryLabel}
        onSecondaryClick={onSecondaryClick}
      />
    </FooterButtonBar>
  );
};

export default FooterButton;
