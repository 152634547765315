import React from 'react';

const Restore = (props: any) => {
  const { fill, ...rest } = props;
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <g clipPath="url(#clip0_2147_11961)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M10.0216 6.12484V3.92126L15.0216 7.12126L10.0216 10.3213V7.81156C7.56145 8.27493 5.70148 10.4163 5.70148 12.988C5.70148 15.8982 8.08333 18.2573 11.0215 18.2573C13.9596 18.2573 16.3415 15.8982 16.3415 12.988H18.0215C18.0215 16.8172 14.8875 19.9213 11.0215 19.9213C7.15549 19.9213 4.02148 16.8172 4.02148 12.988C4.02148 9.49504 6.62927 6.60538 10.0216 6.12484Z"
          fill="#335E65"
        />
      </g>
      <defs>
        <clipPath id="clip0_2147_11961">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(4 4)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Restore;
