import React from 'react';

const LinkedInLogo = (props: any) => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clip-path="url(#clip0_1500_51952)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M6.7749 0H23.2251C25.0203 0.0051772 26.7406 0.723949 28.01 1.99929C29.2794 3.27463 29.9948 5.00288 30 6.80648V23.196C29.9942 24.9992 29.2785 26.7268 28.0091 28.0016C26.7398 29.2764 25.0199 29.9948 23.2251 30H6.7749C4.98009 29.9948 3.26023 29.2764 1.99087 28.0016C0.721518 26.7268 0.00579691 24.9992 0 23.196V6.80648C0.00515319 5.00288 0.72059 3.27463 1.99002 1.99929C3.25944 0.723949 4.97967 0.0051772 6.7749 0V0Z"
          fill="#0A66C2"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M12.0093 11.6284H15.8008V13.5907H15.8545C16.3843 12.639 17.6733 11.6284 19.5996 11.6284C23.6011 11.6284 24.3408 14.1303 24.3408 17.3851V24.2676H20.3882V18.1504C20.3882 16.7498 20.3589 14.947 18.3325 14.947C16.3062 14.947 15.9595 16.4702 15.9595 18.0498V24.2799H12.0093V11.6284ZM9.76563 7.79718C9.76563 8.20564 9.64506 8.60494 9.41918 8.94457C9.1933 9.28419 8.87225 9.5489 8.49663 9.70521C8.12101 9.86153 7.70768 9.90243 7.30892 9.82274C6.91016 9.74305 6.54388 9.54636 6.25639 9.25753C5.9689 8.9687 5.77312 8.6007 5.6938 8.20009C5.61448 7.79947 5.65519 7.38422 5.81078 7.00684C5.96636 6.62947 6.22984 6.30692 6.5679 6.07999C6.90595 5.85306 7.30339 5.73193 7.70996 5.73193C8.25496 5.73258 8.77745 5.95038 9.16282 6.33755C9.54819 6.72471 9.76498 7.24964 9.76563 7.79718ZM5.65918 11.6284H9.76563V24.2676H5.65918V11.6284Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_1500_51952">
          <rect width="30" height="30" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default LinkedInLogo;
