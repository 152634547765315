import * as React from 'react';

const SvgComponent = (props: any) => (
  <svg
    width={17}
    height={16}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M13.833 2H3.167c-.737 0-1.334.597-1.334 1.333V10c0 .736.597 1.333 1.334 1.333h10.666c.737 0 1.334-.597 1.334-1.333V3.333c0-.736-.597-1.333-1.334-1.333ZM5.833 14h5.334M8.5 11.333V14"
      stroke={props.color || '#373634'}
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SvgComponent;
