import React from 'react';

function Icon(props: any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
      {...props}
    >
      <path
        fill="#373634"
        fillRule="evenodd"
        d="M0 10C0 4.48 4.48 0 10 0s10 4.48 10 10-4.48 10-10 10S0 15.52 0 10zm11 1h4V9h-4V5H9v4H5v2h4v4h2v-4z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default Icon;
