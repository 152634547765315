import React from 'react';
import clsx from 'clsx';
import { Theme } from '@material-ui/core/styles';
import withDefaults from '../withDefaults';

const styles = (theme: Theme) => ({
  root: {
    height: '1px',
    width: '100%',
    maxWidth: '1250px',
    margin: '24px auto',
    border: 0,
    backgroundColor: theme.palette.mud.main,
    [theme.breakpoints.up('md')]: {
      margin: '32px auto',
    },
    [theme.breakpoints.up('lg')]: {
      margin: '40 auto',
    },
  },
});

interface HRProps {
  className?: string;
  classes: {
    [key: string]: string;
  };
}

const HR = ({ className: classNamesProp, classes }: HRProps) => {
  return <hr className={clsx(classes.root, classNamesProp)} />;
};

export default withDefaults(styles, { name: 'OH-HR' })(HR);
