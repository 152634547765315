import React from 'react';
import { useDropzone, DropzoneOptions } from 'react-dropzone';

type DropzoneProps = DropzoneOptions & {
  className: string;
  children: any;
};

export default function Dropzone(props: DropzoneProps) {
  const { className, children, ...rest } = props;
  const { getRootProps, getInputProps } = useDropzone({
    ...rest,
  });

  return (
    <div {...getRootProps()} className={className}>
      <input {...getInputProps()} />
      {children}
    </div>
  );
}
