/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-no-bind */
import React from 'react';
import { TypographyProps } from '@material-ui/core/Typography';
import Linkify from 'react-linkify';
import Text from '../Text';

interface Props extends TypographyProps {
  text: string;
}

/**
 * Given a block of text, linkify URLs.
 * A `<Text />` component will be rendered using the props passed to this component.
 * @param text
 */
export default function LinkifiedText({ text, ...typographyProps }: Props) {
  return (
    <Linkify
      componentDecorator={(decoratedHref, decoratedText, key) => (
        <a target="blank" href={decoratedHref} key={key}>
          {decoratedText}
        </a>
      )}
    >
      <Text {...typographyProps}>{text}</Text>
    </Linkify>
  );
}
