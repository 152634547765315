import React from 'react';

const SvgTag = (props: any) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M13.7263 8.94001L8.94634 13.72C8.82251 13.844 8.67546 13.9423 8.5136 14.0094C8.35173 14.0765 8.17823 14.1111 8.00301 14.1111C7.82779 14.1111 7.65429 14.0765 7.49242 14.0094C7.33056 13.9423 7.18351 13.844 7.05967 13.72L1.33301 8.00001V1.33334H7.99967L13.7263 7.06001C13.9747 7.30983 14.1141 7.64776 14.1141 8.00001C14.1141 8.35226 13.9747 8.69019 13.7263 8.94001Z"
      stroke="#393939"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.66699 4.66666H4.67449"
      stroke="#393939"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SvgTag;
