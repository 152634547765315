import React from 'react';

const FacebookLogo = (props: any) => {
  const { fill, ...rest } = props;
  return (
    <svg
      width="60"
      height="61"
      viewBox="0 0 60 61"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M47 0.5H13C5.8203 0.5 0 6.3203 0 13.5V47.5C0 54.6797 5.8203 60.5 13 60.5H47C54.1797 60.5 60 54.6797 60 47.5V13.5C60 6.3203 54.1797 0.5 47 0.5Z"
        fill="url(#paint0_linear_1217_41127)"
      />
      <g clipPath="url(#clip0_1217_41127)">
        <path
          d="M40.8619 39.2961L42.1575 30.6748H34.0525V25.0802C34.0525 22.7216 35.1849 20.4223 38.8151 20.4223H42.5V13.0825C42.5 13.0825 39.1558 12.5 35.9583 12.5C29.2831 12.5 24.9201 16.6289 24.9201 24.1039V30.6748H17.5V39.2961H24.9201L25 60.5C26.5 60.5 27.9326 60.5 29.4863 60.5C31.04 60.5 32.5 60.5 34.0525 60.5V39.2961H40.8619Z"
          fill="white"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_1217_41127"
          x1="30.5"
          y1="0.5"
          x2="30.5"
          y2="60.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0127506" stopColor="#1AA9FD" />
          <stop offset="1" stopColor="#0163E0" />
        </linearGradient>
        <clipPath id="clip0_1217_41127">
          <rect
            width="25"
            height="48"
            fill="white"
            transform="translate(17.5 12.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default FacebookLogo;
