import React from 'react';

const StarOutline = (props: any) => {
  const { fill, ...rest } = props;
  return (
    <svg
      width="19"
      height="18"
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M9.4122 1L12.0116 6.26604L17.8244 7.11567L13.6183 11.2124L14.6109 17L9.4122 14.266L4.21346 17L5.2061 11.2124L1 7.11567L6.81283 6.26604L9.4122 1Z"
        stroke="#373634"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default StarOutline;
