import React from 'react';

const CheckMark = (props: any) => {
  const { fill, small, ...rest } = props;
  return (
    <svg
      width={small ? '14' : '15'}
      height={small ? '10' : '12'}
      viewBox={small ? '0 0 14 10' : '0 0 15 12'}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      {small ? (
        <path
          d="M12.3333 1.5L4.99996 8.83333L1.66663 5.5"
          stroke="#373634"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      ) : (
        <path
          d="M4.76691 9.46756L1.21092 5.73602L0 6.99776L4.76691 12L15 1.26174L13.7976 0L4.76691 9.46756Z"
          fill="#373634"
        />
      )}
    </svg>
  );
};

export default CheckMark;
