import * as React from 'react';

const SvgComponent = (props: any) => (
  <svg
    width={40}
    height={40}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M25.083 8.333a8.333 8.333 0 0 1 6.584 6.584m-6.584-13.25A15 15 0 0 1 38.333 14.9m-1.666 13.3v5a3.334 3.334 0 0 1-3.634 3.333 32.982 32.982 0 0 1-14.383-5.116 32.5 32.5 0 0 1-10-10 32.984 32.984 0 0 1-5.117-14.45A3.333 3.333 0 0 1 6.85 3.333h5A3.333 3.333 0 0 1 15.183 6.2c.211 1.6.603 3.171 1.167 4.683a3.334 3.334 0 0 1-.75 3.517l-2.117 2.117a26.667 26.667 0 0 0 10 10L25.6 24.4a3.333 3.333 0 0 1 3.517-.75c1.512.564 3.083.956 4.683 1.167a3.333 3.333 0 0 1 2.867 3.383Z"
      stroke="#373634"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SvgComponent;
