import React from 'react';

const SmallRightArrow = (props: any) => (
  <svg
    width="6"
    height="8"
    viewBox="0 0 6 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.575371 1.16462C0.520402 1.11654 0.492969 1.06119 0.492969 0.998677C0.492969 0.936161 0.520402 0.880817 0.575371 0.832823L0.987789 0.472071C1.04276 0.423988 1.10603 0.399902 1.1774 0.399902C1.24887 0.399902 1.31213 0.423988 1.3671 0.472071L5.21057 3.83396C5.26553 3.88204 5.29297 3.93739 5.29297 3.9999C5.29297 4.06242 5.26553 4.11776 5.21057 4.16584L1.36721 7.52773C1.31224 7.5759 1.24897 7.5999 1.1775 7.5999C1.10613 7.5999 1.04286 7.5759 0.98789 7.52773L0.575472 7.16716C0.520504 7.11908 0.49307 7.06373 0.49307 7.00113C0.49307 6.9387 0.520504 6.88336 0.575472 6.83527L3.81681 3.9999L0.575371 1.16462Z"
      fill="#373634"
    />
  </svg>
);

export default SmallRightArrow;
