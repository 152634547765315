import React from 'react';

function WineBottleNotAdded() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="45"
      fill="none"
      viewBox="0 0 19 45"
    >
      <path
        fill="#fff"
        stroke="#373634"
        strokeWidth="0.75"
        d="M18.156 25.009c-.4-2.107-1.999-5.24-2.34-5.949-.341-.71-2.56-4.68-3.113-7.036-.489-2.08-.77-7.582-.8-8.213a.159.159 0 01.04-.11l.028-.035a.154.154 0 00.018-.184c-.036-.062-.028-.131.022-.188l.242-.274a.158.158 0 00.04-.14l-.096-.439a.185.185 0 00-.065-.1l-.1-.085c-.053-.045-.077-.103-.065-.16.026-.135.06-.398-.02-.622-.106-.292-.212-.314-.498-.44A.382.382 0 0011.288 1H7.712a.4.4 0 00-.161.032c-.286.128-.392.15-.497.441-.08.224-.047.487-.02.621.01.058-.013.116-.066.161l-.1.085a.19.19 0 00-.064.1l-.098.438a.158.158 0 00.041.141l.242.274c.05.057.059.127.022.189a.153.153 0 00.018.183l.029.035a.159.159 0 01.04.11c-.031.632-.312 6.134-.8 8.214-.553 2.356-2.772 6.326-3.114 7.036-.341.71-1.939 3.842-2.34 5.948C.426 27.2.466 35.263.567 36.488c.074.904.005 4.84.13 5.889.061.51.387.86.54.977.748.572 8.18.646 8.18.646s7.598-.074 8.346-.646c.152-.116.478-.466.54-.977.126-1.048.055-4.984.13-5.888.101-1.225.141-9.29-.277-11.48z"
      ></path>
      <path
        stroke="#373634"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M9.5 38.334a5.833 5.833 0 100-11.667 5.833 5.833 0 000 11.667zM9.5 30.167v4.667M7.167 32.5h4.666"
      ></path>
    </svg>
  );
}

export default WineBottleNotAdded;
