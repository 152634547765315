import React from 'react';

const BlueSuccess = (props: any) => {
  const { fill, ...rest } = props;
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0 8C0 3.584 3.584 0 8 0C12.416 0 16 3.584 16 8C16 12.416 12.416 16 8 16C3.584 16 0 12.416 0 8ZM3.6 8.06954L6.74286 11.0857L12.4 5.65659L11.5137 4.8L6.74286 9.37856L4.48629 7.21897L3.6 8.06954Z"
        fill="#46789E"
      />
    </svg>
  );
};

export default BlueSuccess;
