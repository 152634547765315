import * as React from 'react';

type CurrencyProps = {
  rawValue: number;
  includeCents?: boolean;
};

export const formatUSD = (rawValue: number) =>
  Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(rawValue);

// this would be a good place to support i18n currency display in the future
export default function Index({
  rawValue,
  includeCents = true,
}: CurrencyProps) {
  if (!includeCents) {
    return (
      <>${rawValue.toLocaleString(undefined, { maximumFractionDigits: 0 })}</>
    );
  }

  return <>{formatUSD(rawValue)}</>;
}
