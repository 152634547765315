import React from 'react';

const BlueClose = (props: any) => {
  const { fill, ...rest } = props;
  return (
    <svg
      width="13"
      height="13"
      viewBox="0 0 13 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M6.96654 6.02349L11.8283 1.16177C12.0888 0.901204 12.0888 0.479107 11.8283 0.219277C11.5677 -0.041288 11.1456 -0.041288 10.8858 0.219277L6.02405 5.08099L1.16233 0.218495C0.902523 -0.0420699 0.479668 -0.0420699 0.219838 0.218495C-0.0407271 0.479059 -0.0407271 0.901156 0.219838 1.16099L5.08156 6.02349L0.219838 10.8852C-0.0407271 11.1458 -0.0407271 11.5679 0.219838 11.8277C0.349362 11.9587 0.520039 12.0235 0.690692 12.0235C0.861346 12.0235 1.03202 11.9587 1.16233 11.8285L6.02405 6.96595L10.8858 11.8277C11.0161 11.958 11.1867 12.0227 11.3574 12.0227C11.528 12.0227 11.6987 11.958 11.829 11.8277C12.0896 11.5671 12.0896 11.145 11.829 10.8852L6.96649 6.02346L6.96654 6.02349Z"
        fill="#46789E"
      />
    </svg>
  );
};

export default BlueClose;
