import * as React from 'react';

const SvgComponent = (props: any) => (
  <svg
    width={18}
    height={18}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      className="icon"
      d="M16.5 9h-3l-2.25 6.75-4.5-13.5L4.5 9h-3"
      stroke="#fff"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
      opacity={0.8}
    />
  </svg>
);

export default SvgComponent;
