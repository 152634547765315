import React from 'react';

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13"
      height="13"
      fill="none"
      viewBox="0 0 13 13"
    >
      <path
        fill="#373634"
        d="M3.344 12.995l.144-.16.128-.08.144.08c.208-.16.384-.336.528-.512.144-.175.416-.64.848-1.36l.992-1.68c.528-.896 3.296-4.655 3.92-5.36 1.44-1.647 1.92-2.143 2.112-2.4.176-.223.288-.4.288-.527 0-.08-.048-.208-.128-.208s-.208.064-.368.176l.128-.288-.544.32.032-.224c-.032.096-.288.351-.352.351-.048 0-.064-.048-.064-.127V.932l-.448.223c-.032 0-.064-.016-.064-.064 0-.047.032-.16.064-.32-.224.24-.544.513-.96.8-.56.385-2.736 3.12-3.616 4.545-.592.944-.832 1.504-.928 1.504-.032 0-.048-.064-.064-.192-.288.656-.544 1.2-.8 1.6-.256.4-.496.607-.672.607-.208 0-.384-.4-.576-1.216-.08-.351-.256-.576-.512-.704L2.528 7.7c-.048 0-.112.032-.192.08s-.128.096-.16.096H2.16l-.288-.096-.256.385c-.032.047-.096.063-.144.063-.08 0-.144-.016-.176-.063-.064.223-.144.335-.208.335-.048 0-.144-.032-.272-.112.112.848.208 1.409.304 1.68.192.528.624 1.729 1.04 2.528.048.096.112.208.16.304.16-.095.208-.111.32-.111l.128.015c.08.032.128.08.128.16l.368-.175c.064 0 .08.063.08.207z"
      ></path>
    </svg>
  );
}

export default Icon;
