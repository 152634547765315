import React from 'react';

function Truck() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        stroke="#373634"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M10.667 2h-10v8.667h10V2zM10.667 5.333h2.666l2 2v3.334h-4.666V5.333z"
      />
      <path
        stroke="#373634"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M3.667 14a1.667 1.667 0 100-3.333 1.667 1.667 0 000 3.333zM12.333 14a1.667 1.667 0 100-3.333 1.667 1.667 0 000 3.333z"
      />
    </svg>
  );
}

export default Truck;
