import React from 'react';

const PlusMediumDisabled = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="10"
      height="10"
      viewBox="0 0 10 10"
      fill="none"
    >
      <g clip-path="url(#clip0_427_2290)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M5.75 0.75C5.75 0.335786 5.41421 0 5 0C4.58579 0 4.25 0.335786 4.25 0.75V4H1C0.585786 4 0.25 4.33579 0.25 4.75C0.25 5.16421 0.585786 5.5 1 5.5H4.25V8.75C4.25 9.16421 4.58579 9.5 5 9.5C5.41421 9.5 5.75 9.16421 5.75 8.75V5.5H9C9.41421 5.5 9.75 5.16421 9.75 4.75C9.75 4.33579 9.41421 4 9 4H5.75V0.75Z"
          fill="#C2C6C9"
        />
      </g>
      <defs>
        <clipPath id="clip0_427_2290">
          <rect width="10" height="10" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default PlusMediumDisabled;
