import React from 'react';
import ButtonV2 from './index';
import Grid from '../Grid';

type StackedButtonProps = {
  direction: string;
  onPrimaryClick: () => any;
  onSecondaryClick?: () => any;
  primaryLabel?: string;
  secondaryLabel?: string;
  primaryDisabled?: boolean;
  hideSecondary?: boolean;
};

const StackedButtons = ({
  direction,
  primaryLabel,
  onPrimaryClick,
  primaryDisabled,
  // Technically the "secondary" button may be a tertiary button type.
  secondaryLabel,
  onSecondaryClick,
}: StackedButtonProps) => {
  // We only display the button if the onClick handler is defined
  const showSecondaryButton = !!onSecondaryClick;
  return (
    <>
      {direction === 'vertical' && (
        <Grid container direction="column" spacing={2}>
          <Grid item xs={12}>
            <ButtonV2
              fullWidth
              onClick={onPrimaryClick}
              disabled={primaryDisabled}
            >
              {primaryLabel}
            </ButtonV2>
          </Grid>
          {showSecondaryButton && (
            <Grid item xs={12}>
              <ButtonV2 fullWidth type="tertiary" onClick={onSecondaryClick}>
                {secondaryLabel}
              </ButtonV2>
            </Grid>
          )}
        </Grid>
      )}

      {direction === 'horizontal' && (
        <Grid container direction="row" spacing={2}>
          {showSecondaryButton && (
            <>
              <Grid item xs={6}>
                <ButtonV2 fullWidth type="secondary" onClick={onSecondaryClick}>
                  {secondaryLabel}
                </ButtonV2>
              </Grid>
            </>
          )}
          <Grid item xs={showSecondaryButton ? 6 : 12}>
            <ButtonV2
              fullWidth
              onClick={onPrimaryClick}
              disabled={primaryDisabled}
            >
              {primaryLabel}
            </ButtonV2>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default StackedButtons;
