import * as React from 'react';

const SvgComponent = (props: any) => (
  <svg
    width={31}
    height={30}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M28.057 1.502a1 1 0 0 1 .878 1.356L20.193 27.83a1 1 0 0 1-1.858.076l-4.844-10.898-10.898-4.844a1 1 0 0 1 .076-1.858l24.982-8.744a.998.998 0 0 1 .405-.06Zm-3.862 3.39L5.71 11.36l8.318 3.696L24.195 4.891Zm-8.752 11.58L25.608 6.306 19.14 24.789l-3.696-8.317Z"
      fill="#373634"
    />
  </svg>
);

export default SvgComponent;
