import React from 'react';

const RefreshLeftArrow = (props: any) => {
  const { fill, ...rest } = props;

  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <g clipPath="url(#clip0_2327_19422)">
        <path
          d="M1.5 4V10H7.5"
          stroke="#9F7058"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M23.5 20V14H17.5"
          stroke="#9F7058"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M20.99 9.00001C20.4828 7.5668 19.6209 6.28542 18.4845 5.27543C17.3482 4.26545 15.9745 3.55978 14.4917 3.22427C13.0089 2.88877 11.4652 2.93436 10.0048 3.35679C8.54437 3.77922 7.21475 4.56473 6.14 5.64001L1.5 10M23.5 14L18.86 18.36C17.7853 19.4353 16.4556 20.2208 14.9952 20.6432C13.5348 21.0657 11.9911 21.1113 10.5083 20.7758C9.02547 20.4402 7.6518 19.7346 6.51547 18.7246C5.37913 17.7146 4.51717 16.4332 4.01 15"
          stroke="#9F7058"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2327_19422">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RefreshLeftArrow;
