import * as React from "react"

const SvgComponent = (props: any) => (
    <svg
        width={12}
        height={12}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0 6c0-3.318 2.682-6 6-6s6 2.682 6 6-2.682 6-6 6-6-2.682-6-6Zm8.154 3L9 8.154 6.846 6 9 3.846 8.154 3 6 5.154 3.846 3 3 3.846 5.154 6 3 8.154 3.846 9 6 6.846 8.154 9Z"
            fill="#fff"
        />
    </svg>
)

export default SvgComponent
