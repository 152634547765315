import React from 'react';

function BlueStarOff(props: any) {
  return (
    <svg
      width="15"
      height="16"
      viewBox="0 0 15 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.3285 14.75L7.5 12.0997L11.6715 14.75L10.5645 9.755L14.25 6.39421L9.39675 5.96079L7.5 1.25L5.60325 5.96079L0.75 6.39421L4.4355 9.755L3.3285 14.75ZM9.98657 12.3467L7.5 10.7669L5.01342 12.3467L5.67477 9.36257L3.39881 7.28714L6.38952 7.02005L7.5 4.26204L8.61048 7.02005L11.6012 7.28714L9.32523 9.36257L9.98657 12.3467Z"
        fill="#3C5D62"
      />
    </svg>
  );
}

export default BlueStarOff;
