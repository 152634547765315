import React from 'react';

function SvgConnectPeople() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        fill="#373634"
        fillRule="evenodd"
        d="M20 16.918c0-3.76-2.125-6.963-5.075-8.131a4.77 4.77 0 002.206-4.032c0-2.622-2.095-4.755-4.67-4.755-1.764 0-3.336.989-4.135 2.556a4.563 4.563 0 00-.787-.073c-2.575 0-4.67 2.133-4.67 4.755 0 1.7.884 3.191 2.206 4.032C2.125 12.438 0 15.64 0 19.4V20h15.078v-.599a9.76 9.76 0 00-.184-1.884H20v-.599zM4.045 7.238c0-1.961 1.567-3.557 3.494-3.557a3.414 3.414 0 011.193.218l.021.008a3.52 3.52 0 01.52.248l.03.017.023.015c.304.185.576.414.809.679l.007.008.014.016c.056.064.11.131.16.2l.02.024c.209.286.376.603.494.941l.009.024a3.54 3.54 0 01.068.225l.032.128a3.518 3.518 0 01.062.348l.004.03c.004.033.009.066.012.1a3.6 3.6 0 01-.001.67c-.006.06-.015.12-.025.18l-.004.028-.006.043-.012.075c-.018.093-.04.185-.065.277L10.9 8.2a3.747 3.747 0 01-.105.319l-.004.01a.37.37 0 01-.005.014l-.038.1c-.017.039-.035.076-.053.114l-.02.04a3.5 3.5 0 01-.113.217l-.002.003-.004.008c-.06.104-.125.203-.193.3l-.017.023a2.909 2.909 0 01-.128.169l-.022.026a3.444 3.444 0 01-.174.195l-.008.008-.012.013a3.488 3.488 0 01-1.757.963l-.006.002h-.007c-.097.02-.196.035-.296.047l-.075.008a3.39 3.39 0 01-.322.017h-.012c-.023-.003-.047-.005-.07-.005h-.013c-1.882-.052-3.4-1.624-3.4-3.553zm9.835 11.564a8.416 8.416 0 00-.263-1.583l-.038-.136a8.19 8.19 0 00-.666-1.64 7.698 7.698 0 00-.638-.983l-.044-.056a7.23 7.23 0 00-.343-.405l-.07-.075a6.766 6.766 0 00-.35-.344l-.015-.014a6.52 6.52 0 00-.713-.564 5.741 5.741 0 00-.428-.266 6.158 6.158 0 00-.232-.124l-.009-.005-.143-.071a5.784 5.784 0 00-.457-.192l-.02-.007a5.508 5.508 0 00-.394-.13l-.045-.013a5.676 5.676 0 00-.405-.094l-.03-.006-.058-.012a5.719 5.719 0 00-.433-.06l-.064-.006-.023-.001a5.537 5.537 0 00-.46-.022 1.233 1.233 0 01-.1.006l-.212.003c-3.193.18-5.775 3.114-6.03 6.8H13.88z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default SvgConnectPeople;
