import Container from '../Container';
import styled from '../styled';

// The buttons appear on a fixed bar at the base of the page.

const FooterButtonBar = styled(Container)({
  position: 'fixed',
  bottom: '0px',
  left: '0px',
  maxWidth: 'unset !important',
  backgroundColor: 'white',
  padding: '8px 16px !important',
  boxShadow: '0px -2px 10px rgba(0, 0, 0, 0.1)',
});

export default FooterButtonBar;
