import * as React from 'react';

const SvgComponent = (props: any) => (
  <svg
    width={17}
    height={16}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8.5 14.667a6.667 6.667 0 1 0 0-13.334 6.667 6.667 0 0 0 0 13.334ZM1.833 8h13.334"
      stroke={props.color || '#373634'}
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.5 1.333A10.2 10.2 0 0 1 11.167 8 10.2 10.2 0 0 1 8.5 14.667 10.2 10.2 0 0 1 5.833 8 10.2 10.2 0 0 1 8.5 1.333v0Z"
      stroke={props.color || '#373634'}
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SvgComponent;
