import React from 'react';

function BlankAvatar(props: any) {
  return (
    <svg
      width="100"
      height="100"
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width="100" height="100" rx="1" fill="#E9EAE9" />
      <rect
        x="10.9375"
        y="10.9375"
        width="78.125"
        height="78.125"
        rx="39.0625"
        fill="#C2C6C9"
      />
      <mask
        id="mask0_1196:67778"
        maskUnits="userSpaceOnUse"
        x="10"
        y="10"
        width="80"
        height="80"
      >
        <circle cx="50" cy="50" r="39.0625" fill="#88898A" />
      </mask>
      <g mask="url(#mask0_1196:67778)">
        <ellipse
          cx="50.1422"
          cy="46.6154"
          rx="14.5251"
          ry="14.525"
          fill="#FAFAFA"
        />
        <path
          d="M50.1402 90.1903C34.3254 90.1903 27.737 83.3365 24.131 79.313C23.9049 79.0608 23.8194 78.7126 23.9262 78.3912C26.3854 70.9916 38.3165 62.7794 41.5463 62.42C41.7257 62.4 41.8979 62.4628 42.0517 62.5571C43.2298 63.279 46.1415 64.6657 50.1402 64.6657C53.563 64.6657 56.3088 63.7426 57.7803 62.942C58.3237 62.6464 58.9565 62.484 59.551 62.6547C68.383 65.1896 73.9734 73.9294 75.9674 78.556C76.132 78.9378 76.0298 79.3724 75.7163 79.6455C71.8674 82.9993 62.1167 90.1903 50.1402 90.1903Z"
          fill="#FAFAFA"
        />
      </g>
    </svg>
  );
}

export default BlankAvatar;
