import { Theme } from '@material-ui/core/styles';

export const styles = (theme: Theme) => ({
  popper: {
    opacity: '0.9',
  },
  tooltipArrow: {
    fontFamily: theme.bodyFontFamily,
    fontSize: '11px',
    fontWeight: 'bold',
    fontStyle: 'normal',
    lineHeight: '16px',
    textAlign: 'center',
    backgroundColor: theme.palette.primary.main,
    maxWidth: '231px',
    wordWrap: 'break-word',
    padding: '12px 18px',
  },
  arrow: {
    fontSize: 15,
    color: theme.palette.primary.main,
  },
});
