import * as React from 'react';
import { TextFieldProps } from '@material-ui/core/TextField';
import InputAdornment from '/InputAdornment';
import { SearchInput, Label } from './input.styles';

type InputContainerProps = TextFieldProps & {
  adornmentIcon?: any;
  startAdornmentIcon?: any;
};

export default function SearchInputContainer(props: InputContainerProps) {
  const { label, adornmentIcon, startAdornmentIcon, ...rest } = props;
  const InputProps = {} as InputContainerProps;
  if (adornmentIcon) {
    InputProps.endAdornment = (
      <InputAdornment position="end">{adornmentIcon}</InputAdornment>
    );
  }
  if (startAdornmentIcon) {
    InputProps.startAdornment = (
      <InputAdornment position="start">{startAdornmentIcon}</InputAdornment>
    );
  }
  return (
    <React.Fragment>
      {label && <Label htmlFor={props.name}>{label}</Label>}
      <SearchInput
        fullWidth
        variant="outlined"
        InputProps={InputProps}
        {...rest}
      />
    </React.Fragment>
  );
}
