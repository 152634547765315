import * as React from 'react';

const SvgComponent = (props: any) => (
  <svg
    width={18}
    height={18}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g
      opacity={0.8}
      strokeLinecap="round"
      strokeLinejoin="round"
      className="icon"
    >
      <path
        d="M10.5 1.5h-6A1.5 1.5 0 0 0 3 3v12a1.5 1.5 0 0 0 1.5 1.5h9A1.5 1.5 0 0 0 15 15V6l-4.5-4.5Z"
        stroke="#fff"
        strokeWidth={1.5}
      />
      <path
        d="M10.5 1.5V6H15M12 9.75H6M12 12.75H6"
        stroke="#fff"
        strokeWidth={1.5}
      />
      <path d="M7.5 6.75H6" stroke="#373634" />
    </g>
  </svg>
);

export default SvgComponent;
