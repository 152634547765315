import React from 'react';
import makeStyles from '../makeStyles';
import ThemeType from '../theme/ThemeType';

interface SpacerProps {
  xs: string;
  sm: string;
  md?: string;
  lg: string;
  xl?: string;
}

const useStyles = makeStyles((theme: ThemeType) => ({
  spacer: (props: SpacerProps) => ({
    paddingTop: props.xs,
    [theme.breakpoints.up('sm')]: { paddingTop: props.sm },
    [theme.breakpoints.up('md')]: { paddingTop: props.md || props.sm },
    [theme.breakpoints.up('lg')]: { paddingTop: props.lg },
    [theme.breakpoints.up('xl')]: { paddingTop: props.xl || props.lg },
  }),
}));

const Spacer = ({ xs, sm, md, lg, xl }: SpacerProps) => {
  const classes = useStyles({ xs, sm, md, lg, xl });

  return <div className={classes.spacer} />;
};

export default Spacer;
