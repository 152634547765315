import React from 'react';

const NavOHLogo = (props: any) => {
  const { fill, ...rest } = props;
  return (
    <svg
      width="140"
      height="14"
      viewBox="0 0 140 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M6.91697 1.38226C5.98966 1.35314 5.075 1.60345 4.29173 2.10071C3.57349 2.58018 3.00591 3.25346 2.65484 4.04246C2.26805 4.9157 2.07464 5.86224 2.08785 6.81721C2.07817 7.76619 2.27356 8.70606 2.66066 9.57255C3.0104 10.357 3.57611 11.0258 4.29173 11.5007C5.07626 11.9909 5.98846 12.2375 6.91309 12.2094C7.83786 12.2387 8.75039 11.9919 9.53445 11.5007C10.2511 11.0253 10.8186 10.3568 11.1713 9.57255C11.5572 8.70448 11.7506 7.7632 11.7383 6.81333C11.7515 5.85836 11.5581 4.91181 11.1713 4.03857C10.8129 3.21833 10.2148 2.52568 9.45551 2.0516C8.69622 1.57753 7.8113 1.3442 6.91697 1.38226ZM6.91697 13.4211C5.51309 13.4211 4.28785 13.1221 3.27425 12.5357C2.28442 11.9749 1.48047 11.1365 0.961632 10.124C0.442112 9.10177 0.17574 7.96969 0.184932 6.82304C0.173352 5.66744 0.439735 4.52599 0.961632 3.49488C1.48138 2.48271 2.28418 1.64347 3.27231 1.07935C4.2859 0.485174 5.51309 0.184204 6.91503 0.184204C8.31697 0.184204 9.54416 0.485174 10.5597 1.07935C11.5478 1.64356 12.3505 2.48277 12.8704 3.49488C13.3881 4.52713 13.651 5.66833 13.6374 6.82304C13.6523 7.97032 13.3856 9.10375 12.8607 10.124C12.3297 11.1341 11.5213 11.9714 10.5306 12.5376C9.42984 13.1537 8.18385 13.4628 6.9228 13.4327"
        fill="#373634"
      />
      <path
        d="M21.3961 13.1337V12.3881L21.6388 12.2793C21.7759 12.208 21.8901 12.0995 21.9684 11.9662C22.0467 11.833 22.0859 11.6804 22.0816 11.5259V2.01331C21.9959 1.87515 21.8978 1.74516 21.7883 1.62496C21.6772 1.51639 21.533 1.44805 21.3786 1.43079L21 1.40166V0.452148H22.8097L22.932 0.593896C23.1294 0.838475 23.3073 1.09817 23.4641 1.37059C23.5223 1.46574 23.5728 1.55118 23.6155 1.61526C24.5981 3.09681 26.4816 4.58419 29.2136 6.03273C30.7404 6.83958 32.1092 7.91503 33.2544 9.20749V2.06768C33.2588 1.91296 33.2194 1.76015 33.1407 1.62686C33.062 1.49356 32.9473 1.38521 32.8097 1.31428L32.5689 1.20555V0.459916H35.7476V1.20555L35.5049 1.31428C35.3675 1.3853 35.253 1.49373 35.1747 1.62704C35.0964 1.76036 35.0573 1.91313 35.0621 2.06768V13.1356H33.7204L33.6447 12.8153C33.6447 12.7881 32.8466 9.72011 28.3243 7.2638C26.4718 6.29293 24.9845 5.28322 23.8893 4.26768V11.5279C23.8848 11.6826 23.924 11.8355 24.0023 11.9691C24.0805 12.1027 24.1948 12.2115 24.332 12.2832L24.5748 12.392V13.1376L21.3961 13.1337Z"
        fill="#373634"
      />
      <path
        d="M54.6293 13.1337H44.1924V12.3901L44.4331 12.2794C44.5697 12.2081 44.6834 12.0998 44.7614 11.9669C44.8393 11.834 44.8783 11.6819 44.8739 11.5279V2.06967C44.8784 1.91491 44.8393 1.76202 44.761 1.62845C44.6827 1.49488 44.5684 1.38603 44.4312 1.31433L44.1885 1.20559V0.459961H54.6254L54.8331 2.6852H54.0564L53.9458 2.44054C53.8137 2.13957 53.4895 1.8153 53.1982 1.8153H46.6739V4.93763H49.7632C50.0526 4.93763 50.3768 4.61336 50.5108 4.31433L50.6196 4.06967H51.3555V7.16093H50.6176L50.505 6.91627C50.3729 6.61724 50.0487 6.29297 49.7574 6.29297H46.6739V11.7784H53.2001C53.4895 11.7784 53.8157 11.4541 53.9477 11.1532L54.0584 10.9085H54.8351L54.6293 13.1337Z"
        fill="#373634"
      />
      <path
        d="M71.6894 13.1337H68.5224V12.3901L68.7632 12.2794C68.9006 12.2084 69.015 12.0999 69.0934 11.9666C69.1717 11.8333 69.2107 11.6805 69.2059 11.526V2.06967C69.2103 1.91422 69.1705 1.76071 69.0911 1.62699C69.0117 1.49328 68.896 1.38486 68.7574 1.31433L68.5166 1.20559V0.459961H71.6894V1.20559L71.4486 1.31433C71.3109 1.38558 71.1961 1.49428 71.1174 1.62791C71.0388 1.76154 70.9994 1.91467 71.004 2.06967V5.38229C72.6041 5.33164 74.2028 5.51946 75.7477 5.93957C77.5321 6.44443 79.0894 6.70268 80.3865 6.70462V2.06967C80.3915 1.91483 80.3525 1.76175 80.2742 1.62809C80.1958 1.49444 80.0813 1.38567 79.9438 1.31433L79.7011 1.20559V0.459961H82.87V1.20559L82.6292 1.31433C82.4917 1.38567 82.3772 1.49444 82.2988 1.62809C82.2205 1.76175 82.1816 1.91483 82.1865 2.06967V11.5279C82.1816 11.6828 82.2205 11.8358 82.2988 11.9695C82.3772 12.1032 82.4917 12.2119 82.6292 12.2833L82.87 12.392V13.1357H79.6933V12.392L79.936 12.2833C80.0735 12.2119 80.1881 12.1032 80.2664 11.9695C80.3447 11.8358 80.3837 11.6828 80.3787 11.5279V8.04831C78.9826 8.07355 77.3185 7.79394 75.4176 7.25414C73.98 6.86393 72.493 6.68664 71.004 6.72792V11.5279C70.9994 11.6829 71.0388 11.8361 71.1174 11.9697C71.1961 12.1033 71.3109 12.212 71.4486 12.2833L71.6894 12.392V13.1337Z"
        fill="#373634"
      />
      <path
        d="M96.631 1.3823C95.7057 1.35435 94.7932 1.60462 94.0116 2.10074C93.2934 2.58021 92.7258 3.2535 92.3747 4.04249C91.988 4.91573 91.7945 5.86227 91.8078 6.81725C91.7957 7.7671 91.9891 8.70834 92.3747 9.57647C92.7255 10.3614 93.2926 11.0302 94.0097 11.5046C94.8052 11.9692 95.7098 12.214 96.631 12.214C97.5523 12.214 98.4569 11.9692 99.2524 11.5046C99.9695 11.0298 100.537 10.3612 100.889 9.57647C101.275 8.7084 101.469 7.76712 101.456 6.81725C101.47 5.86227 101.276 4.91573 100.889 4.04249C100.531 3.22193 99.9323 2.52906 99.1727 2.05496C98.413 1.58086 97.5276 1.34772 96.633 1.38618L96.631 1.3823ZM96.633 13.425C95.3618 13.4574 94.1048 13.1519 92.9903 12.5396C92.0007 11.9793 91.1968 11.1416 90.6777 10.1299C90.1624 9.10645 89.8994 7.97464 89.9107 6.8289C89.897 5.67419 90.16 4.533 90.6777 3.50074C91.1974 2.48857 92.0002 1.64933 92.9883 1.08521C94.0039 0.491034 95.2291 0.190063 96.633 0.190063C98.0369 0.190063 99.2602 0.491034 100.276 1.08521C101.264 1.64942 102.067 2.48864 102.586 3.50074C103.105 4.53269 103.368 5.6741 103.353 6.8289C103.368 7.97618 103.102 9.10962 102.577 10.1299C102.046 11.1404 101.238 11.9779 100.247 12.5435C99.146 13.1598 97.8999 13.469 96.6388 13.4386"
        fill="#373634"
      />
      <path
        d="M113.439 7.6191H116.446C116.711 7.62018 116.975 7.6007 117.237 7.56084C117.619 7.50829 117.99 7.39351 118.336 7.22104C118.804 6.99452 119.197 6.6379 119.468 6.19385C119.71 5.78091 119.844 5.31325 119.856 4.83463V4.65793C119.847 4.17508 119.714 3.70274 119.468 3.28706C119.197 2.84252 118.803 2.48581 118.334 2.25987C117.989 2.08856 117.618 1.97383 117.237 1.92007C117.14 1.90259 117.052 1.88706 116.965 1.87735L116.87 1.86764H116.771L116.611 1.85599H113.437L113.439 7.6191ZM110.955 13.1337V12.388L111.196 12.2793C111.334 12.208 111.448 12.0993 111.527 11.9657C111.606 11.8321 111.645 11.679 111.641 11.524V2.06764C111.645 1.91292 111.606 1.76011 111.527 1.62682C111.448 1.49352 111.333 1.38517 111.196 1.31424L110.955 1.2055V0.459874H117.423C118.179 0.456837 118.923 0.652538 119.58 1.02739C120.237 1.40224 120.784 1.94309 121.167 2.5958C121.549 3.24271 121.75 3.98041 121.749 4.73172C121.75 5.48303 121.549 6.22073 121.167 6.86764C120.787 7.51472 120.244 8.05048 119.592 8.42104C118.932 8.79937 118.184 8.99633 117.423 8.99191H113.439V11.5278C113.434 11.6827 113.473 11.8358 113.551 11.9694C113.629 12.1031 113.744 12.2118 113.881 12.2832L114.124 12.3919V13.1375L110.955 13.1337Z"
        fill="#373634"
      />
      <path
        d="M139.792 13.1337H129.354V12.3901L129.596 12.2794C129.734 12.2085 129.849 12.1001 129.927 11.9668C130.006 11.8335 130.045 11.6807 130.041 11.526V2.06967C130.045 1.91467 130.006 1.76154 129.927 1.62791C129.849 1.49428 129.734 1.38558 129.596 1.31433L129.354 1.20559V0.459961H139.792L140 2.6852H139.223L139.113 2.44054C138.979 2.13957 138.66 1.81918 138.363 1.81918H131.839V4.93763H134.926C135.218 4.93763 135.542 4.61336 135.674 4.31433L135.785 4.06967H136.524V7.16093H135.787L135.678 6.91627C135.546 6.61724 135.221 6.29297 134.93 6.29297H131.837V11.7784H138.363C138.654 11.7784 138.979 11.4541 139.111 11.1532L139.221 10.9085H139.998L139.792 13.1337Z"
        fill="#373634"
      />
    </svg>
  );
};

export default NavOHLogo;
