import React from "react";

function Icon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="34"
            height="30"
            fill="none"
            viewBox="0 0 34 30"
        >
            <path
                fill="#373634"
                d="M16.223 12.685a.665.665 0 00-.665.666v.878c-.062.013-.129.017-.188.035a1.934 1.934 0 00-1.38 2.045c.162 1.199 1.232 1.573 2.013 1.847.333.089.647.237.927.437a.618.618 0 01.209.456.6.6 0 01-.22.446c-.211.174-.476.27-.75.272a4.65 4.65 0 01-1.036-.093 2.803 2.803 0 01-.275-.062.666.666 0 10-.384 1.275c.139.04.28.072.421.098.24.044.456.072.662.09v.825a.666.666 0 001.332 0v-.923c.341-.091.657-.26.922-.493a1.912 1.912 0 00.036-2.856c-.41-.34-.889-.59-1.404-.729-.75-.263-1.085-.415-1.133-.768a.602.602 0 01.438-.59 2.335 2.335 0 011.422.14.666.666 0 10.492-1.238 4.153 4.153 0 00-.773-.215v-.877a.665.665 0 00-.666-.666zM33.451 4.862a5.444 5.444 0 00-1.276-2.671A4.784 4.784 0 0028.483.6h-.186a4.928 4.928 0 00-3.277 1.287 5.034 5.034 0 00-.717-.526 5.404 5.404 0 00-3.278-.713A4.747 4.747 0 0017.7 2.447a5.779 5.779 0 00-1.211 3.704 6.496 6.496 0 001.102 3.37 12.631 12.631 0 002.487 2.713 23.413 23.413 0 004.162 2.869c.233.128.494.195.76.197a1.64 1.64 0 00.781-.209 23.798 23.798 0 003.875-2.64 13.554 13.554 0 002.782-3.003 5.993 5.993 0 001.014-4.586zM31.584 28.068H.865a.666.666 0 000 1.332h30.72a.666.666 0 100-1.332z"
            ></path>
            <path
                fill="#373634"
                d="M9.264 26.277a.666.666 0 00.49-1.115 9.04 9.04 0 01-2.36-6.028 8.824 8.824 0 018.32-8.811.666.666 0 00-.074-1.33 10.157 10.157 0 00-9.577 10.14 10.386 10.386 0 002.71 6.928.663.663 0 00.49.216zM22.738 26.102a.668.668 0 00.94-.041 10.243 10.243 0 002.706-6.927c0-.772-.088-1.54-.263-2.293a.666.666 0 00-1.297.303c.152.652.228 1.32.228 1.99a8.915 8.915 0 01-2.355 6.028.664.664 0 00.041.94z"
            ></path>
        </svg>
    );
}

export default Icon;
