import React from 'react';
import ThemeType from '../theme/ThemeType';
import makeStyles from '../makeStyles';
import Text from '../Text';

export enum SplitScreenPresets {
  DOING_GOOD_TASTES_GREAT = 'doing good tastes great',
  BE_YOUR_OWN_BOSS = 'be your own boss',
  ORDER_CONFIRMATION = 'order confirmed',
}

const useStyles = makeStyles<ThemeType, { type?: string }>(
  (theme: ThemeType) => ({
    overlayText: ({ type }) => ({
      color:
        type === SplitScreenPresets.DOING_GOOD_TASTES_GREAT
          ? theme.palette.white
          : theme.palette.primary.main,
      fontFamily: theme.bodyFontFamily,
      fontSize: '44px',
      fontWeight: 'bold',
      letterSpacing: '-0.5px',
      lineHeight: '1.32',

      '.bottom-left': {
        position: 'absolute',
        left: '40px',
        bottom: '104px',
      },

      '.top-center ': {
        paddingTop: '40px',
        textAlign: 'center',
      },
    }),
    beYourOwnBossText: {
      color: theme.palette.primary.main,
      fontFamily: theme.bodyFontFamily,
      fontSize: '44px',
      fontWeight: 'bold',
      letterSpacing: '-0.5px',
      lineHeight: '1.32',
    },
  }),
);

export function SplitScreenTextPreset({ type }: { type: string }) {
  const { overlayText } = useStyles({ type });
  if (!type) {
    return null;
  }

  switch (type) {
    case SplitScreenPresets.DOING_GOOD_TASTES_GREAT:
      return (
        <Text className={overlayText}>
          <div className="bottom-left">
            <div>Doing good</div>
            <div>tastes great.</div>
          </div>
        </Text>
      );
    case SplitScreenPresets.BE_YOUR_OWN_BOSS:
      return (
        <Text className={overlayText}>
          <div className="top-center">Be your own boss.</div>
        </Text>
      );
    default:
      return null;
  }
}

export const presetImages = {
  [SplitScreenPresets.DOING_GOOD_TASTES_GREAT]: '201903loginpic_d.jpg',
  [SplitScreenPresets.BE_YOUR_OWN_BOSS]: 'beyourownboss-2x.jpg',
  [SplitScreenPresets.ORDER_CONFIRMATION]: 'order_confirmation.png',
} as { [name: string]: string };
