// Font styles supported by design system https://www.figma.com/file/MN003Adg7ffx5HAtL74BdG/Design-System?node-id=4161%3A103601
// Note: Mackenzie sometimes uses Tiny and Body interchangeably. This supports both but we should try to improve the Figma naming convention

// All the names should follow a format for easier parsing
// Assumption is that the order remains:
//  fontSize: number, doesn't exist for btn currently.
//  type: body, tiny, btn, accent
//  fontWeight: [bold, semibold, regular, light] ... if none found it's regular, like link and accent? and then if its decoration is link that handles weight separately later.
//  decoration: [italic, link, strike]
// TODO(gemma): Now that we aren't copy pasting from figma i could reorganize the parse order
// But it would just mean the one-off styles copy paste from figma wouldn't work.
// TODO(gemma): Meet with mackenzie, could we start with the type like body 22 and accent 22? since that informs the rest.
// We could also do it as the second word if that really matters to her, just that BTN doesnt follow the same conventino.
// We could reorder so it's like REGULAR BTN or LARGE BTN if she doesnt want numbers and wants to keep the order? using regular as a size and weight isn't great for us though.

const TEXT_BODY = {
  BODY_BOLD_22: '22 Body Bold',
  BODY_BOLD_20: '20 Body Bold',
  BODY_BOLD_24: '24 Body Bold',
  BODY_BOLD_18: '18 Body Bold',
  BODY_BOLD_16: '16 Body Bold',
  BODY_BOLD_14: '14 Body Bold',
  BODY_BOLD_12: '12 Body Bold',
  BODY_BOLD_11: '11 Body Bold',
  TINY_BOLD_12: '12 Tiny Bold',
  TINY_BOLD_11: '11 Tiny Bold',
  BODY_SEMIBOLD_22: '22 Body Semibold',
  BODY_SEMIBOLD_20: '20 Body Semibold',
  BODY_SEMIBOLD_18: '18 Body Semibold',
  BODY_SEMIBOLD_16: '16 Body Semibold',
  BODY_SEMIBOLD_14: '14 Body Semibold',
  BODY_SEMIBOLD_12: '12 Body Semibold',
  BODY_SEMIBOLD_11: '11 Body Semibold',
  TINY_SEMIBOLD_12: '12 Tiny Semibold',
  TINY_SEMIBOLD_11: '11 Tiny Semibold',
  BODY_REGULAR_22: '22 Body Regular',
  BODY_REGULAR_20: '20 Body Regular',
  BODY_REGULAR_18: '18 Body Regular',
  BODY_REGULAR_16: '16 Body Regular',
  BODY_REGULAR_14: '14 Body Regular',
  BODY_REGULAR_12: '12 Body Regular',
  BODY_REGULAR_11: '11 Body Regular',
  TINY_REGULAR_12: '12 Tiny Regular',
  TINY_REGULAR_11: '11 Tiny Regular',
  BODY_LIGHT_22: '22 Body Light',
  BODY_LIGHT_20: '20 Body Light',
  BODY_LIGHT_18: '18 Body Light',
  BODY_LIGHT_16: '16 Body Light',
  BODY_LIGHT_14: '14 Body Light',
  TINY_LIGHT_12: '12 Tiny Light',
  TINY_LIGHT_11: '11 Tiny Light',
  BODY_LINK_22: '22 Body Link',
  BODY_LINK_20: '20 Body Link',
  BODY_LINK_18: '18 Body Link',
  BODY_LINK_16: '16 Body Link',
  BODY_LINK_14: '14 Body Link',
  BODY_LINK_12: '12 Body Link',
  BODY_LINK_11: '11 Body Link',
  TINY_LINK_12: '12 Tiny Link',
  TINY_LINK_11: '11 Tiny Link',
  BODY_SEMIBOLD_ITALIC_22: '22 Body Semibold Italic',
  BODY_SEMIBOLD_ITALIC_20: '20 Body Semibold Italic',
  BODY_SEMIBOLD_ITALIC_18: '18 Body Semibold Italic',
  BODY_SEMIBOLD_ITALIC_16: '16 Body Semibold Italic',
  BODY_SEMIBOLD_ITALIC_14: '14 Body Semibold Italic',
  BODY_SEMIBOLD_ITALIC_12: '12 Body Semibold Italic',
  BODY_SEMIBOLD_ITALIC_11: '11 Body Semibold Italic',
  TINY_SEMIBOLD_ITALIC_12: '12 Tiny Semibold Italic',
  TINY_SEMIBOLD_ITALIC_11: '11 Tiny Semibold Italic',
  BODY_LIGHT_ITALIC_22: '22 Body Light Italic',
  BODY_LIGHT_ITALIC_20: '20 Body Light Italic',
  BODY_LIGHT_ITALIC_18: '18 Body Light Italic',
  BODY_LIGHT_ITALIC_16: '16 Body Light Italic',
  BODY_LIGHT_ITALIC_14: '14 Body Light Italic',
  BODY_LIGHT_ITALIC_12: '12 Body Light Italic',
  BODY_LIGHT_ITALIC_11: '11 Body Light Italic',
  TINY_LIGHT_ITALIC_12: '12 Tiny Light Italic',
  TINY_LIGHT_ITALIC_11: '11 Tiny Light Italic',
  BODY_REGULAR_STRIKE_22: '22 Body Regular Strike',
  BODY_REGULAR_STRIKE_20: '20 Body Regular Strike',
  BODY_REGULAR_STRIKE_18: '18 Body Regular Strike',
  BODY_REGULAR_STRIKE_16: '16 Body Regular Strike',
  BODY_REGULAR_STRIKE_14: '14 Body Regular Strike',
  BODY_REGULAR_STRIKE_12: '12 Body Regular Strike',
  BODY_REGULAR_STRIKE_11: '11 Body Regular Strike',
  TINY_REGULAR_STRIKE_12: '12 Tiny Regular Strike',
  TINY_REGULAR_STRIKE_11: '11 Tiny Regular Strike',
  BODY_SEMIBOLD_STRIKE_22: '22 Body Semibold Strike',
  BODY_SEMIBOLD_STRIKE_20: '20 Body Semibold Strike',
};

const TEXT_ACCENT = {
  ACCENT_JUNIPER_48: '48 Accent Juniper',
  ACCENT_JUNIPER_64: '64 Accent Juniper',
  ACCENT_JUNIPER_96: '96 Accent Juniper',
};

// These are ordered to match figma "Headings" display order.
const TEXT_HEADING = {
  TITLE_LIGHT_72: '72 Title Light',
  TITLE_LIGHT_60: '60 Title Light',
  TITLE_LIGHT_44: '44 Title Light',
  TITLE_THIN_STRIKE_44: '44 Title Thin Strike',
  TITLE_LIGHT_32: '32 Title Light',
  TITLE_THIN_STRIKE_32: '32 Title Thin Strike',
  TITLE_LIGHT_ITALIC_32: '32 Title Light Italic',
  TITLE_LIGHT_24: '24 Title Light',
  TITLE_THIN_STRIKE_24: '24 Title Thin Strike',
  TITLE_UPPER_28: '28 Title Upper', // Light is default for Title
  TITLE_UPPER_22: '22 Title Upper', // Light is default for Title
  TITLE_UPPER_20: '20 Title Upper', // Light is default for Title
  TITLE_LIGHT_20: '20 Title Light',
  TITLE_LIGHT_18: '18 Title Light',
  TITLE_THIN_STRIKE_18: '18 Title Thin Strike',
  OVERLINE_SEMIBOLD_18: '18 Overline Semibold',
  OVERLINE_SEMIBOLD_16: '16 Overline Semibold',
  OVERLINE_REGULAR_16: '16 Overline Regular',
  OVERLINE_BOLD_14: '14 Overline Bold',
  OVERLINE_SEMIBOLD_14: '14 Overline Semibold',
  OVERLINE_REGULAR_14: '14 Overline Regular',
  OVERLINE_REGULAR_STRIKE_14: '14 Overline Regular Strike',
  OVERLINE_BOLD_12: '12 Overline Semibold',
  OVERLINE_SEMIBOLD_12: '12 Overline Semibold',
  OVERLINE_REGULAR_12: '12 Overline Regular',
  OVERLINE_LIGHT_12: '12 Overline Light',
  OVERLINE_BOLD_11: '11 Overline Bold',
  OVERLINE_SEMIBOLD_11: '11 Overline Semibold',
  OVERLINE_REGULAR_11: '11 Overline Regular',
};

export const TEXT_DS = {
  ...TEXT_BODY,
  ...TEXT_ACCENT,
  ...TEXT_HEADING,
};
