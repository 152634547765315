/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-no-bind */
import React from 'react';
import { TypographyProps } from '@material-ui/core/Typography';
import Linkify from 'react-linkify';
import Text from '../Text';

interface Props extends TypographyProps {
  text: string;
  component: string;
}

/**
 * Given a block of text, separate paragraphs by line breaks and linkify URLs.
 * A `<Text />` component will be rendered for each paragraph using the props passed to this component.
 * @param text
 */
export default function PrettifiedText({ text, ...typographyProps }: Props) {
  // trim whitespace and split into paragraphs
  const paragraphs = text.trim().split(/\n{2,}/g);

  return (
    <>
      {paragraphs.map((p, i) => (
        <Linkify
          componentDecorator={(decoratedHref, decoratedText, key) => (
            <a target="blank" href={decoratedHref} key={key}>
              {decoratedText}
            </a>
          )}
        >
          <Text {...typographyProps} key={i}>
            {p}
          </Text>
        </Linkify>
      ))}
    </>
  );
}
