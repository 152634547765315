import React from 'react';

const SortArrows = (props: any) => {
  const { fill, ...rest } = props;
  return (
    <svg
      width="40"
      height="44"
      viewBox="0 0 40 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12 16.4333L16.2857 12L20.5714 16.4333H17.3571V24.2222H15.2143V16.4333H12ZM24.7857 19.7778V27.5667H28L23.7143 32L19.4286 27.5667H22.6429V19.7778H24.7857Z"
        fill="#373634"
      />
    </svg>
  );
};

export default SortArrows;
