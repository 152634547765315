import React from 'react';

const Filter = (props: any) => {
  const { fill, ...rest } = props;
  return (
    <svg
      width="17"
      height="18"
      viewBox="0 0 17 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1.17245 3.60422H2.82167C3.15569 4.78454 4.22039 5.64871 5.47296 5.64871C6.72554 5.64871 7.79023 4.78454 8.12425 3.60422H15.8276C16.2451 3.60422 16.6 3.26698 16.6 2.82436C16.6 2.38173 16.266 2.0445 15.8276 2.0445H8.12425C7.79023 0.864169 6.72554 0 5.47296 0C4.22039 0 3.15569 0.864169 2.82167 2.0445H1.17245C0.754921 2.0445 0.400024 2.38173 0.400024 2.82436C0.400024 3.26698 0.734045 3.60422 1.17245 3.60422ZM5.47296 1.55972C6.141 1.55972 6.72554 2.10773 6.72554 2.82436C6.72554 3.54098 6.18276 4.08899 5.47296 4.08899C4.76317 4.08899 4.22039 3.49883 4.22039 2.82436C4.22039 2.14988 4.80492 1.55972 5.47296 1.55972ZM15.8276 8.22014H14.241C13.907 7.03981 12.8423 6.17564 11.5897 6.17564C10.3371 6.17564 9.27245 7.06089 8.91755 8.22014H1.17245C0.754921 8.22014 0.400024 8.55738 0.400024 9C0.400024 9.42155 0.734045 9.77986 1.17245 9.77986H8.91755C9.25157 10.9602 10.3163 11.8244 11.5688 11.8244C12.8214 11.8244 13.8861 10.9602 14.2201 9.77986H15.8067C16.2243 9.77986 16.5791 9.44262 16.5791 9C16.6 8.57846 16.266 8.22014 15.8276 8.22014ZM11.5897 10.2646C10.9217 10.2646 10.3371 9.71663 10.3371 9C10.3371 8.28337 10.8799 7.73536 11.5897 7.73536C12.2995 7.73536 12.8423 8.28337 12.8423 9C12.8423 9.71663 12.2578 10.2646 11.5897 10.2646ZM8.12425 14.3958H15.8276C16.266 14.3958 16.6 14.733 16.6 15.1756C16.6 15.6183 16.2451 15.9555 15.8276 15.9555H8.12425C7.79023 17.1358 6.72554 18 5.47296 18C4.22039 18 3.15569 17.1358 2.82167 15.9555H1.17245C0.734045 15.9555 0.400024 15.6183 0.400024 15.1756C0.400024 14.733 0.754921 14.3958 1.17245 14.3958H2.82167C3.15569 13.2155 4.22039 12.3513 5.47296 12.3513C6.72554 12.3513 7.79023 13.2155 8.12425 14.3958ZM4.22039 15.1756C4.22039 15.8923 4.80492 16.4403 5.47296 16.4403C6.141 16.4403 6.72554 15.8923 6.72554 15.1756C6.72554 14.459 6.18276 13.911 5.47296 13.911C4.76317 13.911 4.22039 14.459 4.22039 15.1756Z"
        fill="#373634"
      />
    </svg>
  );
};

export default Filter;
