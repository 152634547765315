import * as React from 'react';

const SvgComponent = (props: any) => (
  <svg
    width={30}
    height={30}
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M26.25 14.375C26.2543 16.0248 25.8688 17.6524 25.125 19.125C24.243 20.8897 22.8872 22.374 21.2093 23.4116C19.5314 24.4492 17.5978 24.9992 15.625 25C13.9752 25.0043 12.3476 24.6188 10.875 23.875L3.75 26.25L6.125 19.125C5.38116 17.6524 4.9957 16.0248 5 14.375C5.00076 12.4022 5.55076 10.4686 6.5884 8.79069C7.62603 7.11282 9.11032 5.75696 10.875 4.87501C12.3476 4.13117 13.9752 3.7457 15.625 3.75001H16.25C18.8554 3.89374 21.3163 4.99346 23.1614 6.83858C25.0065 8.6837 26.1063 11.1446 26.25 13.75V14.375Z"
      stroke="#373634"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SvgComponent;
