import React from 'react';

const RefreshRightArrow = (props: any) => {
  const { ...rest } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="21"
      fill="none"
      viewBox="0 0 20 21"
      {...rest}
    >
      <g
        stroke="#373634"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        clipPath="url(#clip0_64_28555)"
      >
        <path d="M19.167 3.833v5h-5M.833 17.167v-5h5"></path>
        <path d="M2.925 8A7.5 7.5 0 0115.3 5.2l3.867 3.633M.833 12.167L4.7 15.8A7.5 7.5 0 0017.075 13"></path>
      </g>
      <defs>
        <clipPath id="clip0_64_28555">
          <path
            fill="#fff"
            d="M0 0H20V20H0z"
            transform="translate(0 .5)"
          ></path>
        </clipPath>
      </defs>
    </svg>
  );
};

export default RefreshRightArrow;
