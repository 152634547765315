import React, { ReactNode, useState } from 'react';

import { styled } from '@mui/material/styles';
import { AccordionProps } from '@mui/material/Accordion';
import { AccordionDetailsProps } from '@mui/material/AccordionDetails';
import { AccordionSummaryProps } from '@mui/material/AccordionSummary';

import Accordion from './Accordion';
import AccordionDetails from './AccordionDetails';
import AccordionSummary from './AccordionSummary';

import theme from '../theme';

interface AccordionListItemProps extends AccordionProps {
  dynamicBorder?: boolean;
  showComponent?: boolean;
  label: string;
  index: string;
  renderComponent?: ReactNode;
  disableBorderBottom?: boolean;
  customColor?: string;
}

const StyledAccordionSummary = styled(
  (props: AccordionSummaryProps & { isExpanded: boolean }) => (
    <AccordionSummary {...props} />
  ),
)(({ dynamicBorder, customColor }: AccordionListItemProps) => ({
  margin: '0',
  padding: '0',
  borderBottom: dynamicBorder
    ? 'none'
    : `1px solid ${customColor || theme.palette.primary.main}`,
  '& p': {
    color: `${customColor || theme.palette.primary.main}`,
    fontWeight: 420,
    [theme.breakpoints.up('md')]: {
      fontSize: '18px',
      lineHeight: '27px',
    },
  },
  '& .MuiAccordionSummary-expandIconWrapper': {
    color: `${customColor || theme.palette.primary.main}`,
    padding: '12px',
    margin: '0 -12px 0 0',
  },
}));

const StyledAccordionDetails = styled((props: AccordionDetailsProps) => (
  <AccordionDetails {...props} />
))(({ dynamicBorder }: AccordionDetailsProps) => ({
  padding: dynamicBorder ? '0px 0px 15px 0px' : '16px 0px 15px 0px',
  '& p': {
    marginBottom: '24px',
    [theme.breakpoints.up('sm')]: {
      fontSize: '18px',
      lineHeight: '27px',
    },
  },
  '& p:last-of-type': { marginBottom: '0' },
  '& ul': {
    margin: '-24px 0 0 -12px',
  },
  '& ol': {
    margin: '0 0 0 -18px',
    fontWeight: 300,
  },
  '& a': {
    fontWeight: 500,
  },
}));

export const AccordionListItem = (props: AccordionListItemProps) => {
  const {
    children,
    dynamicBorder,
    label,
    index,
    showComponent,
    renderComponent,
    disableBorderBottom,
    customColor,
    ...accordionProps
  } = props;
  const [isExpanded, setExpanded] = useState(false);

  const handleExpandChange = () => {
    setExpanded(!isExpanded);
  };

  return (
    <Accordion
      expanded={isExpanded}
      onChange={handleExpandChange}
      sx={{
        margin: '0',
        boxShadow: 'none',
        borderBottom: dynamicBorder
          ? `1px solid ${customColor || theme.palette.primary.main}`
          : 'none',
      }}
      key={`Accordion-${label}${index}`}
      {...accordionProps}
    >
      <StyledAccordionSummary
        aria-controls={`panel${index}a-content`}
        id={`panel${index}a-header`}
        label={label}
        expanded={isExpanded}
        disableBorderBottom={disableBorderBottom}
        customColor={customColor}
      />
      <StyledAccordionDetails>{children}</StyledAccordionDetails>
      {renderComponent && renderComponent}
    </Accordion>
  );
};

export default AccordionListItem;
