import React from 'react';

const OHCheckCircleIcon = (props: any) => {
  const { fill, ...rest } = props;
  return (
    <svg
      width="13"
      height="13"
      viewBox="0 0 13 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0 6.5C0 2.9055 2.9055 0 6.5 0C10.0945 0 13 2.9055 13 6.5C13 10.0945 10.0945 13 6.5 13C2.9055 13 0 10.0945 0 6.5ZM8.8335 9.75L9.75 8.8335L7.4165 6.5L9.75 4.1665L8.8335 3.25L6.5 5.5835L4.1665 3.25L3.25 4.1665L5.5835 6.5L3.25 8.8335L4.1665 9.75L6.5 7.4165L8.8335 9.75Z"
        fill="#373634"
      />
    </svg>
  );
};

export default OHCheckCircleIcon;
