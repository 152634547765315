import React from 'react';

const Clock = (props: any) => {
  const { fill, ...rest } = props;
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M7.20001 13.2C10.5137 13.2 13.2 10.5137 13.2 7.2C13.2 3.88629 10.5137 1.2 7.20001 1.2C3.8863 1.2 1.20001 3.88629 1.20001 7.2C1.20001 10.5137 3.8863 13.2 7.20001 13.2Z"
        stroke="#335E65"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M7.20001 3.60001V7.20001L9.60001 8.40001"
        stroke="#335E65"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default Clock;
