import React from 'react';
import { default as MuiDrawer, DrawerProps } from '@mui/material/Drawer';

const OHWDrawer = ({
  classes,
  children,
  paperClasses,
  ...rest
}: DrawerProps) => {
  return (
    <MuiDrawer
      PaperProps={{
        classes: { ...paperClasses },
      }}
      {...rest}
    >
      {children}
    </MuiDrawer>
  );
};

const Drawer = (props: any) => <OHWDrawer {...props} />;

export default Drawer;
