import React from 'react';

import LinearProgress, {
  LinearProgressProps,
  linearProgressClasses,
} from '@mui/material/LinearProgress';

import theme from '../theme';

interface LinearProgressBarProps extends LinearProgressProps {
  progressColor?: string;
  backgroundBarColor?: string;
}

const LinearProgressBar = (props: LinearProgressBarProps) => {
  const { progressColor, backgroundBarColor, ...rest } = props;
  return (
    <LinearProgress
      sx={{
        height: '24px',
        [`& .${linearProgressClasses.barColorPrimary}`]: {
          backgroundColor: progressColor || theme.palette.blossom.main,
        },
        [`&.${linearProgressClasses.determinate}`]: {
          backgroundColor:
            backgroundBarColor || theme.palette.darkYellowBeige.main,
        },
      }}
      {...rest}
    />
  );
};

export default LinearProgressBar;
