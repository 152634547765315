import * as React from 'react';
import LuxonUtils from '@date-io/luxon';
import { DateTime } from 'luxon';
import get from 'lodash/get';

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  KeyboardDatePickerProps,
} from '@material-ui/pickers';
import { makeStyles, ThemeProvider, useTheme } from '@material-ui/core/styles';
import { IconButtonProps } from '@material-ui/core';

import Calendar from '../Icons/assets/calendar';
import Lock from '../Icons/assets/lock-closed';
import { Label } from '../Input/input.styles';
import { primaryColor } from '../constants/CSS';

class CustomLuxonUtils extends LuxonUtils {
  getWeekdays(): string[] {
    return ['s', 'm', 't', 'w', 't', 'f', 's'];
  }

  getWeekArray(date: DateTime): DateTime[][] {
    const { days } = date
      .endOf('month')
      .endOf('week')
      .diff(date.startOf('month').startOf('week'), 'days')
      .toObject();

    let weeks: DateTime[][] = [];
    new Array(Math.round(days || 0))
      .fill(0)
      .map((_, i) => i)
      .map((day) =>
        date
          .startOf('month')
          .startOf('week')
          .minus({ days: 1 })
          .plus({ days: day }),
      )
      .forEach((v, i) => {
        if (i === 0 || (i % 7 === 0 && i > 6)) {
          weeks.push([v]);
          return;
        }

        weeks[weeks.length - 1].push(v);
      });

    weeks = weeks.filter((week) => {
      // do not allow weeks with start or end outside of current month
      return (
        week[0].hasSame(date, 'month') ||
        week[week.length - 1].hasSame(date, 'month')
      );
    });

    return weeks;
  }
}

const useLeftButtonStyles = makeStyles((theme) => ({
  root: {
    order: 2,
    '&:hover': {
      backgroundColor: 'white',
    },
    color: theme.palette.primary.main,
    disabled: {
      color: theme.palette.greyMedium,
    },
  },
}));

const useRightButtonStyles = makeStyles((theme) => ({
  root: {
    order: 3,
    '&:hover': {
      backgroundColor: 'white',
    },
    color: theme.palette.primary.main,
    disabled: {
      color: theme.palette.greyMedium,
    },
  },
}));

const useButtonStyles = makeStyles({
  root: {
    opacity: 1,
  },
});

type DatePickerContainerProps = KeyboardDatePickerProps & {
  label?: string;
  innerSidePadding?: number;
};

export default function DatePickerContainer(props: DatePickerContainerProps) {
  const { label, value, innerSidePadding, onOpen, ...rest } = props;
  const buttonClasses = useButtonStyles();
  const leftButtonClasses = useLeftButtonStyles();
  const rightButtonClasses = useRightButtonStyles();
  const theme = useTheme();

  const weekOverrideValues = get(
    theme.overrides?.MuiPickersCalendar,
    'week',
    {},
  );

  const getCustomTheme = (weekCount?: number, innerSidePadding?: number) => ({
    ...theme,
    overrides: {
      ...theme?.overrides,
      MuiInputBase: {
        root: {
          backgroundColor: theme.palette.barelyThereGrey.main,
          '&:before': {
            borderBottom: `1px solid ${primaryColor} !important`,
          },
          '&:after': {
            borderBottom: `2px solid ${primaryColor} !important`,
          },
          '&:hover:before': {
            borderBottom: '2px solid rgba(0, 0, 0, 0.87) !important',
          },
        },
        input: {
          color: theme.palette.primary.main,
          fontFamily: theme.bodyFontFamily,
          outline: 'none',
          fontSize: '14px',
          padding: '16px',
          '&::placeholder': {
            fontStyle: 'italic !important',
          },
          '&::placeholder-shown': {
            fontStyle: 'italic !important',
          },
        },
        'input:placeholder': {
          fontStyle: 'italic !important',
        },
      },
      MuiPickersCalendar: {
        ...theme.overrides?.MuiPickersCalendar,
        week: {
          ...weekOverrideValues,
          paddingRight: innerSidePadding ? `${innerSidePadding}px` : '8px',
          paddingLeft: innerSidePadding ? `${innerSidePadding}px` : '8px',
          [theme.breakpoints.down('md')]: {
            paddingRight: innerSidePadding ? `${innerSidePadding}px` : 'unset',
            paddingLeft: innerSidePadding ? `${innerSidePadding}px` : 'unset',
            height: 'unset',
            '&:first-of-type': {
              marginTop: '16px',
            },
          },
        },
        transitionContainer: {
          minHeight: 'unset',
          marginTop: 'unset',
          height: weekCount ? (weekCount < 6 ? '252px' : '296px') : '296px',
          [theme.breakpoints.down('md')]: {
            height: weekCount ? (weekCount < 6 ? '214px' : '248px') : '248px',
          },
        },
      },
      MuiPickersCalendarHeader: {
        ...theme.overrides?.MuiPickersCalendarHeader,
        daysHeader: {
          display: 'none',
        },
      },
    },
  });

  const getContainerTheme = (date: DateTime) => {
    const daysInMonth = date.get('daysInMonth');
    const startWeekdayInMonth = date.get('weekday');
    const weekCount = Math.ceil((daysInMonth + startWeekdayInMonth - 1) / 7);

    return getCustomTheme(weekCount, innerSidePadding);
  };

  const initialContainerTheme = getContainerTheme(
    value?.toString()
      ? DateTime.fromFormat(value.toString(), 'MM/dd/yyyy').startOf('month')
      : DateTime.now().startOf('month'),
  );

  const [containerTheme, setContainerTheme] = React.useState(
    initialContainerTheme,
  );

  // type any because onMonth expects MaterialUiPickersDate but it actually comes back as type DateTime because we use Luxon util
  const onMonthChange = (date: any) => {
    setContainerTheme(getContainerTheme(date));
  };

  // if user clicks outside the picker, this will reset the container to match the selected month
  const onOpenDatePicker = React.useCallback(() => {
    const currentMonth = DateTime.now().startOf('month');
    let currentMonthOfSelectedDateFromISO = null;
    let currentMonthOfSelectedDateFromFormat = null;

    let dateTimeToUse = currentMonth;

    if (value?.toString()) {
      // supports ISO format
      currentMonthOfSelectedDateFromISO = DateTime.fromISO(
        value?.toString(),
      ).startOf('month');

      if (currentMonthOfSelectedDateFromISO.isValid) {
        dateTimeToUse = currentMonthOfSelectedDateFromISO;
      }

      // or supports MM/dd/yyyy format
      currentMonthOfSelectedDateFromFormat = DateTime.fromFormat(
        value?.toString(),
        'MM/dd/yyyy',
      ).startOf('month');

      if (currentMonthOfSelectedDateFromFormat.isValid) {
        dateTimeToUse = currentMonthOfSelectedDateFromFormat;
      }
    }

    setContainerTheme(getContainerTheme(dateTimeToUse));

    if (onOpen) {
      onOpen();
    }
  }, [value, onOpen]);

  return (
    <>
      {label && (
        <div>
          <Label htmlFor={props.name}>{label}</Label>
        </div>
      )}

      <MuiPickersUtilsProvider utils={CustomLuxonUtils}>
        <ThemeProvider theme={containerTheme}>
          <KeyboardDatePicker
            value={value}
            onMonthChange={onMonthChange}
            onOpen={onOpenDatePicker}
            disableToolbar
            disablePast
            format="MM/dd/yyyy"
            KeyboardButtonProps={{
              'aria-label': 'change date',
              classes: buttonClasses,
            }}
            keyboardIcon={rest.disabled ? <Lock /> : <Calendar />}
            variant="inline"
            leftArrowButtonProps={
              {
                classes: leftButtonClasses,
              } as IconButtonProps
            }
            rightArrowButtonProps={
              {
                classes: rightButtonClasses,
              } as IconButtonProps
            }
            {...rest}
          />
        </ThemeProvider>
      </MuiPickersUtilsProvider>
    </>
  );
}
