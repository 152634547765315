import React from 'react';

function BottleGray() {
  return (
    <svg
      width="15"
      height="36"
      viewBox="0 0 15 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.9903 20.1004C13.6782 18.3364 12.4353 15.714 12.1699 15.12C11.9045 14.5256 10.1791 11.202 9.74849 9.2296C9.36887 7.488 9.15011 2.882 9.12598 2.3536C9.12437 2.3208 9.1351 2.2892 9.15708 2.2608L9.1796 2.2316C9.21552 2.1848 9.22089 2.128 9.19354 2.078C9.16512 2.0264 9.17155 1.968 9.2107 1.9204L9.3989 1.6912C9.42785 1.656 9.43911 1.614 9.43053 1.5732L9.35493 1.2068C9.3485 1.176 9.33134 1.1468 9.30453 1.1228L9.22678 1.0516C9.1855 1.0136 9.16727 0.9652 9.17638 0.9168C9.19676 0.8044 9.22303 0.584 9.1603 0.3968C9.07826 0.152 8.99622 0.134 8.7737 0.0280001C8.73671 0.00920007 8.69274 0 8.64824 0H7.20589H5.86703C5.82253 0 5.7791 0.00919994 5.74156 0.0271999C5.51905 0.1336 5.43701 0.1516 5.35497 0.396C5.29224 0.5832 5.31851 0.8036 5.33889 0.916C5.348 0.9644 5.32977 1.0132 5.28848 1.0508L5.21074 1.122C5.18446 1.1464 5.16677 1.1752 5.16034 1.206L5.08473 1.5724C5.07615 1.6132 5.08741 1.6552 5.11637 1.6904L5.30457 1.92C5.34371 1.9676 5.35015 2.026 5.32173 2.0776C5.29438 2.1276 5.29921 2.1844 5.33567 2.2312L5.35819 2.2604C5.38017 2.2888 5.3909 2.3208 5.38929 2.3532C5.36516 2.8816 5.14693 7.4876 4.76677 9.2292C4.33675 11.2016 2.6113 14.5256 2.34535 15.1196C2.07994 15.714 0.837055 18.3364 0.524993 20.1C0.200064 21.9344 0.231162 28.6856 0.309446 29.7112C0.367354 30.468 0.313199 33.7632 0.410785 34.6412C0.458506 35.0684 0.712123 35.3616 0.830084 35.4588C1.41292 35.938 7.19249 36 7.19249 36C7.19249 36 13.1023 35.938 13.6846 35.4592C13.8026 35.3624 14.0562 35.0688 14.1039 34.6416C14.2021 33.7636 14.1474 30.4684 14.2053 29.7116C14.2841 28.686 14.3152 21.9348 13.9903 20.1004Z"
        fill="#C2C6C9"
      />
    </svg>
  );
}

export default BottleGray;
