import React from 'react';

const XSleftArrow = (props: any) => {
  const { fill, ...rest } = props;
  return (
    <svg
      width="4"
      height="7"
      viewBox="0 0 4 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.93133 1.13726C3.97714 1.0972 4 1.05108 4 0.998979C4 0.946882 3.97714 0.900762 3.93133 0.860767L3.58765 0.56014C3.54184 0.520071 3.48912 0.5 3.42964 0.5C3.37009 0.5 3.31736 0.520071 3.27155 0.56014L0.0686688 3.36171C0.0228615 3.40178 0 3.4479 0 3.5C0 3.5521 0.0228615 3.59822 0.0686688 3.63829L3.27147 6.43986C3.31728 6.48 3.37 6.5 3.42956 6.5C3.48903 6.5 3.54176 6.48 3.58757 6.43986L3.93125 6.13938C3.97705 6.09931 3.99992 6.05319 3.99992 6.00102C3.99992 5.949 3.97705 5.90288 3.93125 5.86281L1.23013 3.5L3.93133 1.13726Z"
        fill="#373634"
      />
    </svg>
  );
};

export default XSleftArrow;
