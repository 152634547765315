import React from 'react';

const SvgRewards = (props: any) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.8755 7.00426C15.0077 6.86624 15.038 6.65464 14.9502 6.48252L12.7853 2.23534C12.7111 2.09017 12.5658 1.99962 12.4078 2H3.5907C3.43271 1.99962 3.28745 2.09017 3.21319 2.23534L1.04837 6.48696C0.962679 6.65797 0.992843 6.867 1.12301 7.00426L7.66654 13.8379C7.74781 13.9408 7.86916 14.0003 7.99713 14C8.12511 14.0003 8.24646 13.9408 8.32772 13.8379L14.8755 7.00426ZM10.0978 7.13516L7.99911 12.3903L5.9132 7.1396L10.0978 7.13516ZM7.99907 3.36093L6.23948 6.24715H9.75866L7.99907 3.36093ZM11.0215 7.13516H13.5446L9.21496 11.6665L11.0215 7.13516ZM12.4783 3.51633L11.3777 6.24713L13.8646 6.25157L12.4783 3.51633ZM10.5778 5.93851L8.72435 2.888H11.7978L10.5778 5.93851ZM4.2284 2.888L5.43985 5.92075L7.27409 2.888H4.2284ZM4.64859 6.24937H2.13398L3.54165 3.48526L4.64859 6.24937ZM6.7836 11.6666L5.00055 7.13745L2.45395 7.13967L6.7836 11.6666Z"
      fill="#393939"
    />
  </svg>
);

export default SvgRewards;
