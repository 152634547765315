import React from 'react';

const FacebookLogin = (props: any) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="9" cy="9" r="9" fill="#1877F2" />
      <path
        d="M12.5558 11.5505L12.9582 8.92816H10.441V7.22648C10.441 6.50906 10.7927 5.80971 11.9201 5.80971H13.0646V3.57719C13.0646 3.57719 12.026 3.4 11.0329 3.4C8.95967 3.4 7.60459 4.65588 7.60459 6.92952V8.92816H5.30005V11.5505H7.60459V17.8898C8.06674 17.9623 8.54024 18 9.02278 18C9.50531 18 9.97881 17.9623 10.441 17.8898V11.5505H12.5558Z"
        fill="white"
      />
    </svg>
  );
};

export default FacebookLogin;
