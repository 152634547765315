import React from 'react';
import Container from '../Container';
import Icons from '../Icons';
import styled from '../styled';
import theme from '../theme';
import Text from '../Text';
import { TEXT_DS } from '../Text/constants';

interface SelectionButtonContainerProps {
  selected: boolean;
}

interface SelectionButtonProps {
  selected: boolean;
  onClick: () => any;
  buttonText?: string;
  children?: React.ReactNode;
}

export const SelectionButtonContainer = styled(
  Container,
)<SelectionButtonContainerProps>(({ selected }: { selected: boolean }) => ({
  '&&': {
    border: selected ? '2px solid #9A6C55' : '2px solid #C2C6C9',
    boxShadow: selected ? '0px 8px 16px 0px #1A1A1A29' : 'none',
    borderRadius: '4px',
    width: '100%',
    position: 'relative',
    padding: '16px',
    cursor: 'pointer',
    // ['@media all and (min-width: 768px)']: {
    //   // padding: '0 8px',
    //   maxWidth: '343px',
    // },
    // [theme.breakpoints.up('md')]: {
    //   // padding: 0,
    //   maxWidth: '420px',
    // },
  },
}));

const ButtonText = styled(Text)({
  textAlign: 'center',
});

const IconContainer = styled('span')({
  height: '40px',
  right: '7px',
  top: 0,
  bottom: 0,
  margin: 'auto 0',
  position: 'absolute',
});

export const SelectionButton = ({
  selected,
  onClick,
  buttonText,
  children,
}: SelectionButtonProps) => (
  <SelectionButtonContainer selected={selected} onClick={onClick}>
    <ButtonText
      variant="custom"
      default={TEXT_DS.BODY_SEMIBOLD_18}
      tablet={TEXT_DS.BODY_SEMIBOLD_20}
    >
      {buttonText}
    </ButtonText>
    {children}
    {selected && (
      <IconContainer>
        <Icons.RadioCheckOn fill={theme.palette.mud.main} />
      </IconContainer>
    )}
  </SelectionButtonContainer>
);
