import * as React from 'react';

const SvgComponent = (props: any) => (
  <svg
    width={40}
    height={40}
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M35 19.1667C35.0057 21.3664 34.4918 23.5365 33.5 25.5C32.3241 27.8529 30.5162 29.832 28.2791 31.2155C26.0419 32.599 23.4637 33.3323 20.8333 33.3333C18.6336 33.3391 16.4635 32.8251 14.5 31.8333L5 35L8.16667 25.5C7.17489 23.5365 6.66093 21.3664 6.66667 19.1667C6.66768 16.5363 7.40102 13.9581 8.78453 11.7209C10.168 9.48375 12.1471 7.67595 14.5 6.5C16.4635 5.50822 18.6336 4.99426 20.8333 5H21.6667C25.1406 5.19165 28.4217 6.65794 30.8819 9.1181C33.3421 11.5783 34.8083 14.8594 35 18.3333V19.1667Z"
      stroke="#373634"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SvgComponent;
