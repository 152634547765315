import React, { useState } from 'react';

import { styled } from '@mui/material/styles';
import { AccordionProps } from '@mui/material/Accordion';
import { AccordionSummaryProps } from '@mui/material/AccordionSummary';

import makeStyles from '../makeStyles'; // TODO: Upgrade to MUI5 when Text is upgraded!

import theme from '../theme';
import ThemeType from '../theme/ThemeType';
import Text from '../Text';
import Grid from '../Grid';
import Icons from '../Icons';

import Accordion from './Accordion';
import AccordionDetails from './AccordionDetails';
import AccordionSummary from './AccordionSummary';

export const useStyles = makeStyles((theme: ThemeType) => ({
  sectionHeader: {
    color: theme.palette.neutral0.main,
    textTransform: 'uppercase',
    paddingBottom: '8px',
    [theme.breakpoints.up('sm')]: {
      fontSize: '18px',
      lineHeight: '27px',
    },
  },
  menuItem: {
    color: theme.palette.neutral0.main,
    fontFamily: theme.bodyFontFamily,
    paddingBottom: '4px',
    cursor: 'pointer',
    fontWeight: 300,
    [theme.breakpoints.up('sm')]: {
      fontSize: '18px',
      lineHeight: '27px',
    },
  },
}));

const StyledAccordion = styled((props: AccordionProps) => (
  <Accordion {...props} />
))(() => ({
  borderBottom: 'none',
  backgroundColor:'#31302E',
  margin: '16px 0 0 0 !important',
}));

const StyledAccordionSummary = styled((props: AccordionSummaryProps) => (
  <AccordionSummary {...props} />
))(() => ({
  borderBottom: `1px solid ${theme.palette.neutral0.main}`,
  padding: '16px 0 0 0',
  maxHeight: '48px',
  margin: '0',
}));

interface AccordionFooterItemProps {
  expanded: string;
  setExpanded: React.Dispatch<React.SetStateAction<string>>;
  handleRouteChange: (route: string) => void;
  index: number;
  name: string;
  links: { path: string; name: string }[];
}

export const AccordionFooterItem = (props: AccordionFooterItemProps) => {
  const { index, name, links, handleRouteChange } = props;

  const [isExpanded, setExpanded] = useState(false);

  const handleExpandChange = () => {
    setExpanded(!isExpanded);
  };

  const { sectionHeader, menuItem } = useStyles();

  return (
    <StyledAccordion
      expanded={isExpanded}
      onChange={handleExpandChange}
      key={`Accordion-${name}`}
    >
      <StyledAccordionSummary
        aria-controls={`panel${index}a-content`}
        id={`panel${index}a-header`}
        expandIcon={isExpanded ? <Icons.MinusWhite /> : <Icons.PlusWhite />}
      >
        <Text variant="h3" className={sectionHeader}>
          {name}
        </Text>
      </StyledAccordionSummary>
      <AccordionDetails sx={{ padding: '16px 0 0 0' }}>
        <Grid container direction="column">
          {links &&
            links.map((link: any) => {
              return (
                <Text
                  className={menuItem}
                  key={`AccordionItem-${link.name}`}
                  onClick={() => handleRouteChange(link?.path)}
                >
                  {link.name}
                </Text>
              );
            })}
        </Grid>
      </AccordionDetails>
    </StyledAccordion>
  );
};

export default AccordionFooterItem;
