import React from 'react';

interface EmptyProfileProps {
  width: string;
  height: string;
}

function EmptyProfile({ width = '401', height = '401' }: EmptyProfileProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={`312.809 0 401 401`}
      style={{ borderRadius: '50%' }}
    >
      <g transform="translate(-467.5 -843.44) scale(1.223)">
        <path
          fill="#E4E6E7"
          d="M601.45 653.07H1002.45V1054.0700000000002H601.45z"
        ></path>
        <path
          fill="#AEB4B7"
          d="M802.38 908.08c-84.515 0-153.52 48.185-157.38 108.62h314.79c-3.87-60.44-72.9-108.62-157.41-108.62zM881.37 818.86c0 46.746-35.106 84.641-78.41 84.641s-78.41-37.895-78.41-84.641 35.106-84.641 78.41-84.641c43.31 0 78.41 37.9 78.41 84.64z"
        ></path>
      </g>
    </svg>
  );
}

export default EmptyProfile;
