import React from 'react';

// JC: Do we need this export here? It won't build for me if the interface is not exported.
export interface SliderProps {
  fill?: string;
}
const SliderRightArrow = (props: SliderProps) => (
  <span>
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.206 12.1242C14.0686 11.9907 14 11.8369 14 11.6633C14 11.4896 14.0686 11.3359 14.206 11.2026L15.2371 10.2005C15.3745 10.0669 15.5326 10 15.7111 10C15.8897 10 16.0479 10.0669 16.1853 10.2005L25.794 19.539C25.9314 19.6726 26 19.8263 26 20C26 20.1737 25.9314 20.3274 25.794 20.461L16.1856 29.7995C16.0482 29.9333 15.89 30 15.7113 30C15.5329 30 15.3747 29.9333 15.2373 29.7995L14.2063 28.7979C14.0688 28.6644 14.0003 28.5106 14.0003 28.3367C14.0003 28.1633 14.0688 28.0096 14.2063 27.876L22.3096 20L14.206 12.1242Z"
        fill={props.fill || '#9F7058'}
      />
    </svg>
  </span>
);
export default SliderRightArrow;
