import * as React from 'react';

const SvgComponent = (props: any) => (
  <svg
    width={18}
    height={18}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g opacity={0.8} className="icon">
      <mask id="a" fill="#fff">
        <path d="M4.5 10.519h5.869V9.394H4.5v1.125Zm0-2.438h9V6.956h-9v1.125Zm0-2.437h9V4.519h-9v1.125ZM1.5 16.5V2.625c0-.288.113-.547.337-.778.226-.231.488-.347.788-.347h12.75c.287 0 .547.116.778.347.231.231.347.49.347.778v9.75c0 .287-.116.547-.347.778-.231.231-.49.347-.778.347H4.5l-3 3Zm1.125-2.719 1.406-1.406h11.344v-9.75H2.625v11.156Zm0-11.156v11.156V2.625Z" />
      </mask>
      <path
        d="M4.5 10.519h5.869V9.394H4.5v1.125Zm0-2.438h9V6.956h-9v1.125Zm0-2.437h9V4.519h-9v1.125ZM1.5 16.5V2.625c0-.288.113-.547.337-.778.226-.231.488-.347.788-.347h12.75c.287 0 .547.116.778.347.231.231.347.49.347.778v9.75c0 .287-.116.547-.347.778-.231.231-.49.347-.778.347H4.5l-3 3Zm1.125-2.719 1.406-1.406h11.344v-9.75H2.625v11.156Zm0-11.156v11.156V2.625Z"
        fill="#373634"
      />
      <path
        d="M1.875 2.625v11.156h1.5V2.625h-1.5ZM4.5 10.519H3v1.5h1.5v-1.5Zm5.869 0v1.5h1.5v-1.5h-1.5Zm0-1.125h1.5v-1.5h-1.5v1.5Zm-5.869 0v-1.5H3v1.5h1.5Zm0-1.313H3v1.5h1.5v-1.5Zm9 0v1.5H15v-1.5h-1.5Zm0-1.125H15v-1.5h-1.5v1.5Zm-9 0v-1.5H3v1.5h1.5Zm0-1.312H3v1.5h1.5v-1.5Zm9 0v1.5H15v-1.5h-1.5Zm0-1.125H15v-1.5h-1.5v1.5Zm-9 0v-1.5H3v1.5h1.5ZM1.5 16.5H0v3.621l2.56-2.56L1.5 16.5Zm3-3V12h-.621l-.44.44L4.5 13.5Zm-1.875.281h-1.5v3.622l2.56-2.561-1.06-1.06Zm1.406-1.406v-1.5H3.41l-.44.44 1.061 1.06Zm11.344 0v1.5h1.5v-1.5h-1.5Zm0-9.75h1.5v-1.5h-1.5v1.5Zm-12.75 0v-1.5h-1.5v1.5h1.5ZM4.5 12.019h5.869v-3H4.5v3Zm7.369-1.5V9.394h-3v1.125h3Zm-1.5-2.625H4.5v3h5.869v-3ZM3 9.394v1.125h3V9.394H3Zm1.5.187h9v-3h-9v3Zm10.5-1.5V6.956h-3v1.125h3Zm-1.5-2.625h-9v3h9v-3ZM3 6.956v1.125h3V6.956H3Zm1.5.188h9v-3h-9v3Zm10.5-1.5V4.519h-3v1.125h3Zm-1.5-2.625h-9v3h9v-3ZM3 4.519v1.125h3V4.519H3ZM3 16.5V2.625H0V16.5h3ZM3 2.625a.48.48 0 0 1-.04.187c-.025.059-.05.084-.047.08L.763.802C.3 1.275 0 1.898 0 2.625h3Zm-.087.268A.467.467 0 0 1 2.625 3V0C1.887 0 1.246.304.762.8l2.15 2.093ZM2.625 3h12.75V0H2.625v3Zm12.75 0a.504.504 0 0 1-.2-.043c-.062-.028-.088-.055-.083-.05l2.122-2.12C16.744.315 16.116 0 15.375 0v3Zm-.283-.092c.006.005-.021-.021-.049-.082A.505.505 0 0 1 15 2.625h3c0-.741-.316-1.368-.786-1.839l-2.121 2.122ZM15 2.625v9.75h3v-9.75h-3Zm0 9.75c0-.064.014-.136.043-.2.028-.062.055-.088.05-.083l2.12 2.122c.471-.47.787-1.098.787-1.839h-3Zm.092-.283c-.005.006.021-.021.082-.049a.505.505 0 0 1 .201-.043v3c.741 0 1.368-.316 1.839-.786l-2.121-2.122Zm.283-.092H4.5v3h10.875v-3Zm-11.936.44-3 3 2.122 2.12 3-3-2.122-2.12Zm.247 2.402 1.406-1.406-2.121-2.122-1.407 1.407 2.122 2.12Zm.345-.967h11.344v-3H4.031v3Zm12.844-1.5v-9.75h-3v9.75h3Zm-1.5-11.25H2.625v3h12.75v-3Zm-14.25 1.5v9.75h3v-9.75h-3Zm0 9.75v1.406h3v-1.406h-3Z"
        fill="#fff"
        mask="url(#a)"
      />
    </g>
  </svg>
);

export default SvgComponent;
