import React, { useState } from 'react';
import Container from '../Container';
import Icons from '../Icons';
import makeStyles from '../makeStyles';
import Text from '../Text';
import ThemeType from '../theme/ThemeType';

const useStyles = makeStyles((theme: ThemeType) => ({
  dropdownContainer: {
    boxShadow: `0px 5px 8px ${theme.palette.greyMedium.main}`,
    position: 'relative',
    zIndex: 10,
    width: '99.7%',
    paddingLeft: 0,
    marginTop: 0,
  },
  dropdownHeader: {
    backgroundColor: '#F9FCFF',
    height: '48px',
    border: `1px solid ${theme.palette.primary.main}`,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  dropdownTitle: {
    marginTop: '30px',
    marginBottom: '6px',
    fontSize: '12px',
  },
  dropdownItem: {
    height: '48px',
    borderBottom: `1px solid ${theme.palette.veryLightGrey.main}`,
    borderLeft: `5px solid ${theme.palette.greyLight.main}`,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  dropdownItemSelected: {
    height: '48px',
    backgroundColor: `#F9FCFF`,
    borderBottom: `1px solid ${theme.palette.veryLightGrey.main}`,
    borderLeft: `5px solid ${theme.palette.primary.main}`,
  },
  selectedOptionText: {
    lineHeight: 3,
  },
  optionText: {
    marginLeft: '10px',
    lineHeight: 3,
  },
  arrowIcon: {
    float: 'right',
    marginTop: '-29px',
  },
}));

type OptionType = {
  value: number | string | boolean;
  displayValue?: string;
};

type DropdownMenuProps = {
  selectedValue: number | string | boolean;
  setSelectedValue: React.Dispatch<
    React.SetStateAction<number | string | boolean>
  >;
  title: string;
  selectedOptionText: string;
  options: OptionType[];
};

export default function DropdownMenu(props: DropdownMenuProps) {
  const {
    selectedValue,
    setSelectedValue,
    title,
    selectedOptionText,
    options,
  } = props;
  const classes = useStyles();

  const [openDropdown, setOpenDropdown] = useState(false);
  const handleToggleDropDown = () => {
    setOpenDropdown(!openDropdown);
  };

  const optionsWithHandlers = options.map((option: OptionType) => {
    const value = option.value;
    const handler = () => {
      setSelectedValue(value);
      handleToggleDropDown();
    };

    return {
      ...option,
      handler,
    };
  });

  return (
    <>
      <Text className={classes.dropdownTitle}>{title}</Text>
      <Container
        className={classes.dropdownHeader}
        onClick={handleToggleDropDown}
      >
        <Text className={classes.selectedOptionText}>{selectedOptionText}</Text>
        {openDropdown ? (
          <Icons.ArrowUpSmall className={classes.arrowIcon} />
        ) : (
          <Icons.ArrowDownSmall className={classes.arrowIcon} />
        )}
      </Container>
      {openDropdown && (
        <ul className={classes.dropdownContainer}>
          {optionsWithHandlers.map((o) => (
            <li
              className={
                selectedValue === o.value
                  ? classes.dropdownItemSelected
                  : classes.dropdownItem
              }
              onClick={o.handler}
            >
              <Text className={classes.optionText}>
                {o.displayValue || o.value}
              </Text>
            </li>
          ))}
        </ul>
      )}
    </>
  );
}
