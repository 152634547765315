import React from 'react';

function BottleBlue6() {
  return (
    <svg
      width="14"
      height="36"
      viewBox="0 0 14 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.7327 20.1004C13.4206 18.3364 12.1778 15.714 11.9123 15.12C11.6469 14.5256 9.92148 11.202 9.49093 9.2296C9.1113 7.488 8.89254 2.882 8.86841 2.3536C8.8668 2.3208 8.87753 2.2892 8.89951 2.2608L8.92203 2.2316C8.95795 2.1848 8.96332 2.128 8.93597 2.078C8.90755 2.0264 8.91399 1.968 8.95313 1.9204L9.14133 1.6912C9.17029 1.656 9.18154 1.614 9.17297 1.5732L9.09736 1.2068C9.09093 1.176 9.07377 1.1468 9.04696 1.1228L8.96922 1.0516C8.92793 1.0136 8.9097 0.9652 8.91881 0.9168C8.93919 0.8044 8.96546 0.584 8.90273 0.3968C8.82069 0.152 8.73865 0.134 8.51614 0.0280001C8.47914 0.00920007 8.43517 0 8.39067 0H6.94832H5.60946C5.56496 0 5.52153 0.00919994 5.484 0.0271999C5.26148 0.1336 5.17944 0.1516 5.0974 0.396C5.03467 0.5832 5.06094 0.8036 5.08132 0.916C5.09043 0.9644 5.0722 1.0132 5.03092 1.0508L4.95317 1.122C4.9269 1.1464 4.9092 1.1752 4.90277 1.206L4.82716 1.5724C4.81859 1.6132 4.82985 1.6552 4.8588 1.6904L5.047 1.92C5.08614 1.9676 5.09258 2.026 5.06416 2.0776C5.03681 2.1276 5.04164 2.1844 5.0781 2.2312L5.10062 2.2604C5.1226 2.2888 5.13333 2.3208 5.13172 2.3532C5.10759 2.8816 4.88936 7.4876 4.50921 9.2292C4.07918 11.2016 2.35373 14.5256 2.08778 15.1196C1.82237 15.714 0.579486 18.3364 0.267425 20.1C-0.0575046 21.9344 -0.026406 28.6856 0.0518774 29.7112C0.109786 30.468 0.0556303 33.7632 0.153216 34.6412C0.200937 35.0684 0.454555 35.3616 0.572516 35.4588C1.15535 35.938 6.93492 36 6.93492 36C6.93492 36 12.8448 35.938 13.4271 35.4592C13.545 35.3624 13.7987 35.0688 13.8464 34.6416C13.9445 33.7636 13.8898 30.4684 13.9477 29.7116C14.0265 28.686 14.0576 21.9348 13.7327 20.1004Z"
        fill="#46789E"
      />
      <path
        d="M4.32991 26.76C4.32991 28.608 5.24191 30.12 7.16191 30.12C8.61391 30.12 9.83791 28.884 9.83791 27.372C9.83791 25.716 8.60191 24.768 7.32991 24.768C6.52591 24.768 5.82991 25.152 5.73391 25.212C6.12991 23.736 8.03791 22.74 8.58991 22.56C8.81791 22.488 8.86591 22.356 8.80591 22.212L8.60191 21.72C8.55391 21.588 8.44591 21.528 8.30191 21.576C7.19791 21.996 4.32991 23.412 4.32991 26.76ZM7.14991 29.04C5.84191 29.04 5.39791 27.672 5.49391 26.664C5.56591 26.448 6.18991 25.752 7.13791 25.752C8.02591 25.752 8.73391 26.484 8.73391 27.384C8.73391 28.284 8.03791 29.04 7.14991 29.04Z"
        fill="white"
      />
    </svg>
  );
}

export default BottleBlue6;
