import * as React from 'react';

const SvgComponent = (props: any) => (
  <svg
    width={57}
    height={56}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx={28.5} cy={28} r={28} fill="#EDE0BF" />
    <path
      d="M20.784 24.315h.274c1.053 0 1.916.862 1.916 1.915v9.58a1.921 1.921 0 0 1-1.916 1.915h-.274a1.921 1.921 0 0 1-1.916-1.916V26.23c0-1.053.863-1.915 1.916-1.915Zm7.663-5.748c1.054 0 1.916.863 1.916 1.916V35.81a1.921 1.921 0 0 1-1.916 1.916 1.921 1.921 0 0 1-1.916-1.916V20.483c0-1.053.863-1.916 1.916-1.916Zm7.663 10.948c1.054 0 1.916.862 1.916 1.915v4.38a1.921 1.921 0 0 1-1.916 1.915 1.921 1.921 0 0 1-1.916-1.916V31.43c0-1.053.863-1.915 1.916-1.915Z"
      fill="#D19400"
    />
  </svg>
);

export default SvgComponent;
