import React, { ReactNode } from 'react';

import AccordionSummary, {
  AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import { styled } from '@mui/material/styles';

import Icons from '../Icons';
import Text from '../Text';

interface OHWAccordionSummaryProps extends AccordionSummaryProps {
  label?: string;
  subLabel?: string;
  children?: ReactNode;
  disableBorderBottom?: boolean;
  disableParagraphStyling?: boolean;
  iconClassName?: string;
}

const StyledAccordionSummaryWrapper = ({
  disableBorderBottom,
  disableParagraphStyling,
  ...props
}: {
  disableBorderBottom?: boolean;
  disableParagraphStyling?: boolean;
  [key: string]: any;
}) => <AccordionSummary {...props} />;

const StyledAccordionSummary = styled((props: OHWAccordionSummaryProps) => (
  <StyledAccordionSummaryWrapper {...props} />
))(
  ({
    disableBorderBottom,
    disableParagraphStyling,
  }: OHWAccordionSummaryProps) => {
    return {
      borderBottom: disableBorderBottom ? `${0} !important` : undefined,
      ...(disableParagraphStyling
        ? {}
        : {
            '& p': {
              fontSize: '16px',
              lineHeight: '24px',
              fontWeight: 400,
              letterSpacing: '0.01em',
            },
          }),
    };
  },
);

const OHWAccordionSummary = (props: OHWAccordionSummaryProps) => {
  const {
    iconClassName,
    children,
    expandIcon,
    label,
    subLabel,
    ...accordionProps
  } = props;

  return (
    <StyledAccordionSummary
      expandIcon={
        expandIcon ? (
          expandIcon
        ) : (
          <Icons.ExpandMoreIcon className={iconClassName} />
        )
      }
      {...accordionProps}
    >
      {label ? (
        <div>
          <Text variant="body1" component="p">
            {label}
          </Text>
          {subLabel && (
            <Text variant="body1" component="p">
              {subLabel}
            </Text>
          )}
        </div>
      ) : (
        children
      )}
    </StyledAccordionSummary>
  );
};

export default OHWAccordionSummary;
