import React from 'react';

const CaretDown = (props: any) => {
  const { fill, ...rest } = props;
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path d="M5 8.5L10 13.5L15 8.5H5Z" fill="#373634" />
    </svg>
  );
};

export default CaretDown;
