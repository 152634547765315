import React, { useState } from 'react';
import Downshift from 'downshift';
import ThemeType from '../theme/ThemeType';
import makeStyles from '../makeStyles';
import { MenuContainer } from '../AutoCompleteV2';

function useToggle(initial = false) {
  const [value, setToggle] = useState(initial);
  return {
    value,
    set: setToggle,
    setTrue: () => setToggle(true),
    setFalse: () => setToggle(false),
    reset: () => setToggle(initial),
    toggle: () => setToggle((prev) => !prev),
  };
}

export interface StyleProps {
  positionRelative?: boolean;
}

const useStyles = makeStyles<ThemeType, StyleProps>(() => ({
  fullWidth: {
    width: '100%',
    position: ({ positionRelative }: StyleProps) =>
      positionRelative ? 'relative' : 'inherit',
  },
}));

interface GetItemPropsOptions<Item> extends React.HTMLProps<HTMLElement> {
  index?: number;
  item: Item;
  isSelected?: boolean;
  disabled?: boolean;
}

export interface SuggestionProps {
  index: number;
  highlightedIndex: number | null;
  itemProps: GetItemPropsOptions<string>;
  selectedItem: string | Object;
  suggestion: any;
}

type SuggestionsType = {
  id: string | null;
  image: string | null;
  isCEO: boolean;
  isEvent: boolean;
  isProduct: boolean;
  primaryLabel: string;
  customUrl: string | null;
  productCategory: string | null;
  routePath: string | null;
  secondaryLabel: string | null;
};

interface AutoCompleteProps {
  inputValue: string;
  placeholder: string;
  suggestions: SuggestionsType;
  MenuContainer?: JSX.Element;
  Input: JSX.Element;
  handleChange: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void;
  handleSelect: (selectedItem: string | Object) => void;
  renderSuggestion: (props: SuggestionProps) => JSX.Element;
  endAdornmentIcon?: any;
  startAdornmentIcon?: any;
  disabled?: boolean;
  hasError?: boolean;
  error?: string;
  helperText?: string;
  positionRelative?: boolean;
}

export default function AutoComplete(props: AutoCompleteProps) {
  const {
    inputValue,
    placeholder,
    suggestions,
    handleChange,
    handleSelect,
    renderSuggestion,
    Input,
    endAdornmentIcon,
    startAdornmentIcon,
    disabled,
    hasError,
    error,
    helperText,
    positionRelative,
  } = props;

  const classes = useStyles({ positionRelative });

  const MenuContainerComponent = props.MenuContainer || MenuContainer;

  const {
    value: isMenuOpen,
    setTrue: setMenuOpen,
    setFalse: setMenuClose,
  } = useToggle();

  const onChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    handleChange(event);
    if (event.target.value) {
      return setMenuOpen();
    }
    return setMenuClose();
  };

  const onSelect = (selectedItem: string | Object) => {
    handleSelect(selectedItem);
    setMenuClose();
  };

  return (
    <div className={classes.fullWidth}>
      <Downshift
        initialSelectedItem={inputValue}
        isOpen={isMenuOpen}
        /* eslint-disable react/jsx-no-bind */
        onSelect={onSelect}
        onOuterClick={setMenuClose}
        itemToString={(item: any) => {
          return item == null
            ? ''
            : typeof item === 'object'
            ? item[Object.keys(item)[0]]
            : String(item);
        }}
      >
        {({
          getItemProps,
          getMenuProps,
          getInputProps,
          highlightedIndex,
          selectedItem,
        }): any => {
          return (
            <div>
              {/*
                // @ts-ignore */}
              <Input
                {...getInputProps()}
                value={inputValue}
                inputRef={(input: any) => {
                  if (input !== null) {
                    input.focus();
                  }
                }}
                onChange={onChange}
                placeholder={placeholder}
                adornmentIcon={endAdornmentIcon}
                startAdornmentIcon={startAdornmentIcon}
                disabled={disabled}
                hasError={hasError}
                error={error}
                helperText={helperText}
                className={classes.fullWidth}
              />
              {/*
                // @ts-ignore */}
              <MenuContainerComponent {...getMenuProps()}>
                {isMenuOpen &&
                  suggestions &&
                  // @ts-ignore
                  suggestions.map((suggestion: any, index: number) =>
                    renderSuggestion({
                      suggestion,
                      index,
                      itemProps: getItemProps({
                        item: suggestion,
                      }),
                      highlightedIndex,
                      selectedItem,
                    }),
                  )}
              </MenuContainerComponent>
            </div>
          );
        }}
      </Downshift>
    </div>
  );
}
