import React from 'react';

const QuestionMarkSVG = (props: any) => {
  const { fill, stroke, ...rest } = props;
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <g clip-path="url(#clip0_271_16058)">
        <path
          d="M3.125 12C3.125 13.1819 3.35779 14.3522 3.81008 15.4442C4.26238 16.5361 4.92531 17.5282 5.76104 18.364C6.59677 19.1997 7.58892 19.8626 8.68085 20.3149C9.77278 20.7672 10.9431 21 12.125 21C13.3069 21 14.4772 20.7672 15.5692 20.3149C16.6611 19.8626 17.6532 19.1997 18.489 18.364C19.3247 17.5282 19.9876 16.5361 20.4399 15.4442C20.8922 14.3522 21.125 13.1819 21.125 12C21.125 10.8181 20.8922 9.64778 20.4399 8.55585C19.9876 7.46392 19.3247 6.47177 18.489 5.63604C17.6532 4.80031 16.6611 4.13738 15.5692 3.68508C14.4772 3.23279 13.3069 3 12.125 3C10.9431 3 9.77278 3.23279 8.68085 3.68508C7.58892 4.13738 6.59677 4.80031 5.76104 5.63604C4.92531 6.47177 4.26238 7.46392 3.81008 8.55585C3.35779 9.64778 3.125 10.8181 3.125 12Z"
          stroke={stroke || '#88898A'}
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M12.125 17V17.01"
          stroke={stroke || '#88898A'}
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M12.125 13.5C12.1066 13.1754 12.1942 12.8535 12.3745 12.583C12.5549 12.3125 12.8183 12.1078 13.125 12C13.5009 11.8562 13.8382 11.6272 14.1106 11.3309C14.3829 11.0347 14.5827 10.6792 14.6943 10.2926C14.8059 9.90594 14.8263 9.49868 14.7537 9.10285C14.6812 8.70702 14.5178 8.33343 14.2763 8.0115C14.0349 7.68956 13.722 7.42807 13.3623 7.2476C13.0026 7.06713 12.6059 6.97262 12.2035 6.97151C11.8011 6.97039 11.4039 7.0627 11.0432 7.24117C10.6826 7.41964 10.3682 7.67939 10.125 7.99998"
          stroke={stroke || '#88898A'}
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_271_16058">
          <rect
            width="24"
            height="24"
            fill={fill || 'white'}
            transform="translate(0.125)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
export default QuestionMarkSVG;
