import React from 'react';

function SMS(props: any) {
  return (
    <svg
      width="60"
      height="61"
      viewBox="0 0 60 61"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        y="0.5"
        width="60"
        height="60"
        rx="12"
        fill="url(#paint0_linear_1523_54389)"
      />
      <path
        d="M45 35.5C45 36.3841 44.6488 37.2319 44.0237 37.857C43.3986 38.4821 42.5507 38.8333 41.6667 38.8333H21.6667L15 45.5V18.8333C15 17.9493 15.3512 17.1014 15.9763 16.4763C16.6014 15.8512 17.4493 15.5 18.3333 15.5H41.6667C42.5507 15.5 43.3986 15.8512 44.0237 16.4763C44.6488 17.1014 45 17.9493 45 18.8333V35.5Z"
        stroke="white"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1523_54389"
          x1="30"
          y1="0.5"
          x2="30"
          y2="60.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#6CF381" />
          <stop offset="1" stopColor="#23C141" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default SMS;
