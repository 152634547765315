import React from 'react';

function BackArrow(props: any) {
  const { fill, ...rest } = props;
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M10 12.5L6 8.5L10 4.5"
        stroke="#373634"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export default BackArrow;
