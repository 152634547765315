import React from 'react';

function BottleBlue() {
  return (
    <svg
      width="15"
      height="36"
      viewBox="0 0 15 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.9881 20.1004C13.676 18.3364 12.4331 15.714 12.1677 15.12C11.9023 14.5256 10.1769 11.202 9.7463 9.2296C9.36668 7.488 9.14791 2.882 9.12378 2.3536C9.12218 2.3208 9.1329 2.2892 9.15488 2.2608L9.1774 2.2316C9.21333 2.1848 9.21869 2.128 9.19134 2.078C9.16292 2.0264 9.16936 1.968 9.2085 1.9204L9.3967 1.6912C9.42566 1.656 9.43692 1.614 9.42834 1.5732L9.35273 1.2068C9.3463 1.176 9.32914 1.1468 9.30233 1.1228L9.22459 1.0516C9.1833 1.0136 9.16507 0.9652 9.17418 0.9168C9.19456 0.8044 9.22083 0.584 9.1581 0.3968C9.07606 0.152 8.99403 0.134 8.77151 0.0280001C8.73451 0.00920007 8.69054 0 8.64604 0H7.20369H5.86483C5.82033 0 5.7769 0.00919994 5.73937 0.0271999C5.51685 0.1336 5.43481 0.1516 5.35277 0.396C5.29004 0.5832 5.31631 0.8036 5.33669 0.916C5.3458 0.9644 5.32757 1.0132 5.28629 1.0508L5.20854 1.122C5.18227 1.1464 5.16457 1.1752 5.15814 1.206L5.08254 1.5724C5.07396 1.6132 5.08522 1.6552 5.11417 1.6904L5.30237 1.92C5.34151 1.9676 5.34795 2.026 5.31953 2.0776C5.29219 2.1276 5.29701 2.1844 5.33347 2.2312L5.35599 2.2604C5.37798 2.2888 5.3887 2.3208 5.38709 2.3532C5.36296 2.8816 5.14473 7.4876 4.76458 9.2292C4.33455 11.2016 2.6091 14.5256 2.34315 15.1196C2.07774 15.714 0.834857 18.3364 0.522796 20.1C0.197866 21.9344 0.228965 28.6856 0.307249 29.7112C0.365157 30.468 0.311001 33.7632 0.408588 34.6412C0.456308 35.0684 0.709926 35.3616 0.827887 35.4588C1.41072 35.938 7.19029 36 7.19029 36C7.19029 36 13.1002 35.938 13.6825 35.4592C13.8004 35.3624 14.054 35.0688 14.1018 34.6416C14.1999 33.7636 14.1452 30.4684 14.2031 29.7116C14.2819 28.686 14.313 21.9348 13.9881 20.1004Z"
        fill="#46789E"
      />
    </svg>
  );
}

export default BottleBlue;
