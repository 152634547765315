import * as React from 'react';

const SvgComponent = (props: any) => (
  <svg
    width={18}
    height={18}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M15.63 3.457a4.125 4.125 0 0 0-5.835 0L9 4.252l-.795-.795A4.126 4.126 0 0 0 2.37 9.292l.795.795L9 15.922l5.835-5.835.795-.795a4.127 4.127 0 0 0 0-5.835v0Z"
      stroke="#fff"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
      opacity={0.8}
      className="icon"
    />
  </svg>
);

export default SvgComponent;
