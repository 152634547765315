import React from 'react';

const CloudUpload = (props: any) => {
  const { fill, ...rest } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
      {...rest}
    >
      <g
        stroke="#373634"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        clipPath="url(#clip0_64_35251)"
      >
        <path d="M16 16l-4-4-4 4M12 12v9"></path>
        <path d="M20.39 18.39A5 5 0 0018 9h-1.26A8 8 0 103 16.3"></path>
        <path d="M16 16l-4-4-4 4"></path>
      </g>
      <defs>
        <clipPath id="clip0_64_35251">
          <path fill="#fff" d="M0 0H24V24H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
};

export default CloudUpload;
