import React from 'react';

const MinusThin = (props: any) => {
  const { fill, ...rest } = props;
  return (
    <svg
      width="10"
      height="2"
      viewBox="0 0 10 2"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M0 1H10"
        stroke="#393939"
        strokeWidth="0.75"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default MinusThin;
