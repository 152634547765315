import React from 'react';

const UpsellBottomBorder = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none">
    <path
      fill="#9F7058"
      d="M24 20a4 4 0 0 1-4 4H0a26.082 26.082 0 0 0 10.497-11.2l1.132-2.33A20.04 20.04 0 0 1 24 0v20Z"
    />
  </svg>
);
export default UpsellBottomBorder;
