import * as React from 'react';

const SvgComponent = (props: any) => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clip-path="url(#clip0_2262_8299)">
      <path
        d="M3 4.5C3 5.74275 5.6865 6.75 9 6.75C12.3135 6.75 15 5.74275 15 4.5C15 3.25725 12.3135 2.25 9 2.25C5.6865 2.25 3 3.25725 3 4.5Z"
        stroke="#268794"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M3 4.5V9C3 10.2427 5.6865 11.25 9 11.25C9.3585 11.25 9.71025 11.238 10.0515 11.2155"
        stroke="#268794"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M15 9V4.5"
        stroke="#268794"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M3 9V13.5C3 14.6445 5.28 15.5895 8.229 15.7313"
        stroke="#268794"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M13.815 11.7074C13.9613 11.5612 14.1349 11.4451 14.326 11.366C14.5171 11.2868 14.7219 11.2461 14.9288 11.2461C15.1356 11.2461 15.3404 11.2868 15.5315 11.366C15.7226 11.4451 15.8962 11.5612 16.0425 11.7074C16.1888 11.8537 16.3048 12.0273 16.3839 12.2184C16.4631 12.4095 16.5038 12.6143 16.5038 12.8212C16.5038 13.028 16.4631 13.2328 16.3839 13.4239C16.3048 13.615 16.1888 13.7887 16.0425 13.9349L13.5 16.4999H11.25V14.2499L13.815 11.7074Z"
        stroke="#268794"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_2262_8299">
        <rect width="18" height="18" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default SvgComponent;
